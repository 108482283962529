import { Dispatch, ApplicationState } from 'modules/redux-store';
import { FirebaseDatabaseService } from 'modules/firebase/services';
import { LocalisedEntity } from 'modules/localisation';
import { FirestoreListenerActions } from 'modules/firestore-listeners';

import { Unit } from '../models';

import { UnitActions } from './actions';

const companyunits = new FirebaseDatabaseService<Unit>('company-units');

export const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  /** If units listener is already active, don't trigger again */
  const units = getState().listeners.unitsListener;
  if (units) {
    return;
  }

  dispatch(UnitActions.unitsRequest());

  const successFunction = (data: Unit[]) =>
    dispatch(UnitActions.unitsSuccess(data));

  const errorFunction = (error: string) =>
    dispatch(UnitActions.unitsError(error));

  const listenerProps = {
    successFunction,
    errorFunction
  };

  companyunits
    .filterAsync(undefined, listenerProps)
    .then(listener =>
      dispatch(
        FirestoreListenerActions.setUnitsListener(listener as VoidFunction)
      )
    );

  return;
};

export const saveNewUnit = (newUnit: LocalisedEntity) => async (
  dispatch: Dispatch
) => {
  dispatch(UnitActions.unitsRequest());
  companyunits.addAsync(newUnit);
  return;
};

export const updateUnit = (newUnit: LocalisedEntity) => async (
  dispatch: Dispatch
) => {
  dispatch(UnitActions.unitsRequest());
  companyunits.updateAsync(newUnit);
  return;
};

export const removeAsync = (unitId: string) => async (dispatch: Dispatch) => {
  dispatch(UnitActions.unitsRequest());
  await companyunits.removeAsync(unitId);
  return;
};

export const UnitsThunkActions = {
  getAllAsync,
  saveNewUnit,
  updateUnit,
  removeAsync
};
