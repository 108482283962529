import React from 'react';
import { NestDataObject, ErrorMessage } from 'react-hook-form';

interface Props {
  name: string;
  register: any;
  label?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  sublabel?: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  checked?: boolean;
  inputClassName?: string;
  errors: NestDataObject<any, any>;
  errMsg?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  textarea?: boolean;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  pattern?: string;
  validationObject?: {
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    max?: number;
    min?: number;
    pattern?: RegExp;
    validate?: any;
  };
  min?: number;
  max?: number;
  step?: number;
  onBlur?: (event: any) => void;
}

export const ValidatedHookInput: React.FC<Props> = ({
  name,
  type,
  register,
  defaultValue,
  checked,
  placeholder,
  label,
  labelClassName,
  sublabel,
  inputClassName,
  wrapperClassName,
  errors,
  errMsg,
  textarea,
  inputMode,
  pattern,
  disabled,
  min,
  max,
  step,
  validationObject,
  onBlur,
  autoFocus
}) => (
  <div className={wrapperClassName}>
    {type !== 'checkbox' && label && (
      <label htmlFor={name} className={labelClassName || 'field__lbl'}>
        {label}
      </label>
    )}
    {!textarea && (
      <input
        autoFocus={autoFocus}
        type={type || 'text'}
        name={name}
        id={name}
        defaultChecked={checked}
        placeholder={placeholder}
        defaultValue={defaultValue || ''}
        ref={register(validationObject)}
        className={`${inputClassName || 'input input--med'} ${
          errors[name] ? 'input--error' : ''
        }`}
        inputMode={inputMode}
        pattern={pattern}
        onBlur={onBlur}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
      />
    )}

    {textarea && (
      <textarea
        autoFocus={autoFocus}
        name={name}
        id={name}
        placeholder={placeholder}
        ref={register(validationObject)}
        className={inputClassName || 'input input--textarea'}
        disabled={disabled}
        onBlur={onBlur}
        defaultValue={defaultValue}
      />
    )}
    {type === 'checkbox' && label && <label htmlFor={name}>{label}</label>}

    {sublabel && <p className="label-sub">{sublabel}</p>}

    <ErrorMessage
      errors={errors || {}}
      name={name}
      message={errMsg || 'This field is required'}
      as={<p className="alert alert--warning" />}
    />
  </div>
);
