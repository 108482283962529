import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ClientOfferStatus } from '../models';

interface Props {
  clientStatusFilter?: ClientOfferStatus;
  clearStatusFiltersCallback: () => void;
  setClientStatusFilterCallback: (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => void;
}

export class StatusFilters extends React.Component<Props> {
  render() {
    const {
      clearStatusFiltersCallback,
      clientStatusFilter,
      setClientStatusFilterCallback
    } = this.props;

    return (
      <div className="offers__filters__status">
        <p className="offers__filters__title t-micro t-upperext o-40">
          <FormattedMessage id="filter.label.status" />
        </p>
        <button
          onClick={clearStatusFiltersCallback}
          className={`btn btn--core btn--filter ${
            !clientStatusFilter ? 'btn--filter--active' : ''
          }`}
        >
          <FormattedMessage id="filter.button.showall" />
        </button>
        <button
          data-status={ClientOfferStatus.Pending}
          onClick={setClientStatusFilterCallback}
          className={`btn btn--core btn--filter ${
            clientStatusFilter === ClientOfferStatus.Pending
              ? 'btn--filter--active'
              : ''
          }`}
        >
          {ClientOfferStatus.Pending}
        </button>
        <button
          data-status={ClientOfferStatus.Accepted}
          onClick={setClientStatusFilterCallback}
          className={`btn btn--core btn--filter ${
            clientStatusFilter === ClientOfferStatus.Accepted
              ? 'btn--filter--active'
              : ''
          }`}
        >
          {ClientOfferStatus.Accepted}
        </button>
        <button
          data-status={ClientOfferStatus.Rejected}
          onClick={setClientStatusFilterCallback}
          className={`btn btn--core btn--filter ${
            clientStatusFilter === ClientOfferStatus.Rejected
              ? 'btn--filter--active'
              : ''
          }`}
        >
          {ClientOfferStatus.Rejected}
        </button>
      </div>
    );
  }
}
