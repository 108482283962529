import { ApplicationStrings } from '../models';

const hr: ApplicationStrings = {
  // Login view
  'login.welcome': 'Dobrodošli u Offernator',

  // Page names
  'page.homepage': 'Početnu stranicu',
  'page.clients': 'Klijenti',
  'page.services': 'Usluge',
  'page.units': 'Jedinice',
  'page.settings': 'Postavke',
  'page.newoffer': 'Nova ponuda',

  // List headers
  'headers.offername': 'Ime ponude',
  'headers.servicename': 'Ime usluge',
  'headers.unitname': 'Ime jedinice',
  'headers.actions': 'Akcije',

  // Filters
  'filter.label.orderby': 'Poredaj po:',
  'filter.label.status': 'Status:',
  'filter.label.year': 'Godina:',
  'filter.button.name': 'Naziv',
  'filter.button.date': 'Datum',
  'filter.button.showall': 'Prikaži sve',
  'filter.button.drafts': 'Skice',
  'filter.button.published': 'Objavljene',
  'filter.button.archived': 'Arhivirane',

  // Offers
  'offers.listitem.amount': 'Iznos',
  'offers.listitem.status': 'Status',
  'offers.listitem.year': 'Godina',

  // Inputs
  'input.email': 'E-mail',
  'input.password': 'Lozinka',
  'input.rememberme': 'Zapamti me',

  // Buttons
  'button.save': 'Spremi',
  'button.delete': 'Ukloni',
  'button.cancel': 'Odustani',
  'button.edit': 'Uredi',
  'button.publish': 'Objavi',
  'button.archive': 'Arhiviraj',
  'button.logout': 'Odjava',
  'button.login': 'Prijava',
  'button.addnew.offer': 'Dodaj novu ponudu',
  'button.addnew.service': 'Dodaj novu uslugu',
  'button.addnew.unit': 'Dodaj novu jedinicu',
  'button.addnew.client': 'Dodaj novog klijenta',
  'button.copy': 'Kopiraj link',
  'button.preview': 'Pregled',
  'button.duplicate': 'Dupliciraj',

  // Errors
  'error.label.default': 'Greška',
  'error.label.unauthorized': 'Pristup nije dozvoljen',
  'error.message.noitems': 'Trenutno nema podataka',
  'error.message.unauthorized': 'Nemate dopuštenje za pregled ove sekcije. ',
  'error.message.pagenotfound':
    'Stranica koju tražite nije pronađena. Provjerite jeste li unijeli pravilnu adresu ili posjetite ',

  // Empty states
  'empty.offer.title': 'Neimenovana ponuda',
  'empty.offer.companyInfo': 'Nema informacija o firmi.',
  'empty.offer.type': 'Za traženi kriterij ne postoje ponude.',
  'empty.offer.message':
    'Kreirajte novu ponudu uz pomoć našeg čarobnjaka za kreiranje ponuda. Jednostavno je!',

  // Footer
  'footer.copyright': 'Copyright &copy; 2018 Sva prava pridržana.'
};

export default hr;
