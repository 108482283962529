import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  id: string;
  code: string;
  titleEn: string;
  titleHr: string;
  removeItemCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  editItemCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ServiceListItem: React.SFC<Props> = ({
  id,
  code,
  titleEn,
  titleHr,
  removeItemCallback,
  editItemCallback,
}) => (
  <div className="settings-itemlist__item settings-itemlist__item--services">
    <span className="t-small">{code}</span>
    <span className="t-small">{titleEn}</span>
    <span className="t-small">{titleHr}</span>
    <div>
      <button
        data-service-id={id}
        onClick={editItemCallback}
        className="btn btn--dark btn--tny"
      >
        <FormattedMessage id="button.edit" />
      </button>
      <button
        data-service-id={id}
        data-title-en={titleEn}
        data-title-hr={titleHr}
        onClick={removeItemCallback}
        className="btn btn--ghost btn--tny"
      >
        <img
          src={require('assets/icons/icon-trash-16.svg')}
          alt=""
          className="btn__icon"
        />
      </button>
    </div>
  </div>
);
