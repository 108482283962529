import * as React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RoutingProps, AppRoute } from 'modules/navigation';
import { PageLoading } from 'components';

import { ACLRoute } from './ACLRoute';

type Props = RoutingProps;

export class AccessControlList extends React.Component<Props> {
  redirectToErrorRoute = () => <Redirect to={this.props.errorRoute.path!} />;

  render() {
    const { routes, authStateChanging, errorRoute, ...rest } = this.props;

    if (authStateChanging) {
      return <PageLoading />;
    }

    return (
      <Switch>
        {routes.map((route: AppRoute) => (
          <ACLRoute key={route.key} {...route} {...rest} />
        ))}

        <Route render={this.redirectToErrorRoute} />
      </Switch>
    );
  }
}
