import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { useFormContext } from 'react-hook-form';

interface Props {
  discountName: string;
  pricePerUnitName: string;
  quantityName: string;
}

export const ServicePrices: React.FC<Props> = ({
  discountName,
  pricePerUnitName,
  quantityName,
}) => {
  const { watch } = useFormContext();
  const currency = watch('currency');
  const discount = watch(discountName);
  const pricePerUnit = watch(pricePerUnitName);
  const quantity = watch(quantityName);

  function calculatePriceWithoutDiscount() {
    return pricePerUnit * quantity || 0;
  }

  function calculatePriceWithDiscount() {
    if (discount) {
      return (
        pricePerUnit * quantity - pricePerUnit * quantity * (discount / 100)
      );
    }

    return calculatePriceWithoutDiscount();
  }

  function calculateTotalDiscount() {
    if (discount) {
      return pricePerUnit * quantity * (discount / 100);
    }
    return 0;
  }

  return (
    <div className="service-prices">
      <div className="service-prices__price">
        <p>
          <FormattedNumber
            value={calculatePriceWithoutDiscount()}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          {` ${currency?.value}`}
        </p>
      </div>
      <div className="service-prices__discount">
        <p>
          {Number(discount) > 0 && '-'}
          <FormattedNumber
            value={calculateTotalDiscount() || 0}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          {` ${currency?.value}`}
        </p>
      </div>
      <div className="service-prices__total">
        <p>
          <strong>
            Subtotal:{' '}
            <FormattedNumber
              value={calculatePriceWithDiscount()}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
            {` ${currency?.value}`}
          </strong>
        </p>
      </div>
    </div>
  );
};
