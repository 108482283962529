import * as React from 'react';
import { Navigation } from 'modules/navigation';
import { Link } from 'react-router-dom';

import { Footer } from './Footer';

export const Layout: React.SFC = ({ children }) =>
  !window.location.pathname.includes('/view/') ? (
    <div className="container container--app">
      {/* Hide header when route contains view, as that's shared offer view */}
      {!window.location.pathname.includes('/view/') && (
        <header className="app__header header">
          <div className="header__branding">
            <Link to="/" className="t-negative">
              Offernator
            </Link>
          </div>
          <div className="header__nav">
            <Navigation />
          </div>
        </header>
      )}
      <main className="app__content">{children}</main>
      <Footer />
    </div>
  ) : (
    <div className="container container--public">
      {children}
      <Footer />
    </div>
  );
