import { Dispatch } from 'modules/redux-store';
import { FirebaseDatabaseService } from 'modules/firebase/services';
import { Offer } from 'modules/offers';

import { GetSingleOfferActions } from '.';

const offers = new FirebaseDatabaseService<Offer>('offers');

export const getSingleOffer = (id: string) => async (dispatch: Dispatch) => {
  dispatch(GetSingleOfferActions.getSingleOfferRequest());

  const singleOfferOrError = await offers.getByIdAsync(id);

  if (typeof singleOfferOrError === 'string') {
    dispatch(GetSingleOfferActions.getSingleOfferError(singleOfferOrError));
    return;
  }
  if (typeof singleOfferOrError === 'function') {
    dispatch(GetSingleOfferActions.getSingleOfferError('Error'));
    return;
  }

  dispatch(GetSingleOfferActions.getSingleOfferSuccess(singleOfferOrError));
  return;
};

export const PublicOfferThunkAction = {
  getSingleOffer
};
