export function serialize(text: string) {
  if (text.startsWith('{"object":"value","document"')) {
    return text;
  }

  const jsonValue = {
    document: {
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          nodes: [
            {
              object: 'text',
              text
            }
          ]
        }
      ]
    }
  };

  return JSON.stringify(jsonValue);
}
