import { Dispatch, ApplicationState } from 'modules/redux-store';
import { FirebaseDatabaseService } from 'modules/firebase/services';
import { FirestoreListenerActions } from 'modules/firestore-listeners';

import { Service } from '../models';

import { ServiceActions } from './actions';

const companyservices = new FirebaseDatabaseService<Service>(
  'company-services'
);

export const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  /** If services listener is already active, don't trigger again */
  const services = getState().listeners.servicesListener;
  if (services) {
    return;
  }

  dispatch(ServiceActions.servicesRequest());

  const successFunction = (data: Service[]) =>
    dispatch(ServiceActions.servicesSuccess(data));

  const errorFunction = (error: string) =>
    dispatch(ServiceActions.servicesError(error));

  const listenerProps = {
    successFunction,
    errorFunction
  };

  companyservices
    .filterAsync(undefined, listenerProps)
    .then(listener =>
      dispatch(
        FirestoreListenerActions.setServicesListener(listener as VoidFunction)
      )
    );

  return;
};

export const saveNewService = (
  newService: Service | Partial<Service>
) => async (dispatch: Dispatch) => {
  dispatch(ServiceActions.servicesRequest());
  companyservices.addAsync(newService);
  return;
};

export const updateService = (newService: Service) => async (
  dispatch: Dispatch
) => {
  dispatch(ServiceActions.servicesRequest());
  companyservices.updateAsync(newService);
  return;
};

export const removeAsync = (serviceId: string) => async (
  dispatch: Dispatch
) => {
  dispatch(ServiceActions.servicesRequest());
  await companyservices.removeAsync(serviceId);
  return;
};

export const ServicesThunkActions = {
  getAllAsync,
  saveNewService,
  updateService,
  removeAsync
};
