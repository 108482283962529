import { Settings } from '..';

import { SettingsActions } from '.';
import { SettingsActionTypes } from './types';

export interface SettingsState {
  readonly settingsAreChanging: boolean;
  readonly settings?: Settings;
  readonly error?: string;
}

const INITIAL_STATE: SettingsState = {
  settingsAreChanging: false,
  settings: undefined,
  error: undefined
};

export const settingsReducer = (
  state: SettingsState = INITIAL_STATE,
  action: SettingsActions
): SettingsState => {
  switch (action.type) {
    case SettingsActionTypes.SettingsRequest:
      return {
        ...state,
        settingsAreChanging: true,
        error: undefined
      };
    case SettingsActionTypes.SettingsSuccess:
      return {
        ...state,
        settingsAreChanging: false,
        settings: action.payload.settings
      };
    case SettingsActionTypes.SettingsError:
      return {
        ...state,
        settingsAreChanging: false,
        error: action.payload.error
      };
    default:
      return state || INITIAL_STATE;
  }
};
