import { Unit } from '../models';

import { UnitActions } from './actions';
import { UnitActionTypes } from './types';

export interface UnitState {
  readonly unitsAreChanging: boolean;
  readonly units?: Unit[];
  readonly error?: string;
}

const INITIAL_STATE: UnitState = {
  unitsAreChanging: false,
  units: undefined,
  error: undefined
};

export const unitsReducer = (
  state: UnitState = INITIAL_STATE,
  action: UnitActions
): UnitState => {
  switch (action.type) {
    case UnitActionTypes.UnitRequest:
      return {
        ...state,
        unitsAreChanging: true,
        error: undefined
      };
    case UnitActionTypes.UnitSuccess:
      return {
        ...state,
        unitsAreChanging: false,
        units: action.payload.units
      };
    case UnitActionTypes.UnitError:
      return {
        ...state,
        unitsAreChanging: false,
        error: action.payload.error
      };
    default:
      return state || INITIAL_STATE;
  }
};
