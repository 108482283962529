import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  id: string;
  titleEn: string;
  titleHr: string;
  removeItemCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  editItemCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const UnitListItem: React.SFC<Props> = ({
  id,
  titleEn,
  titleHr,
  removeItemCallback,
  editItemCallback,
}) => (
  <div className="settings-itemlist__item settings-itemlist__item--units">
    <span>{titleEn}</span>
    <span>{titleHr}</span>
    <div className="btn-group btn-group--simple">
      <button
        data-unit-id={id}
        onClick={editItemCallback}
        className="btn btn--dark btn--tny"
      >
        <FormattedMessage id="button.edit" />
      </button>
      <button
        data-unit-id={id}
        data-title-en={titleEn}
        data-title-hr={titleHr}
        onClick={removeItemCallback}
        className="btn btn--ghost btn--tny"
      >
        <img
          src={require('assets/icons/icon-trash-16.svg')}
          alt=""
          className="btn__icon"
        />
      </button>
    </div>
  </div>
);
