import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { AppRoute, NavigationSelectors } from 'modules/navigation';
import { ApplicationState } from 'modules/redux-store';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface ReduxProps {
  loginRoute: AppRoute;
}

class PermissionError extends React.Component<ReduxProps> {
  render() {
    const { loginRoute } = this.props;

    return (
      <section className="errorpage">
        <div className="errorpage__wrapper wrapper">
          <header className="errorpage__header">
            <h1>
              <FormattedMessage id="error.label.unauthorized" />
            </h1>
          </header>
          <div className="errorpage__content">
            <p>
              <FormattedMessage id="error.message.unauthorized" />
            </p>
          </div>
          <div className="errorpage__action">
            <Link className="btn btn--primary btn--sml" to={loginRoute.path!}>
              {loginRoute.title}
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(
  (state: ApplicationState): ReduxProps => ({
    loginRoute: NavigationSelectors.getBasePublicRoute(state)
  })
)(PermissionError);
