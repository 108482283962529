import { createAction, ActionUnion } from 'modules/redux-store';

import { Language, ApplicationStrings } from '../models';

import { LocalisationActionTypes } from './types';

export const LocalisationActions = {
  setLanguage: (
    currentLanguage: Language,
    currentApplicationStrings: ApplicationStrings
  ) =>
    createAction(LocalisationActionTypes.SetLanguage, {
      currentLanguage,
      currentApplicationStrings
    })
};

export type LocalisationActions = ActionUnion<typeof LocalisationActions>;
