import React, { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FormattedService } from 'modules/company-services';
import { FormattedUnit } from 'modules/company-units';
import { Currency, SelectOption } from 'models';
import { FormSelect, ValidatedHookInput, RichTextEditor } from 'components';

import { ServicePrices } from '..';
import { useFormContext } from 'react-hook-form';

interface Props {
  id: string;
  index: number;
  name?: string;
  services?: FormattedService[];
  units?: FormattedUnit[];
  type?: string;
  unit?: string | SelectOption;
  selectedService?: string;
  selectedUnit?: string;
  additionalField?: string;
  pricePerUnit?: number;
  discount?: number;
  quantity?: number;
  currency?: Currency;
  language?: string;
  priceWithoutDiscount?: number;
  discountAmount?: number;
  price?: number;
  handleRemoveServiceCallback: (id: string) => void;
}

export const OfferBuilderService: React.FC<Props> = ({
  id,
  index,
  services,
  quantity,
  discount,
  pricePerUnit,
  additionalField,
  name,
  unit,
  type,
  selectedService,
  handleRemoveServiceCallback,
  units,
  selectedUnit,
}) => {
  const { register, errors, setValue } = useFormContext();
  const defaultUnit =
    units?.find(
      (unit) =>
        unit.label.toLowerCase() === 'hour' ||
        unit.label.toLowerCase() === 'sat'
    ) ||
    (units && units[0]);

  useEffect(() => {
    register(`services[${index}].id`);
    setValue(`services[${index}].id`, id);
    register(`services[${index}].index`);
    setValue(`services[${index}].index`, index);
    register(`services[${index}].type`);
    setValue(`services[${index}].type`, type);
    register(`services[${index}].unitId`);
    setValue(`services[${index}].unitId`, selectedUnit || defaultUnit?.value);
    register(`services[${index}].serviceId`);
    setValue(`services[${index}].serviceId`, selectedService);
  }, [
    register,
    index,
    setValue,
    id,
    type,
    selectedUnit,
    selectedService,
    defaultUnit,
  ]);

  function handleDeleteService() {
    handleRemoveServiceCallback(id);
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <section
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="proposal-service"
        >
          <div
            {...provided.dragHandleProps}
            className="proposal-service__dragger"
          >
            <img
              alt="drag item"
              src={require('assets/icons/icon-drag-24.svg')}
            />
          </div>
          <div className="proposal-service__body">
            <header className="proposal-service__body__header">
              <FormSelect
                label="Services"
                wrapperClassName="field"
                options={services || []}
                errMsg="Service field is required!"
                name={`services[${index}].name`}
                onChange={updateServiceId}
                defaultValue={
                  name && selectedService
                    ? {
                        value: selectedService,
                        label: name,
                      }
                    : undefined
                }
                required
              />
            </header>

            <div className="proposal-service__body__layout">
              <div className="proposal-service__body__layout__description">
                <RichTextEditor
                  wrapperClassName="field"
                  label="Description label"
                  body={additionalField}
                  name={`services[${index}].additionalField`}
                />
              </div>
              <div className="proposal-service__body__layout__fields">
                <FormSelect
                  label="Unit"
                  wrapperClassName="field"
                  options={units || []}
                  errMsg="Service field is required!"
                  name={`services[${index}].unit`}
                  defaultValue={{
                    value: selectedUnit || defaultUnit?.value,
                    label: (unit as string) || defaultUnit?.label,
                  }}
                  onChange={updateUnitId}
                  required
                />

                <ValidatedHookInput
                  wrapperClassName="field"
                  label="Quantity"
                  inputClassName="input input--text"
                  min={0}
                  step={0.01}
                  register={register}
                  defaultValue={quantity?.toString()}
                  errors={errors}
                  type="number"
                  name={`services[${index}].quantity`}
                />

                <ValidatedHookInput
                  wrapperClassName="field"
                  label="Price per unit"
                  inputClassName="input input--text"
                  min={0}
                  step={0.01}
                  register={register}
                  errors={errors}
                  type="number"
                  defaultValue={Number(pricePerUnit).toString()}
                  name={`services[${index}].pricePerUnit`}
                />

                <ValidatedHookInput
                  wrapperClassName="field"
                  label="Discount (%)"
                  inputClassName="input input--text"
                  min={0}
                  step={0.01}
                  register={register}
                  errors={errors}
                  defaultValue={Number(discount).toString()}
                  type="number"
                  name={`services[${index}].discount`}
                />
              </div>

              <div className="proposal-service__body__layout__summary">
                <ServicePrices
                  discountName={`services[${index}].discount`}
                  pricePerUnitName={`services[${index}].pricePerUnit`}
                  quantityName={`services[${index}].quantity`}
                />
              </div>
            </div>

            <footer className="proposal-service__footer">
              <button
                type="button"
                onClick={handleDeleteService}
                className="btn btn--ghost btn--sml btn--sml--iconOnly"
              >
                <img
                  src={require('assets/icons/icon-trash-16.svg')}
                  alt=""
                  className="btn__icon"
                />
              </button>
            </footer>
          </div>
        </section>
      )}
    </Draggable>
  );

  function updateUnitId(id: string) {
    setValue(`services[${index}].unitId`, id);
  }

  function updateServiceId(id: string) {
    setValue(`services[${index}].serviceId`, id);
  }
};
