import { Dispatch, ApplicationState } from 'modules/redux-store';
import { FirebaseDatabaseService } from 'modules/firebase/services';
import { Client } from 'modules/company-clients/models';
import { FirestoreListenerActions } from 'modules/firestore-listeners';

import { ClientsActions } from '.';

const companyclients = new FirebaseDatabaseService<Client>('company-clients');

export const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  /** If client listener is already active, don't trigger again */
  const clientListener = getState().listeners.clientsListener;
  if (clientListener) {
    return;
  }

  dispatch(ClientsActions.clientsRequest());

  const successFunction = (data: Client[]) =>
    dispatch(ClientsActions.clientsSuccess(data));

  const errorFunction = (error: string) =>
    dispatch(ClientsActions.clientsError(error));

  const listenerProps = {
    successFunction,
    errorFunction
  };

  companyclients.filterAsync(undefined, listenerProps).then(listener => {
    dispatch(
      FirestoreListenerActions.setClientsListener(listener as VoidFunction)
    );
  });

  return;
};

/**
 *
 * @note Not a thunk!
 */
export const saveNewClient = (newClient: Partial<Client>) => async () => {
  await companyclients.addAsync(newClient);
};

/**
 *
 * @note Not a thunk!
 */
export const editClient = (clientForUpdate: Partial<Client>) => async () => {
  await companyclients.updateAsync(clientForUpdate);
};

export const removeAsync = (clientId: string) => async (dispatch: Dispatch) => {
  dispatch(ClientsActions.clientsRequest());

  await companyclients.removeAsync(clientId);
  return;
};

export const ClientsThunkActions = {
  getAllAsync,
  saveNewClient,
  editClient,
  removeAsync
};
