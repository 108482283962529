import { createAction, ActionUnion } from 'modules/redux-store';

import { FirestoreListenerActionTypes } from './types';

const AddActions = {
  setOffersListener: (listener: VoidFunction) =>
    createAction(FirestoreListenerActionTypes.SetOffersListener, {
      listener
    }),
  setClientsListener: (listener: VoidFunction) =>
    createAction(FirestoreListenerActionTypes.SetClientsListener, {
      listener
    }),
  setServicesListener: (listener: VoidFunction) =>
    createAction(FirestoreListenerActionTypes.SetServicesListener, {
      listener
    }),
  setUnitsListener: (listener: VoidFunction) =>
    createAction(FirestoreListenerActionTypes.SetUnitsListener, {
      listener
    }),
  setSettingsListener: (listener: VoidFunction) =>
    createAction(FirestoreListenerActionTypes.SetSettingsListener, {
      listener
    })
};

const GeneralActions = {
  clearReducer: () => createAction(FirestoreListenerActionTypes.ClearReducer)
};

export const FirestoreListenerActions = {
  ...AddActions,
  ...GeneralActions
};

export type FirestoreListenerActions = ActionUnion<
  typeof FirestoreListenerActions
>;
