import { FirebaseAuthService } from 'modules/firebase';
import { LocalisationSelectors } from 'modules/localisation';
import { ApplicationState } from 'modules/redux-store';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { AppRoute } from '../models';

interface State {
  isMenuToggled: boolean;
}

interface ReduxProps {
  isLoggedIn: boolean;
  routes: AppRoute[];
}

interface DispatchProps {
  logout: () => void;
}

type Props = RouteComponentProps<any> & DispatchProps & ReduxProps;

class Navigation extends React.Component<Props, State> {
  renderHeaderMenu(routes: AppRoute[]) {
    return routes.map(route => {
      // Do not show public routes, routes with showInMenu set to false and if user is not logged in.
      if (!route.showInMenu) {
        return null;
      }

      return (
        <li key={route.path} className="nav__links__item">
          <NavLink
            to={route.path!}
            exact={route.exact}
            className="nav__link"
            activeClassName="active"
          >
            {route.title}
          </NavLink>
        </li>
      );
    });
  }

  renderLogoutButton(logout: () => void) {
    return (
      <button className="nav__link nav__logout" onClick={logout}>
        <FormattedMessage id="button.logout" />
      </button>
    );
  }

  render() {
    const { isLoggedIn, routes } = this.props;

    return (
      <nav className="nav">
        {isLoggedIn && (
          <ul className="nav__links">{this.renderHeaderMenu(routes)}</ul>
        )}
      </nav>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state: ApplicationState): ReduxProps => ({
      isLoggedIn: state.auth.isLoggedIn,
      routes: LocalisationSelectors.getLocalisedRoutes(state)
    }),
    (): DispatchProps => ({
      logout: () => new FirebaseAuthService().logoutAsync()
    })
  )
)(Navigation) as any;
