import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  UnitListHeader,
  Unit,
  UnitListItem,
  UnitsThunkActions,
} from 'modules/company-units';
import { connect } from 'react-redux';
import { Dispatch, ApplicationState } from 'modules/redux-store';
import { LocalisedEntity } from 'modules/localisation';
import { PagedContent, ConfirmationModal } from 'components';
import { withSettingsMenu } from 'components/hoc';
import { compose } from 'redux';
import { NewUnitModal } from 'modules/company-units/components/NewUnitModal';

interface ReduxProps {
  units?: Unit[];
  unitsAreChanging: boolean;
}

interface DispatchProps {
  getAllUnits: () => Promise<void>;
  removeUnit: (unitId: string) => Promise<void>;
  updateUnit: (newUnit: Unit) => Promise<void>;
  saveNewUnit: (newUnit: LocalisedEntity) => Promise<void>;
}

type Props = ReduxProps & DispatchProps;

interface State {
  modalIsOpen: boolean;
  confirmationModalIsOpen: boolean;
  isEdit: boolean;
  [param: string]: string | boolean | undefined;
  unitNameEnglish: string;
  unitNameEnglishValid: boolean;
  unitNameCroatian: string;
  unitNameCroatianValid: boolean;
  id: string;
}

class Units extends React.PureComponent<Props, State> {
  state = {
    modalIsOpen: false,
    confirmationModalIsOpen: false,
    isEdit: false,
    unitNameEnglish: '',
    unitNameEnglishValid: false,
    unitNameCroatian: '',
    unitNameCroatianValid: false,
    id: '',
  };

  componentWillMount() {
    const { getAllUnits } = this.props;
    getAllUnits();
  }

  openModal = () => {
    this.setState({
      modalIsOpen: true,
      isEdit: false,
    });
  };

  closeModal = () => {
    this.setState({
      id: '',
      unitNameEnglish: '',
      unitNameCroatian: '',
      modalIsOpen: false,
    });
  };

  openConfirmationModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { unitId, titleEn, titleHr } = event.currentTarget.dataset;
    if (unitId) {
      this.setState({
        id: unitId,
        unitNameEnglish: titleEn || '',
        unitNameCroatian: titleHr || '',
        confirmationModalIsOpen: true,
      });
    }
  };

  closeConfirmationModal = () => {
    this.setState({
      id: '',
      confirmationModalIsOpen: false,
    });
  };

  handleSave = () => {
    const { unitNameEnglish, unitNameCroatian, id, isEdit } = this.state;
    const { saveNewUnit, updateUnit } = this.props;

    /** If title in both languages is entered, create new unit object and push it to thunk to be saved in firestore. */
    if (unitNameEnglish.length && unitNameCroatian.length) {
      const newUnit: Unit = {
        id,
        localisation: {
          en: {
            title: unitNameEnglish,
          },
          hr: {
            title: unitNameCroatian,
          },
        },
      };

      // If modalIsOpen existing unit.
      if (isEdit) {
        updateUnit(newUnit);
      } else {
        saveNewUnit(newUnit);
      }

      this.closeModal();
    }
  };

  handleRemove = () => {
    const { removeUnit } = this.props;
    const { id } = this.state;

    if (id) {
      removeUnit(id);
      this.closeConfirmationModal();
    }
  };

  renderListItemComponent = (unit: Unit) => (
    <UnitListItem
      key={unit.localisation.en.title}
      id={unit.id}
      titleEn={unit.localisation.en.title}
      titleHr={unit.localisation.hr.title}
      removeItemCallback={this.openConfirmationModal}
      editItemCallback={this.handleEdit}
    />
  );

  handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { units } = this.props;
    const { unitId } = event.currentTarget.dataset;

    const unitToEdit = units && units.find((item) => item.id === unitId);

    // If unit to edit hasn't been found abort editing.
    if (!unitToEdit) {
      return;
    }

    // Populate current state with selected client which needs to be edited.
    this.setState({
      id: unitToEdit.id,
      unitNameEnglish: unitToEdit.localisation.en.title,
      unitNameCroatian: unitToEdit.localisation.hr.title,
      modalIsOpen: true,
    });
    return;
  };

  handleInputChange: React.ReactEventHandler<HTMLInputElement> = (event) => {
    const { name } = event.currentTarget;
    const value: string = event.currentTarget.value;

    this.setState({
      [name]: value,
    });
  };

  handleValidatedInputChange = (
    propName: string,
    value: string,
    isValid?: boolean
  ) => {
    this.setState({
      [propName]: value,
      [`${propName}Valid`]: isValid,
    });
  };

  render() {
    const { units } = this.props;
    const {
      modalIsOpen,
      unitNameEnglish,
      unitNameCroatian,
      unitNameEnglishValid,
      unitNameCroatianValid,
      confirmationModalIsOpen,
      isEdit,
    } = this.state;

    const formValid = unitNameEnglishValid && unitNameCroatianValid;

    return (
      <React.Fragment>
        <section className="settings">
          <header className="settings__header">
            <div className="settings__header__content">
              <h1 className="t-delta s-bottom--med">
                <FormattedMessage id="page.units" />
              </h1>
              <p className="t-small o-60">
                Set up and manage units used in proposals. You can add as many
                units as needed - hours, months etc.
              </p>
            </div>
            <div className="settings__header__action">
              <button
                onClick={this.openModal}
                className="btn btn--sml btn--primary"
              >
                <img
                  src={require('assets/icons/icon-add-16-negative.svg')}
                  alt=""
                  className="btn__icon btn__icon--left"
                />
                <FormattedMessage id="button.addnew.unit" />
              </button>
            </div>
          </header>

          <UnitListHeader />
          {units && (
            <PagedContent
              data={units}
              renderItem={this.renderListItemComponent}
            />
          )}

          <NewUnitModal
            title={`${isEdit ? 'Edit' : 'Add new'} unit`}
            isVisible={modalIsOpen}
            formValid={formValid}
            closeModalCallback={this.closeModal}
            saveCallback={this.handleSave}
            handleInputChangeCallback={this.handleValidatedInputChange}
            unitNameCroatian={unitNameCroatian}
            unitNameEnglish={unitNameEnglish}
          />

          <ConfirmationModal
            title="Confirm delete"
            itemNameEnglish={unitNameEnglish}
            description="Are you sure?"
            isVisible={confirmationModalIsOpen}
            confirmCallback={this.handleRemove}
            cancelCallback={this.closeConfirmationModal}
          />
        </section>
      </React.Fragment>
    );
  }
}

export default compose(
  withSettingsMenu,
  connect(
    (state: ApplicationState): ReduxProps => ({
      units: state.units.units,
      unitsAreChanging: state.units.unitsAreChanging,
    }),
    (dispatch: Dispatch): DispatchProps => ({
      getAllUnits: () => dispatch(UnitsThunkActions.getAllAsync()),
      updateUnit: (newUnit: Unit) =>
        dispatch(UnitsThunkActions.updateUnit(newUnit)),
      saveNewUnit: (newUnit: LocalisedEntity) =>
        dispatch(UnitsThunkActions.saveNewUnit(newUnit)),
      removeUnit: (unitId: string) =>
        dispatch(UnitsThunkActions.removeAsync(unitId)),
    })
  )
)(Units);
