import { createSelector } from 'reselect';
import { LocalisationSelectors } from 'modules/localisation';

const getBasePublicRoute = createSelector(
  [LocalisationSelectors.getLocalisedRoutes],
  routes => routes.find(route => route.key === 'login')!
);

const getBaseAuthenticatedRoute = createSelector(
  [LocalisationSelectors.getLocalisedRoutes],
  routes => routes.find(route => route.key === 'offers')!
);

const getGeneralErrorRoute = createSelector(
  [LocalisationSelectors.getLocalisedRoutes],
  routes => routes.find(route => route.key === 'error')!
);

const getPermissionErrorRoute = createSelector(
  [LocalisationSelectors.getLocalisedRoutes],
  routes => routes.find(route => route.key === 'unauthorized')!
);

export const NavigationSelectors = {
  getBasePublicRoute,
  getBaseAuthenticatedRoute,
  getPermissionErrorRoute,
  getGeneralErrorRoute
};
