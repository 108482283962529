import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { unregisterSW } from 'workers';
import { Routing } from 'modules/navigation';
import { configureStore } from 'modules/redux-store';
import { ConnectedIntl } from 'modules/localisation';
import { toast } from 'react-toastify';
import 'normalize.css';
import { AuthenticationThunkActions } from 'modules/authentication';
import { AnyAction } from 'redux';

import './css/app.css';

// App itself
class App extends React.Component {
  // Configure a redux store container
  store = configureStore();

  componentWillMount() {
    toast.configure({
      closeButton: false,
      position: toast.POSITION.BOTTOM_RIGHT
    });

    this.store.dispatch(
      (AuthenticationThunkActions.loginWithPersistedUser() as unknown) as AnyAction
    );
  }

  render() {
    return (
      <Provider store={this.store}>
        <ConnectedIntl>
          <Routing />
        </ConnectedIntl>
      </Provider>
    );
  }
}

// Render the Routing component which will mount required routes and Layout
ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

// Unregister a service worker to implement firebase caching
unregisterSW();
