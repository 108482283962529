import { BaseOfferService } from './BaseOfferService';

// This extends OfferService to help with mapping of both items in array. It's a hack, yes.
export interface OfferField extends BaseOfferService {
  body?: string;
}

export const serviceIsOfferField = (
  service: BaseOfferService
): service is OfferField => {
  return service.type === 'field';
};
