import React, { useEffect } from 'react';
import { FormattedNumber } from 'react-intl';

import { Offer, serviceIsOfferService, OfferService } from '../models';
import { useFormContext } from 'react-hook-form';
import { ValidatedHookInput } from 'components';
import { SelectOption } from 'models';

interface Props {
  currentOffer?: Partial<Offer>;
}

export const TotalPrices: React.FC<Props> = ({ currentOffer }) => {
  const { register, watch, errors, setValue } = useFormContext();
  const { total, services, currency } = watch({ nest: true });
  const vat = total?.vat;

  useEffect(() => {
    register('total.price');
    register('total.priceWithoutVat');
    register('total.vatAmount');

    setValue('total.price', currentOffer?.total?.price || 0);
    setValue(
      'total.priceWithoutVat',
      currentOffer?.total?.priceWithoutVat || 0
    );
    setValue('total.vatAmount', currentOffer?.total?.vatAmount || 0);
  }, [register, setValue, currentOffer]);

  // Filter out only services (not fields) for price calculation.
  const onlyServices: OfferService[] =
    services &&
    services.filter((item: OfferService) => item && item.type === 'service');

  // Calculate total price from all services.
  const sum =
    onlyServices &&
    onlyServices
      .filter((item: OfferService) => serviceIsOfferService(item))
      .reduce(
        (total: number = 0, item: OfferService) =>
          total +
          +(item.pricePerUnit || 0) * +(item.quantity || 0) -
          +(item.pricePerUnit || 0) *
            +(item.quantity || 0) *
            (+(item.discount || 0) / 100),
        0
      );

  // If VAT is not defined, copy sum to total price, otherwise calculate it in.
  let price = sum;
  let vatAmount = 0;
  if (sum && vat) {
    price = sum + sum * (+vat / 100);
    vatAmount = sum * (+vat / 100);
  }

  const totalHours =
    onlyServices &&
    onlyServices
      .filter(item => (item?.unit as SelectOption)?.label === 'Hour')
      .map(item => item.quantity);

  useEffect(() => {
    setValue('total.price', price || 0);
    setValue('total.priceWithoutVat', sum || 0);
    setValue('total.vatAmount', vatAmount || 0);
  }, [setValue, sum, vatAmount, price]);

  if (!services) {
    return null;
  }

  return (
    <div className="total-prices">
      <div className="f f--between-center s-bottom--med">
        <p className="total-prices__subtotal__label">Total hours</p>
        <p className="total-prices__subtotal__value">
          {totalHours.length > 0
            ? totalHours.reduce((total = 0, amount) => +total + +(amount || 0))
            : 0}
        </p>
      </div>

      <div className="f f--between-center s-bottom--med">
        <p className="total-prices__subtotal__label">Subtotal</p>
        <p className="total-prices__subtotal__value">
          <FormattedNumber
            value={sum || 0}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          {` ${(currency as SelectOption).value}`}
        </p>
      </div>
      <div className="f f--between-center s-bottom--med">
        <p className="total-prices__subtotal__label">VAT</p>
        <div className="total-prices__subtotal__value">
          <ValidatedHookInput
            register={register}
            name="total.vat"
            errors={errors}
            inputClassName="input input--text input--w-micro"
            type="number"
            defaultValue={currentOffer?.total?.vat?.toString()}
            min={0}
            max={100}
          />
          <span className="s-left--tny">%</span>
        </div>
      </div>
      <div className="f f--between-center s-bottom--med">
        <p className="total-prices__subtotal__label">VAT amount</p>
        <p className="total-prices__subtotal__value">
          <FormattedNumber
            value={vatAmount || 0}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          {` ${(currency as SelectOption).value}`}
        </p>
      </div>
      <div className="f f--between-center t-upper t-faded t-tiny">
        <p className="total-prices__subtotal__label">Total</p>
        <p className="total-prices__subtotal__value">
          <FormattedNumber
            value={price || 0}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
          {` ${(currency as SelectOption).value}`}
        </p>
      </div>
    </div>
  );
};
