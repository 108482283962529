import { useState, useCallback, useEffect } from 'react'
import {
  Offer,
  OfferStatus,
  FormattedVersionHistory,
  OfferService,
  OfferField
} from '../models'
import { Language } from 'modules/localisation'
import { Currency, SelectOption } from 'models'
import { useParams, useHistory } from 'react-router-dom'
import { ItemSelectors } from '../redux'
import { Settings } from 'modules/settings'
import { DropResult } from 'react-beautiful-dnd'
import uuid from 'uuid'
import { v4 as generateUuid } from 'uuid'
import addWeeks from 'date-fns/addWeeks'
import { Client } from 'modules/company-clients'

interface State {
  hasChanges: boolean
  offerIsSaved: boolean
  publishConfirmationModalIsOpen: boolean
}

export const useNewOffer = (
  editOffer: (currentOffer?: Partial<Offer>) => Promise<void>,
  getAllOffers: () => Promise<void>,
  getAllUnits: () => Promise<void>,
  getAllServices: () => Promise<void>,
  removeVersionHistory: () => Promise<void>,
  dispatchError: (error?: string) => void,
  getAllVersions: (id: string) => Promise<void>,
  saveNewVersion: (offer: Partial<Offer>, id: string) => Promise<void>,
  updateDraft: (offer: Partial<Offer>, id: string) => Promise<void>,
  move: (from: number, to: number) => void,
  offerIsBeingSaved: boolean,
  offers?: Offer[],
  currentOffer?: Partial<Offer>,
  versionHistory?: FormattedVersionHistory[],
  settings?: Settings,
  clients?: Client[],
  getValues?: (payload?: { nest: boolean }) => Offer,
  remove?: (index?: number | number[] | undefined) => void
) => {
  const { id: matchId } = useParams()
  const history = useHistory()

  const [state, setState] = useState<State>({
    hasChanges: false,
    offerIsSaved: false,
    publishConfirmationModalIsOpen: false
  })

  /** Used to initialize new offer. */
  const populateInitialOffer = useCallback(() => {
    const newOffer: Partial<Offer> = {
      name: 'Untitled Proposal',
      creationDateTime: Date.now(),
      validUntilDateTime: addWeeks(Date.now(), 2).getTime(),
      status: OfferStatus.Draft,
      language: Language.Hrvatski,
      currency: Currency.HRK,
      services: []
    }
    editOffer(newOffer)
  }, [editOffer])

  useEffect(() => {
    getAllOffers()
    getAllUnits()
    getAllServices()

    return () => {
      removeVersionHistory()
      editOffer(undefined)
      dispatchError(undefined)
      window.onbeforeunload = null
      window.onpopstate = null
      // window.removeEventListener('popstate', this.handleBack, false);
    }
  }, [
    getAllOffers,
    getAllUnits,
    getAllServices,
    dispatchError,
    editOffer,
    removeVersionHistory
  ])

  useEffect(() => {
    if (!matchId && !currentOffer) {
      populateInitialOffer()
    } else if (offers && !currentOffer && matchId) {
      // Filter out the existing information about the offer only once. Then save it to the currentOffer.
      const editableOffer = ItemSelectors.getSingleOffer(offers, matchId)

      if (editableOffer) {
        editOffer(editableOffer)
      } else {
        // If offer is not found after filtering, trigger error that it does not exist.
        dispatchError('This offer does not exist.')
      }
    }
  }, [
    matchId,
    populateInitialOffer,
    offers,
    currentOffer,
    dispatchError,
    editOffer
  ])

  useEffect(() => {
    // Get editable offer if editing offer, not creating new one.
    if (offers && !currentOffer && matchId) {
      // Filter out the existing information about the offer only once. Then save it to the currentOffer.
      const editableOffer = ItemSelectors.getSingleOffer(offers, matchId)

      if (editableOffer) {
        // Save this offer from Firebase to local currentOffer object which is then pushed to the server on save.
        editOffer(editableOffer)
      } else {
        // If offer is not found after filtering, trigger error that it does not exist.
        dispatchError('This offer does not exist.')
      }
    }

    // Get version history if it doesn't already exist.
    if (
      currentOffer &&
      currentOffer.id &&
      currentOffer.status === OfferStatus.Published &&
      !versionHistory
    ) {
      getAllVersions(currentOffer.id)
    }
  }, [
    offers,
    currentOffer,
    matchId,
    dispatchError,
    getAllVersions,
    editOffer,
    versionHistory
  ])

  useEffect(() => {
    // When user creates a new offer, redirect to generated ID url
    if (!matchId && currentOffer && currentOffer.id) {
      history.push(`/offers/add/${currentOffer.id}`)
    }
  }, [currentOffer, history, matchId])

  useEffect(() => {
    // When settings are fetched from firebase update current offer with the data.
    if (
      currentOffer &&
      !currentOffer.companyInfo &&
      !currentOffer.total &&
      settings
    ) {
      const updatedOffer = {
        ...currentOffer,
        companyInfo: settings.companyInfo,
        companyLogo: settings.companyLogo,
        total: {
          vat: settings.taxAmount
        }
      }

      editOffer(updatedOffer)
    }

    // Show notice that offer is saved sucessfully for n seconds.
    if (offerIsBeingSaved) {
      setState(state => ({
        ...state,
        offerIsSaved: true
      }))

      setTimeout(
        () =>
          setState(state => ({
            ...state,
            offerIsSaved: false
          })),
        3000
      )
    }

    // Get version history if it doesn't already exist.
    if (
      currentOffer &&
      currentOffer.id &&
      currentOffer.status === OfferStatus.Published &&
      offerIsBeingSaved &&
      !offerIsBeingSaved
    ) {
      getAllVersions(currentOffer.id)
    }

    if (state.hasChanges) {
      window.onbeforeunload = () => 'Are you sure?'
    } else {
      window.onbeforeunload = null
    }
  }, [
    offerIsBeingSaved,
    getAllVersions,
    currentOffer,
    history,
    editOffer,
    settings,
    state.hasChanges
  ])

  const togglePublishConfirmationModal = useCallback(() => {
    setState(state => ({
      ...state,
      publishConfirmationModalIsOpen: !state.publishConfirmationModalIsOpen
    }))
  }, [])

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source } = result

      // Don't do anything if there is no destination or if destination is the same as source.
      if (!destination || destination.index === source.index) {
        return
      }

      if (currentOffer?.services) {
        const val = currentOffer.services[source.index]
        currentOffer.services.splice(source.index, 1)
        currentOffer.services.splice(destination.index, 0, val)

        const updatedOffer = {
          ...currentOffer,
          services: [...currentOffer.services]
        }

        move(source.index, destination.index)
        editOffer(updatedOffer as Offer)
        setState(state => ({ ...state, hasChanges: true }))
      }
    },
    [currentOffer, editOffer, move]
  )

  const handleSelectHistoryVersion = useCallback(
    (offerVersion: Offer) => {
      // Update current version with version selected from history.
      editOffer({
        ...offerVersion
      })
    },
    [editOffer]
  )

  const normalizeServices = useCallback((services?: OfferService[]) => {
    if (!services) return []
    const newServices = services.map(service => {
      if (service?.name) {
        service.name = ((service.name as unknown) as SelectOption).label
      }

      if (service?.unit) {
        service.unit = ((service.unit as unknown) as SelectOption).label
      }

      return service
    })

    return newServices.filter(service => Boolean(service))
  }, [])

  /** This saves new version of published offer, or overwrites latest draft if offer isn't published yet. */
  const handleSaveOffer = useCallback(
    (data: any) => {
      const request: Offer = {
        ...data,
        id: matchId ? matchId : uuid.v4(),
        language: data.language.value,
        currency: data.currency.value,
        services: normalizeServices(data.services),
        creationDateTime: Date.now()
      }

      delete request['clientInfoSelect']

      // If offer is published, saving should create new version, if not, overwrite latest draft.
      if (request.status === OfferStatus.Published) {
        saveNewVersion(request, request.id)
      } else {
        updateDraft(request, request.id)
      }

      editOffer(request)
      setState(state => ({ ...state, hasChanges: false }))
    },
    [matchId, saveNewVersion, updateDraft, normalizeServices, editOffer]
  )

  /** This should be triggered only once, by clicking publish button. */
  const handleInitialOfferPublishing = useCallback(() => {
    // Toggle status to Published, and add current timestamp.
    const updatedOffer = {
      ...currentOffer,
      status: OfferStatus.Published,
      creationDateTime: Date.now()
    }

    if (currentOffer) {
      saveNewVersion(updatedOffer, currentOffer.id!)
      togglePublishConfirmationModal()
      setState(state => ({ ...state, hasChanges: false }))
    }
  }, [togglePublishConfirmationModal, currentOffer, saveNewVersion])

  const handleAddServiceOrFieldOrHeader = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const serviceType: string = event.currentTarget.name

      if (!currentOffer) {
        return
      }

      // Depending on the type of the field/service, add different stuff.
      // Add non-optional data to the field.
      let newServiceOrField: OfferService | OfferField = {
        id: `${generateUuid()}`,
        type: serviceType // === 'service' ? 'service' : 'field'
      }

      // If service is generated add default discount amount of 0.
      if (newServiceOrField.type === 'service') {
        newServiceOrField = {
          ...newServiceOrField,
          discount: 0,
          quantity: 1,
          pricePerUnit: 10
        }
      }

      const services =
        currentOffer && currentOffer.services
          ? [...currentOffer!.services!, newServiceOrField]
          : [newServiceOrField]

      const updatedOffer = { ...currentOffer, services }

      editOffer(updatedOffer)
      setState(state => ({ ...state, hasChanges: true }))
    },
    [editOffer, currentOffer]
  )

  const handleRemoveServiceOrFieldOrHeader = useCallback(
    (id: string) => {
      if (!currentOffer || !currentOffer.services) {
        return
      }

      // Filter out the service with the deleted id.
      const updatedServices = currentOffer.services.filter(
        item => item && item.id !== id
      )

      const updatedOffer = {
        ...currentOffer,
        services: [...updatedServices]
      }

      editOffer(updatedOffer)
      setState(state => ({ ...state, hasChanges: true }))

      // set new state for react-hook-form
      if (!getValues || !remove) {
        return
      }

      const serviceIndex = getValues({ nest: true }).services.findIndex(
        item => item && item.id === id
      )
      remove(serviceIndex)
    },
    [editOffer, currentOffer, getValues, remove]
  )

  const selectClientFromDatabase = useCallback(
    (selectedClientId: string) => {
      const clientInfo =
        clients && clients.find(item => item.id === selectedClientId)

      if (clientInfo) {
        /** Hotfix for saving offer name
         * hotfix should be removed because this part is only fetching name
         * and all form field should be fetched and checked
         */
        let updatedName = 'Untitled Proposal'
        if (getValues) {
          updatedName = getValues({ nest: true }).name
        }
        /** END OF HOTFIX */

        const clientInfoString = `${clientInfo.name}, ${clientInfo.address}, VAT ID: ${clientInfo.taxId}, ${clientInfo.phone}, ${clientInfo.email}`

        const updatedOffer = {
          ...currentOffer,
          name: updatedName,
          clientInfo,
          clientInfoString
        }

        editOffer(updatedOffer)
        setState(state => ({ ...state, hasChanges: true }))
      }
    },
    [clients, currentOffer, editOffer, getValues]
  )

  return {
    state,
    selectClientFromDatabase,
    handleRemoveServiceOrFieldOrHeader,
    handleAddServiceOrFieldOrHeader,
    handleInitialOfferPublishing,
    handleSaveOffer,
    handleSelectHistoryVersion,
    onDragEnd,
    togglePublishConfirmationModal
  }
}
