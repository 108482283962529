import * as React from 'react';
import { withSettingsMenu } from 'components/hoc';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState, Dispatch } from 'modules/redux-store';
import { SettingsThunkActions, Settings } from 'modules/settings';
import { Loading, SlateEditor, InitialValue, serialize } from 'components';
import Dropzone from 'react-dropzone';
import * as uuid from 'uuid';
import firebase from 'firebase/app';
import 'firebase/storage';
import { Value } from 'slate';
import { toast } from 'react-toastify';

interface ReduxProps {
  settings?: Settings;
}

interface DispatchProps {
  getAllSettings: VoidFunction;
  updateSettings: (settings: Settings) => void;
}

type Props = ReduxProps & DispatchProps;
type State = Settings;

class GeneralSettings extends React.Component<Props, State> {
  state = {
    taxAmount: 0,
    companyInfo: undefined,
    companyLogo: '',
  };

  componentWillMount() {
    const { getAllSettings, settings } = this.props;

    getAllSettings();

    if (settings) {
      this.setState(settings);
    }
  }

  componentWillUpdate(nextProps: Props) {
    if (!this.props.settings && nextProps.settings) {
      this.setState(nextProps.settings);
    }
  }

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name } = event.currentTarget;
    let value: string | number = event.currentTarget.value;

    if (name === 'taxAmount') {
      value = parseInt(value);
      if (isNaN(value)) {
        value = 0;
      } else if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }
    }

    this.setState({
      [name]: value,
    });
  };

  handleSlateChange = (value: string) => {
    this.setState({
      companyInfo: value,
    });
  };

  onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length) {
      const snap = await firebase
        .storage()
        .ref()
        .child(`company-logo/${uuid.v4()}`)
        .put(acceptedFiles[0]);

      const imageDownloadUrl = await snap.ref.getDownloadURL();
      this.setState({
        companyLogo: imageDownloadUrl,
      });
    }
  };

  removeCompanyLogo = () => {
    this.setState({
      companyLogo: '',
    });
  };

  saveChanges = () => {
    const { updateSettings } = this.props;
    const updatedSettings = {
      id: 'generalSettings',
      ...this.state,
    };

    updateSettings(updatedSettings);
    toast.success('Changes are saved!');
  };

  renderDropzoneMessages = (isDragActive: boolean) => {
    return isDragActive ? (
      <p>Drop files here...</p>
    ) : (
      <p>Drop image to upload or click to select a file.</p>
    );
  };

  render() {
    const { settings } = this.props;
    const { taxAmount, companyInfo, companyLogo } = this.state;
    const slateBody = settings?.companyInfo
      ? Value.fromJSON(JSON.parse(serialize(settings.companyInfo)))
      : InitialValue;

    if (!settings) {
      return <Loading />;
    }

    return (
      <section className="settings">
        <header className="settings__header">
          <div className="settings__header__content">
            <h1 className="t-delta s-bottom--med">General settings</h1>
            <p className="t-small o-60">
              Most common settings used to configure your account such as tax
              settings and general company profile.
            </p>
          </div>
          <div className="settings__header__action">
            <button
              onClick={this.saveChanges}
              disabled={!taxAmount && !companyInfo}
              className="btn btn--sml btn--primary"
            >
              Save changes
            </button>
          </div>
        </header>
        <div className="general__tax">
          <h2 className="t-epsilon s-bottom--lrg">Global tax settings</h2>
          <div className="field">
            <label htmlFor="taxAmount" className="field__lbl">
              Default vat value
            </label>
            <input
              id="taxAmount"
              name="taxAmount"
              className="input input--w-micro input--text"
              type="number"
              min="0"
              max="100"
              value={taxAmount}
              onChange={this.handleInputChange}
            />{' '}
            %
          </div>
        </div>
        <div className="general__info">
          <h2 className="t-epsilon s-bottom--lrg">Company information</h2>
          <div className="field">
            <label htmlFor="companyInfo" className="field__lbl">
              Prototyp information
            </label>
            <SlateEditor
              content={slateBody}
              updateFieldCallback={this.handleSlateChange}
              placeholder="Enter your company info"
            />
          </div>
          <div className="field">
            <label htmlFor="companyLogo" className="field__lbl">
              Company logo
            </label>
            <section
              style={{ backgroundImage: `url(${companyLogo})` }}
              className="photobox"
            >
              {!companyLogo && (
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                      <div
                        {...getRootProps()}
                        className={`${
                          isDragActive
                            ? 'dropzone dropzone--isActive'
                            : 'dropzone'
                        }`}
                      >
                        <input {...getInputProps()} />
                        {this.renderDropzoneMessages(isDragActive)}
                      </div>
                    );
                  }}
                </Dropzone>
              )}
              {companyLogo && (
                <button
                  onClick={this.removeCompanyLogo}
                  className="btn btn--dark btn--sml photobox__delete"
                >
                  Remove cover image
                </button>
              )}
            </section>
          </div>
        </div>
      </section>
    );
  }
}

export default compose(
  withSettingsMenu,
  connect(
    (state: ApplicationState): ReduxProps => ({
      settings: state.settings.settings,
    }),
    (dispatch: Dispatch): DispatchProps => ({
      getAllSettings: () => dispatch(SettingsThunkActions.getAllAsync()),
      updateSettings: (settings: Settings) =>
        dispatch(SettingsThunkActions.updateSettings(settings)),
    })
  )
)(GeneralSettings);
