import 'firebase/firestore';
import uuid from 'uuid';

import { QueryFilter, ListenerProps } from '../models';

import { FirebaseService } from './FirebaseService';

export class FirebaseDatabaseService<T extends any> {
  private firebase = FirebaseService.Instance;
  private firestore = this.firebase.firestore();
  private collection: firebase.firestore.CollectionReference;

  constructor(collection: string) {
    this.collection = this.firestore.collection(collection);
  }

  async filterAsync(
    queryParams?: QueryFilter[],
    listenerProps?: ListenerProps
  ): Promise<string | T[] | VoidFunction> {
    let queryRef: firebase.firestore.Query | undefined;

    if (queryParams) {
      for (const query of queryParams) {
        queryRef = queryRef
          ? queryRef.where(query.field, query.operator, query.value)
          : this.collection.where(query.field, query.operator, query.value);
      }
    }

    const data = queryRef || this.collection;

    /** If this should be a listener function */
    if (listenerProps) {
      return data.onSnapshot(
        { includeMetadataChanges: true },
        snapshot => {
          const items = snapshot.docs.map(document => {
            const convertedDocument = document.data() as T;
            if (!convertedDocument.id) {
              convertedDocument.id = document.id;
            }
            return convertedDocument;
          });

          listenerProps.successFunction(items);
        },
        (error: Error) => listenerProps.errorFunction(error.message)
      );
    }

    return data
      .get()
      .then(snapshot =>
        snapshot.docs.map(document => ({
          id: document.id,
          ...(document.data() as any)
        }))
      )
      .catch((error: firebase.FirebaseError) => error.message);
  }

  async getAllAsync(): Promise<string | T[]> {
    return this.collection
      .get()
      .then(snapshot =>
        snapshot.docs.map(document => ({
          id: document.id,
          ...(document.data() as any)
        }))
      )
      .catch((error: firebase.FirebaseError) => error.message);
  }

  /** Add new documents or collection. */
  async addAsync(entity: (Partial<T> | T[]) & { id?: string }) {
    if (Array.isArray(entity)) {
      return entity.forEach(doc => this.collection.add(doc));
    }

    if (!entity.id) {
      entity.id = uuid.v4();
    }

    await this.collection.doc(entity.id).set(entity);

    return entity.id;
  }

  /** Add new offer document with initial draft data. Don't add version history item here. Pass documentId if updating existing offer with new draft, or just entity to create new offer with draft data. */
  async addDraft(entity: Partial<T> | T[], existingDocumentId?: string) {
    let newDocumentId;
    const result: any = {};

    // Create new empty offer document if existing document Id isn't passed.
    if (!existingDocumentId) {
      newDocumentId = await this.collection
        .add({})
        .then(doc => (result.id = doc.id))
        .catch(error => (result.error = error));
    }

    // Set latest state in main document to be queriable for all offers with one query.
    this.collection.doc(existingDocumentId || newDocumentId).set(entity);

    // Add draft collection with a document called draft with draft information. Update this on existing document if existingDocumentId is passed.
    this.collection
      .doc(existingDocumentId || newDocumentId)
      .collection('draft')
      .doc('draft')
      .set(entity);

    return result;
  }

  /** Set new version document in history collection for a specific offer. */
  async addNewVersion(entity: Partial<T> | T[], documentId: string) {
    // Generate new version document
    this.collection
      .doc(documentId)
      .collection('history')
      .doc()
      .set(entity);

    // Set latest state in main document to be queriable for all offers with one query.
    this.collection.doc(documentId).set(entity);

    // Remove drafts collection as new published version should remove draft for this offer.
    this.collection
      .doc(documentId)
      .collection('draft')
      .doc('draft')
      .delete();
  }

  batchDelete(snapshot: firebase.firestore.QuerySnapshot) {
    // When there are no documents left, we are done
    if (snapshot.size === 0) {
      return 0;
    }

    // Delete documents in a batch
    const batch = this.firestore.batch();
    snapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });

    return batch.commit().then(() => {
      return snapshot.size;
    });
  }

  async deleteCollection(
    currentDocument: firebase.firestore.DocumentReference,
    collectionName: string
  ) {
    return await currentDocument
      .collection(collectionName)
      .get()
      .then(this.batchDelete.bind(this));
  }

  async removeAsync(entityId: string) {
    const doc = this.collection.doc(entityId);
    this.deleteCollection(doc, 'history');
    this.deleteCollection(doc, 'draft');
    return doc.delete();
  }

  async getByIdAsync(entityId: string, listenerProps?: ListenerProps) {
    // If this should be listener.
    if (listenerProps) {
      return this.collection.doc(entityId).onSnapshot(
        { includeMetadataChanges: true },
        snapshot => {
          const result = snapshot.data() as T;
          listenerProps.successFunction(result);
        },
        (error: Error) => listenerProps.errorFunction(error.message)
      );
    }

    return this.collection
      .doc(entityId)
      .get()
      .then(snapshot => snapshot.data() as T)
      .catch((error: firebase.FirebaseError) => error.message);
  }

  // Update fields in existing doc.
  async updateAsync(entity: Partial<T>) {
    return this.collection.doc(entity.id).update(entity);
  }

  // Get ordered version history of a single offer by its id.
  async getHistory(entityId: string) {
    return this.collection
      .doc(entityId)
      .collection('history')
      .orderBy('creationDateTime', 'desc')
      .get()
      .then(snapshot =>
        snapshot.docs.map(
          (doc: firebase.firestore.DocumentSnapshot) => doc.data() as T
        )
      );
  }

  async getPublishedByIdAsync(entityId: string) {
    return this.collection
      .where('status', '==', 'Published')
      .where('id', '==', entityId)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          return 'error';
        }
        return snapshot.docs[0].data() as T;
      })
      .catch((error: firebase.FirebaseError) => error.message);
  }
}
