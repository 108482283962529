import * as React from 'react';
import { withSettingsMenu } from 'components/hoc';
import {
  NewClientModal,
  Client,
  ClientsThunkActions,
  ClientSelectors,
  ClientsActions,
} from 'modules/company-clients';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState, Dispatch } from 'modules/redux-store';
import { ClientItem } from 'modules/company-clients/components/ClientItem';
import { Loading, ConfirmationModal, PagedContent } from 'components';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

interface ReduxProps {
  clients?: Client[];
  clientsAreChanging: boolean;
  clientNameDuplicate: boolean;
  clientNameId: (id: string) => boolean;
}

interface DispatchProps {
  getAllClients: (clientId: string) => void;
  removeClient: (clientId: string) => void;
  editClient: (client: Partial<Client>) => void;
  saveClient: (client: Partial<Client>) => void;
  queryClientName: (query: string) => void;
}

interface State {
  newClientModal: boolean;
  confirmationModalIsOpen: boolean;
  id?: string;
  clientName: string;
  taxId: string;
  email: string;
  phone: string;
  address: string;
  clientNameValid: boolean;
  taxIdValid: boolean;
  emailValid: boolean;
  phoneValid: boolean;
  addressValid: boolean;
  editing: boolean;
  [param: string]: string | boolean | undefined;
}

type Props = ReduxProps & DispatchProps;

class Clients extends React.Component<Props, State> {
  state: State = {
    newClientModal: false,
    confirmationModalIsOpen: false,
    id: undefined,
    clientName: '',
    taxId: '',
    email: '',
    phone: '',
    address: '',
    editing: false,
    clientNameValid: false,
    taxIdValid: false,
    emailValid: false,
    phoneValid: false,
    addressValid: false,
  };

  createNewClient = () => {
    this.setState({
      id: '',
      clientName: '',
      taxId: '',
      email: '',
      phone: '',
      address: '',
    });

    this.openModal();
  };

  openModal = () => {
    this.setState({
      newClientModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      newClientModal: false,
      editing: false,
    });
    this.props.queryClientName('');
  };

  openConfirmationModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { clientId, clientName } = event.currentTarget.dataset;
    if (clientId) {
      this.setState({
        id: clientId,
        clientName: clientName || '',
        confirmationModalIsOpen: true,
      });
    }
  };

  closeConfirmationModal = () => {
    this.setState({
      id: undefined,
      confirmationModalIsOpen: false,
    });
  };

  handleInputChange: React.ReactEventHandler<HTMLInputElement> = (event) => {
    const { name } = event.currentTarget;
    const value: string = event.currentTarget.value;

    this.setState({
      [name]: value,
    });
  };

  handleValidatedInputChange = (
    propName: string,
    value: string,
    isValid?: boolean
  ) => {
    if (propName === 'clientName') {
      this.props.queryClientName(value);
    }
    this.setState({
      [propName]: value,
      [`${propName}Valid`]: isValid,
    });
  };

  saveNewClient = () => {
    const { saveClient, editClient } = this.props;
    const {
      clientName,
      taxId,
      email,
      phone,
      address,
      editing,
      id,
    } = this.state;

    const newClient: Partial<Client> = {
      id,
      name: clientName,
      email,
      taxId,
      address,
      phone,
    };

    if (!clientName) {
      return;
    }

    // If editing existing client.
    editing ? editClient(newClient) : saveClient(newClient);
    this.setState({ clientNameExists: false });

    this.closeModal();
    toast.success('Client is saved!');
  };

  editClient = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { clients } = this.props;
    const { clientId } = event.currentTarget.dataset;

    const clientToEdit =
      clients && clients.find((item) => item.id === clientId);

    if (!clientToEdit) {
      return null;
    }

    // Populate current state with selected client which needs to be edited.
    this.setState({
      id: clientToEdit.id,
      clientName: clientToEdit.name,
      email: clientToEdit.email || '',
      taxId: clientToEdit.taxId || '',
      address: clientToEdit.address || '',
      phone: clientToEdit.phone || '',
      editing: true,
    });

    this.openModal();
    return;
  };

  deleteClient = () => {
    const { removeClient } = this.props;
    const { id } = this.state;

    if (id) {
      removeClient(id);
      this.closeConfirmationModal();
    }
  };

  renderListItemComponent = (item: Client) => (
    <ClientItem
      key={item.id}
      clientInfo={item}
      clientName={item.name}
      editClientCallback={this.editClient}
      deleteClientCallback={this.openConfirmationModal}
    />
  );

  render() {
    const {
      newClientModal,
      confirmationModalIsOpen,
      clientName,
      taxId,
      email,
      phone,
      address,
      clientNameValid,
      editing,
      id,
    } = this.state;
    const {
      clients,
      clientsAreChanging,
      clientNameDuplicate,
      clientNameId,
    } = this.props;

    if (clientsAreChanging) {
      return <Loading />;
    }

    return (
      <section className="clients">
        <header className="settings__header">
          <div className="settings__header__content">
            <h1 className="t-delta s-bottom--med">
              <FormattedMessage id="page.clients" />
            </h1>
            <p className="t-small o-60">
              Set up clients and their information to use when adding a
              proposal.
            </p>
          </div>
          <div className="settings__header__action">
            <button
              onClick={this.createNewClient}
              className="btn btn--primary btn--sml"
            >
              <img
                src={require('assets/icons/icon-add-16-negative.svg')}
                alt=""
                className="btn__icon btn__icon--left"
              />
              <FormattedMessage id="button.addnew.client" />
            </button>
          </div>
        </header>
        <section className="clients__list">
          <header className="settings-itemlist__header settings-itemlist__header--clients">
            <span>Client name</span>
            <span>Actions</span>
          </header>
          {clients && (
            <PagedContent
              data={clients}
              renderItem={this.renderListItemComponent}
            />
          )}
        </section>

        <NewClientModal
          title={`${editing ? 'Edit' : 'Add new'} client`}
          isVisible={newClientModal}
          clientNameValid={clientNameValid}
          clientNameDuplicate={clientNameDuplicate}
          isEdit={editing}
          clientNameCheck={id}
          checkId={clientNameId}
          closeModalCallback={this.closeModal}
          saveCallback={this.saveNewClient}
          handleInputChangeCallback={this.handleValidatedInputChange}
          clientName={clientName}
          taxId={taxId}
          email={email}
          phone={phone}
          address={address}
        />

        <ConfirmationModal
          title="Confirm delete"
          itemNameEnglish={clientName}
          description="Are you sure?"
          isVisible={confirmationModalIsOpen}
          confirmCallback={this.deleteClient}
          cancelCallback={this.closeConfirmationModal}
        />
      </section>
    );
  }
}

export default compose(
  withSettingsMenu,
  connect(
    (state: ApplicationState): ReduxProps => ({
      clients: ClientSelectors.getSortedClients(state),
      clientsAreChanging: state.clients.clientsAreChanging,
      clientNameDuplicate: ClientSelectors.getClientDuplicate(state),
      clientNameId: (id: string) => ClientSelectors.getClientEdit(state, id),
    }),
    (dispatch: Dispatch): DispatchProps => ({
      getAllClients: () => dispatch(ClientsThunkActions.getAllAsync()),
      saveClient: (client: Partial<Client>) =>
        dispatch(ClientsThunkActions.saveNewClient(client)),
      editClient: (client: Partial<Client>) =>
        dispatch(ClientsThunkActions.editClient(client)),
      removeClient: (clientId: string) =>
        dispatch(ClientsThunkActions.removeAsync(clientId)),
      queryClientName: (query: string) =>
        dispatch(ClientsActions.clientsQuery(query)),
    })
  )
)(Clients);
