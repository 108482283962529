import * as React from 'react';
import {
  ServiceListItem,
  ServiceListHeader,
  ServicesThunkActions,
  Service,
  NewServiceModal,
  ServiceSelectors,
  ServiceActions,
} from 'modules/company-services';
import { FormattedMessage } from 'react-intl';
import { ApplicationState, Dispatch } from 'modules/redux-store';
import { connect } from 'react-redux';
import { PagedContent } from 'components/PagedContent';
import { compose } from 'redux';
import { withSettingsMenu } from 'components/hoc';
import { ConfirmationModal } from 'components';

interface ReduxProps {
  services?: Service[];
  servicesAreChanging: boolean;
  getDuplicateId: boolean;
  serviceCodeId: (id: string) => boolean;
}

interface DispatchProps {
  getAllServices: () => Promise<void>;
  removeService: (serviceId: string) => Promise<void>;
  updateService: (service: Service) => Promise<void>;
  saveNewService: (newService: Service | Partial<Service>) => Promise<void>;
  queryIdService: (query: string) => void;
}

type Props = ReduxProps & DispatchProps;

interface State {
  modalIsOpen: boolean;
  confirmationModalIsOpen: boolean;
  isEdit: boolean;
  [param: string]: string | boolean | number | undefined;
  serviceNameEnglish: string;
  serviceNameEnglishValid: boolean;
  serviceNameCroatian: string;
  serviceNameCroatianValid: boolean;
  serviceCode?: string;
  serviceCodeValid: boolean;
  id: string;
}

class Services extends React.Component<Props, State> {
  state = {
    modalIsOpen: false,
    confirmationModalIsOpen: false,
    isEdit: false,
    serviceNameEnglish: '',
    serviceNameEnglishValid: false,
    serviceNameCroatian: '',
    serviceNameCroatianValid: false,
    serviceCode: '',
    serviceCodeValid: false,
    id: '',
  };

  componentWillMount() {
    const { getAllServices } = this.props;
    getAllServices();
  }

  openModal = () => {
    this.setState({
      modalIsOpen: true,
      isEdit: false,
    });
  };

  closeModal = () => {
    this.setState({
      id: '',
      serviceCode: '',
      serviceNameEnglish: '',
      serviceNameCroatian: '',
      formValid: false,
      modalIsOpen: false,
    });
    this.props.queryIdService('');
  };

  openConfirmationModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { serviceId, titleEn, titleHr } = event.currentTarget.dataset;
    if (serviceId) {
      this.setState({
        id: serviceId,
        serviceNameEnglish: titleEn || '',
        serviceNameCroatian: titleHr || '',
        confirmationModalIsOpen: true,
      });
    }
  };

  closeConfirmationModal = () => {
    this.setState({
      id: '',
      confirmationModalIsOpen: false,
    });
  };

  handleSave = () => {
    const {
      serviceNameEnglish,
      serviceNameCroatian,
      serviceCode,
      isEdit,
      id,
    } = this.state;
    const { saveNewService, updateService } = this.props;

    /** If title in both languages is entered, create new unit object and push it to thunk to be saved in firestore. */
    if (serviceNameEnglish && serviceNameCroatian && serviceCode) {
      const newService: Service = {
        id,
        code: serviceCode,
        localisation: {
          en: {
            title: serviceNameEnglish,
          },
          hr: {
            title: serviceNameCroatian,
          },
        },
      };

      isEdit ? updateService(newService) : saveNewService(newService);
      this.setState({ serviceIdExists: false });
      this.closeModal();
    }
  };

  handleRemove = () => {
    const { removeService } = this.props;
    const { id } = this.state;

    if (id) {
      removeService(id);
      this.closeConfirmationModal();
    }
  };

  renderListItemComponent = (service: Service) => (
    <ServiceListItem
      key={service.code}
      id={service.id}
      code={service.code}
      titleEn={service.localisation.en.title}
      titleHr={service.localisation.hr.title}
      removeItemCallback={this.openConfirmationModal}
      editItemCallback={this.handleEdit}
    />
  );

  handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { services } = this.props;
    const { serviceId } = event.currentTarget.dataset;

    const serviceToEdit =
      services && serviceId && services.find((item) => item.id === serviceId);

    // If unit to edit hasn't been found abort editing.
    if (!serviceToEdit) {
      return;
    }

    // Populate current state with selected client which needs to be edited.
    this.setState({
      id: serviceToEdit.id,
      serviceCode: serviceToEdit.code,
      serviceNameEnglish: serviceToEdit.localisation.en.title,
      serviceNameCroatian: serviceToEdit.localisation.hr.title,
      modalIsOpen: true,
      isEdit: true,
    });
  };

  handleValidatedInputChange = (
    propName: string,
    value: string,
    isValid?: boolean
  ) => {
    if (propName === 'serviceCode') {
      this.props.queryIdService(value);
    }
    this.setState({
      [propName]: value,
      [`${propName}Valid`]: isValid,
    });
  };

  render() {
    const { services, getDuplicateId, serviceCodeId } = this.props;
    const {
      modalIsOpen,
      confirmationModalIsOpen,
      serviceNameEnglish,
      serviceNameCroatian,
      serviceCode,
      serviceCodeValid,
      serviceNameCroatianValid,
      serviceNameEnglishValid,
      isEdit,
      id,
    } = this.state;

    const formValid =
      serviceCodeValid && serviceNameCroatianValid && serviceNameEnglishValid;

    return (
      <React.Fragment>
        <section className="settings">
          <header className="settings__header">
            <div className="settings__header__content">
              <h1 className="t-delta s-bottom--med">
                <FormattedMessage id="page.services" />
              </h1>
              <p className="t-small o-60">
                Set up and manage your services for creating proposals.
              </p>
            </div>
            <div className="settings__header__action">
              <button
                onClick={this.openModal}
                className="btn btn--sml btn--primary"
              >
                <img
                  src={require('assets/icons/icon-add-16-negative.svg')}
                  alt=""
                  className="btn__icon btn__icon--left"
                />
                <FormattedMessage id="button.addnew.service" />
              </button>
            </div>
          </header>

          <ServiceListHeader />
          {services && (
            <PagedContent
              data={services}
              renderItem={this.renderListItemComponent}
            />
          )}
          <NewServiceModal
            title={`${isEdit ? 'Edit' : 'Add new'} service`}
            isVisible={modalIsOpen}
            formValid={formValid}
            getDuplicateId={getDuplicateId}
            closeModalCallback={this.closeModal}
            saveCallback={this.handleSave}
            handleInputChangeCallback={this.handleValidatedInputChange}
            isEdit={isEdit}
            checkId={serviceCodeId}
            serviceCodeCheck={id}
            serviceNameCroatian={serviceNameCroatian}
            serviceNameEnglish={serviceNameEnglish}
            serviceCode={serviceCode}
          />

          <ConfirmationModal
            title="Confirm delete"
            itemNameEnglish={serviceNameEnglish}
            description="Are you sure?"
            isVisible={confirmationModalIsOpen}
            confirmCallback={this.handleRemove}
            cancelCallback={this.closeConfirmationModal}
          />
        </section>
      </React.Fragment>
    );
  }
}

export default compose(
  withSettingsMenu,
  connect(
    (state: ApplicationState): ReduxProps => ({
      services: ServiceSelectors.getSortedServices(state),
      servicesAreChanging: state.services.servicesAreChanging,
      getDuplicateId: ServiceSelectors.getServiceDuplicate(state),
      serviceCodeId: (id: string) => ServiceSelectors.getServiceEdit(state, id),
    }),
    (dispatch: Dispatch): DispatchProps => ({
      getAllServices: () => dispatch(ServicesThunkActions.getAllAsync()),
      updateService: (service: Service) =>
        dispatch(ServicesThunkActions.updateService(service)),
      saveNewService: (newService: Service | Partial<Service>) =>
        dispatch(ServicesThunkActions.saveNewService(newService)),
      removeService: (serviceId: string) =>
        dispatch(ServicesThunkActions.removeAsync(serviceId)),
      queryIdService: (query: string) =>
        dispatch(ServiceActions.servicesQuery(query)),
    })
  )
)(Services);
