import firebase from 'firebase/app';

import * as env from '../env';

export class FirebaseService {
  private static instance: firebase.app.App;
  private static firebaseConfig = env.development;

  public static get Instance() {
    const firebaseEnv = process.env.REACT_APP_FIREBASE_ENV;
    if (firebaseEnv) {
      this.firebaseConfig = env[firebaseEnv];
    }

    return (
      this.instance ||
      (this.instance = firebase.initializeApp(this.firebaseConfig))
    );
  }
}
