import React from 'react';
import Select from 'react-select';
import { useFormContext, Controller, ErrorMessage } from 'react-hook-form';

import { SelectOption } from 'models';

interface Props {
  name: string;
  label?: string;
  sublabel?: string;
  defaultValue?: any;
  wrapperClassName?: string;
  errMsg?: string;
  options: SelectOption[];
  required?: boolean;
  placeholder?: string;
  styles?: Record<string, any>;
  onChange?: (value: any) => void;
  disabled?: boolean;
}

export const FormSelect: React.FC<Props> = ({
  name,
  label,
  sublabel,
  defaultValue,
  required,
  options,
  styles,
  placeholder,
  errMsg,
  onChange,
  disabled,
  wrapperClassName,
}) => {
  const { control, errors } = useFormContext();

  return (
    <div className={wrapperClassName}>
      {label && (
        <label htmlFor={name} className="field__lbl">
          {label}
        </label>
      )}

      <Controller
        as={
          <Select options={options} styles={styles} placeholder={placeholder} />
        }
        name={name}
        rules={{ required }}
        control={control}
        defaultValue={defaultValue}
        onChange={handleChange}
        isDisabled={disabled}
      />

      <p className="label-sub">{sublabel}</p>

      <ErrorMessage
        errors={errors || {}}
        name={name}
        message={errMsg || 'This field is required'}
        as={<p className="alert alert--warning" />}
      />
    </div>
  );

  function handleChange([selected]: any[]) {
    if (onChange) {
      onChange(selected.value);
    }

    return selected;
  }
};
