import * as views from 'views';
import { ACLType } from 'modules/authorization';

import { AppRoute } from '../models';

const errorRoutes: AppRoute[] = [
  {
    key: 'unauthorized',
    accessbilityLevel: ACLType.Public,
    component: views.PermissionError,
    exact: true,
    localisation: {
      en: { title: 'Unauthorized', path: '/not-authorized' },
      hr: {
        title: 'Pristup nije dozvoljen',
        path: '/hr/pristup-nije-dozvoljen'
      }
    }
  },
  {
    // Fallback route, always render last
    key: 'error',
    fallbackRoute: true,
    accessbilityLevel: ACLType.Public,
    component: views.GeneralError,
    localisation: {
      en: { title: 'Error', path: '/error' },
      hr: {
        title: 'Greška',
        path: '/hr/greska'
      }
    }
  }
];

export default errorRoutes;
