import { createSelector } from 'reselect';
import { ApplicationState } from 'modules/redux-store';
import { Client } from 'modules/company-clients';

const getClients = (state: ApplicationState) => state.clients.clients;
const getQuery = (state: ApplicationState) => state.clients.query;
const getClientById = (state: ApplicationState, id: string) =>
  state.clients.clients?.find((i) => i.id === id);

const getSortedClients = createSelector([getClients], (clients) => {
  return clients?.sort((a: Client, b: Client) => a.name.localeCompare(b.name));
});

const getClientDuplicate = createSelector(
  [getClients, getQuery],
  (clients, query) => !!clients?.find((a) => a.name === query)
);

const getClientEdit = createSelector(
  [getClients, getQuery, getClientById],
  (clients, query, client) =>
    !!clients?.find((i) => {
      return i.id !== client?.id ? i.name === query : undefined;
    })
);

export const ClientSelectors = {
  getSortedClients,
  getClientDuplicate,
  getClientEdit,
};
