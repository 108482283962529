import * as React from 'react';
import { FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { OfferStatus, ClientOfferStatus } from '../models';
import { FormattedClientStatus } from '../models/FormattedClientStatus';

interface Props {
  title?: string;
  creationDateTime: number;
  displayDateTime?: number;
  amount?: number;
  currency?: string;
  status?: OfferStatus;
  clientStatus?: ClientOfferStatus;
  year?: number;
  id: string;
  clientName?: string;
  handleDeleteOfferCallback: (id: string) => void;
  handleDuplicateOfferCallback: (id: string) => void;
  handlePublishOfferCallback: (id: string) => void;
  handleArchiveOfferCallback: (id: string) => void;
  changeClientStatusCallback: (
    id: string,
    selectedStatus: ClientOfferStatus
  ) => void;
}

interface State {
  formattedClientStatus?: FormattedClientStatus[];
}

export class OfferListItem extends React.Component<Props, State> {
  componentWillMount() {
    const formattedClientStatus = Object.keys(ClientOfferStatus).map((key) => ({
      value: ClientOfferStatus[key],
      label: ClientOfferStatus[key],
    }));

    this.setState({
      formattedClientStatus,
    });
  }

  duplicateOffer = () => {
    const { handleDuplicateOfferCallback, id } = this.props;
    handleDuplicateOfferCallback(id);
    toast.success('Offer has been duplicated as draft!');
  };

  deleteOffer = () => {
    const { handleDeleteOfferCallback, id } = this.props;
    handleDeleteOfferCallback(id);
  };

  publishOffer = () => {
    const { handlePublishOfferCallback, id } = this.props;
    handlePublishOfferCallback(id);
  };

  archiveOffer = () => {
    const { handleArchiveOfferCallback, id } = this.props;
    handleArchiveOfferCallback(id);
    toast.success('Offer has been arhived!');
  };

  changeClientStatus = (selectedStatus: any) => {
    const { changeClientStatusCallback, id } = this.props;

    changeClientStatusCallback(id, selectedStatus.value);
    toast.success('Client status changed!');
  };

  onSuccessfulCopy = () => {
    // TODO add message for successful copy to clipboard
    toast.info('Link copied to clipboard!');
  };

  render() {
    const {
      title,
      creationDateTime,
      displayDateTime,
      amount,
      currency,
      status,
      clientStatus,
      id,
      clientName,
    } = this.props;

    const { formattedClientStatus } = this.state;

    return (
      <section className="proposal-item">
        <div className="proposal-item__info">
          <div className="proposal-item__main">
            <p className="t-tiny t-upperext o-60 s-bottom--sml">{clientName}</p>
            <NavLink to={`/offers/add/${id}`} className="proposal-item__title">
              <h1 className="t-epsilon">{title}</h1>
            </NavLink>
            <p className="t-tiny o-60">
              {creationDateTime && (
                <FormattedDate
                  value={new Date(displayDateTime || creationDateTime)}
                  year="numeric"
                  month="long"
                  hour="numeric"
                  minute="numeric"
                  day="2-digit"
                />
              )}
            </p>
          </div>
          <div className="proposal-item__client-status">
            <p className="t-tiny t-upperext o-60 s-bottom--sml">
              <FormattedMessage id="offers.listitem.status" />
            </p>
            <Select
              name="currency"
              value={{
                label: clientStatus || ClientOfferStatus.Pending,
                value: clientStatus || ClientOfferStatus.Pending,
              }}
              onChange={this.changeClientStatus}
              options={formattedClientStatus}
            />
          </div>
          <div className="proposal-item__amount">
            <p className="t-tiny t-upperext o-60 s-bottom--sml">
              <FormattedMessage id="offers.listitem.amount" />
            </p>
            <p>
              {amount ? (
                <React.Fragment>
                  <FormattedNumber
                    value={amount}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />{' '}
                  {` ${currency}`}
                </React.Fragment>
              ) : (
                '/'
              )}
            </p>
          </div>
        </div>
        <div className="proposal-item__actions">
          {status && status === OfferStatus.Published && (
            <React.Fragment>
              <Link
                to={`/view/${id}`}
                target={'_blank'}
                className="btn btn--primary btn--basic btn--tny proposal-item__actions__item"
              >
                <FormattedMessage id="button.preview" />
              </Link>

              <CopyToClipboard
                text={`${window.location.origin}/view/${id}`}
                onCopy={this.onSuccessfulCopy}
              >
                <button className="btn btn--basic btn--tny proposal-item__actions__item">
                  Copy link
                </button>
              </CopyToClipboard>

              <button
                onClick={this.archiveOffer}
                className="btn btn--basic btn--tny proposal-item__actions__item"
              >
                <FormattedMessage id="button.archive" />
              </button>
            </React.Fragment>
          )}

          {status && status !== OfferStatus.Published && (
            <button
              onClick={this.publishOffer}
              className="btn btn--primary btn--tny proposal-item__actions__item"
            >
              <FormattedMessage id="button.publish" />
            </button>
          )}

          <button
            onClick={this.duplicateOffer}
            className="btn btn--basic btn--tny proposal-item__actions__item"
          >
            <FormattedMessage id="button.duplicate" />
          </button>

          <button
            data-offer-id={id}
            data-title={title}
            onClick={this.deleteOffer}
            className="btn btn--basic btn--tny proposal-item__actions__item"
          >
            <FormattedMessage id="button.delete" />
          </button>
        </div>
      </section>
    );
  }
}
