import { AppRoute } from '../models';
import { applicationRoutes } from '../const';

export interface NavigationState {
  readonly routes: AppRoute[];
}

const INITIAL_STATE: NavigationState = {
  routes: applicationRoutes
};

export const navigationReducer = (
  state: NavigationState = INITIAL_STATE,
  action: any
): NavigationState => {
  switch (action.type) {
    default:
      return state || INITIAL_STATE;
  }
};
