enum ActionTypes {
  ClientsRequest = 'clients/REQUEST',
  ClientsSuccess = 'clients/SUCCESS',
  ClientsError = 'clients/ERROR',
  ClientsQuery = 'clients/QUERY',
}

export const ClientsActionTypes = {
  ...ActionTypes,
};
