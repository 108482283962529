import { BaseOfferService } from './BaseOfferService';
import { SelectOption } from 'models';

export interface OfferService extends BaseOfferService {
  serviceId?: string; // id of connected service
  name?: string; // Project management
  additionalField?: string; // textarea markup content
  quantity?: number; // 34, 66
  body?: string;
  unitId?: string; // id of connected unit
  unit?: string | SelectOption; // day, hour, month ...
  pricePerUnit?: number; // 30
  discount?: number; // 10%
  total?: {
    priceWithoutDiscount?: number;
    discountAmount?: number;
    price?: number;
  };
}

export const serviceIsOfferService = (
  service?: BaseOfferService
): service is OfferService => {
  return service?.type === 'service';
};
