import * as React from 'react';
import { ApplicationState, Dispatch } from 'modules/redux-store';
import { connect } from 'react-redux';
import {
  Offer,
  OfferService,
  OfferField,
  serviceIsOfferService,
  serviceIsOfferField,
  OfferHeading,
  OfferStatus,
  ClientInfo,
} from 'modules/offers';
import { RouteComponentProps } from 'react-router';
import { PublicOfferThunkAction } from 'modules/view-offer';
import { SlateEditor, PageLoading, serialize } from 'components';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { Value } from 'slate';

interface ReduxProps {
  loggedIn: boolean;
  publicOffer?: Partial<Offer>;
  publicOfferIsUpdating: boolean;
  error?: string;
}

interface DispatchProps {
  getSingleOffer: (id: string) => Promise<void>;
}

interface State {
  id?: string;
}

type Props = ReduxProps & RouteComponentProps<any> & DispatchProps;

class ViewOffer extends React.Component<Props, State> {
  componentDidMount() {
    const { match } = this.props;

    if (match.params && match.params.id) {
      this.props.getSingleOffer(match.params.id);
    }
  }

  renderHeading = (item: OfferHeading) => {
    return (
      <header key={item.id} className="proposal-block-heading">
        <div className="proposal-block-heading__heading">
          <h1 className="t-delta">{item.heading}</h1>
        </div>
        <div className="proposal-block-heading__description">
          <h2 className="t-zeta o-60">{item.subheading}</h2>
        </div>
      </header>
    );
  };

  renderService = (item: OfferService) => {
    const { publicOffer } = this.props;

    if (!publicOffer) {
      return null;
    }

    return (
      <div key={item.id} className="proposal-block-service">
        <div className="proposal-block-service__info">
          <h3 className="proposal-block-service__name">{item.name}</h3>
          {/* <p className="t-eta o-60">{item.additionalField}</p> */}
          {item.additionalField && (
            <SlateEditor
              className={'is-readonly t-small o-60'}
              content={Value.fromJSON(JSON.parse(item.additionalField))}
              readOnly={true}
            />
          )}
        </div>
        <div className="proposal-block-service__pricing">
          <div className="proposal-block-service__pricing__top">
            <div className="proposal-block-service__pricing__top__quantity">
              <p className="t-tiny t-upperext o-60">Quantity ({item.unit}):</p>{' '}
              <span className="t-eta">{item.quantity}</span>
            </div>
            <div className="proposal-block-service__pricing__top__price">
              <p className="t-tiny t-upperext o-60">Price:</p>{' '}
              <span className="t-eta">
                <FormattedNumber
                  value={item.pricePerUnit! || 0}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
                {` ${publicOffer.currency}`}
              </span>
            </div>
            <div className="proposal-block-service__pricing__top__totalvalue">
              <span className="t-eta">
                <FormattedNumber
                  value={item.pricePerUnit! * item.quantity!}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
                {` ${publicOffer.currency}`}
              </span>
            </div>
          </div>
          {item.discount !== 0 && (
            <div className="proposal-block-service__pricing__discount">
              <div>
                <p className="t-tiny t-upperext o-60">Discount:</p>{' '}
                <span>{item.discount}%</span>
              </div>
              <div /> {/* This extra DIV is for flexbox layouting purposes */}
              <div className="t-right">
                <span>
                  {Number(item.discount) > 0 && '-'}
                  <FormattedNumber
                    value={
                      item.pricePerUnit! *
                      item.quantity! *
                      (item.discount! / 100)
                    }
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                  {` ${publicOffer.currency}`}
                </span>
              </div>
            </div>
          )}
          <div className="proposal-block-service__pricing__subtotal">
            <div>
              <p className="t-tiny t-upperext">
                <strong>Subtotal</strong>
              </p>
            </div>
            <div />
            <div className="t-right">
              <span>
                <strong>
                  <FormattedNumber
                    value={
                      item.pricePerUnit! * item.quantity! -
                      item.pricePerUnit! *
                        item.quantity! *
                        (item.discount! / 100)
                    }
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                  {` ${publicOffer.currency}`}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderField = (item: OfferField) => {
    const { publicOffer } = this.props;

    if (!publicOffer) {
      return null;
    }

    return (
      <div key={item.id} className="proposal-block-text">
        {item.body && (
          <SlateEditor
            className={'is-readonly t-eta o-80'}
            content={Value.fromJSON(JSON.parse(item.body))}
            readOnly={true}
          />
        )}
      </div>
    );
  };

  render() {
    const { publicOffer, publicOfferIsUpdating, error, loggedIn } = this.props;

    if (publicOfferIsUpdating) {
      return <PageLoading />;
    }

    if (error) {
      return <h1>Error. Write some witty error.</h1>;
    }

    if (!publicOffer) {
      return null;
    }

    if (!loggedIn && publicOffer.status !== OfferStatus.Published) {
      return <h1>You cannot access this offer!</h1>;
    }

    if (publicOffer.status === OfferStatus.Archived) {
      return <h1>Offer has been arhived!</h1>;
    }

    return (
      <section className="proposal">
        <header
          className="proposal__header"
          style={{
            backgroundImage: `url(${publicOffer.coverPhoto})`,
          }}
        >
          <div className="wrapper wrapper--tight">
            <div className="proposal__header__heading">
              <p className="t-negative t-tiny t-upperext o-60">
                Created:{' '}
                <FormattedDate
                  value={
                    publicOffer.displayDateTime
                      ? new Date(publicOffer.displayDateTime)
                      : new Date(publicOffer.creationDateTime!)
                  }
                  year="numeric"
                  month="long"
                  hour="numeric"
                  minute="numeric"
                  day="2-digit"
                />
              </p>
              {publicOffer.validUntilDateTime && (
                <p className="t-negative t-tiny t-upperext o-60 s-top--tny">
                  Valid until:{' '}
                  <FormattedDate
                    value={new Date(publicOffer.validUntilDateTime)}
                    year="numeric"
                    month="long"
                    hour="numeric"
                    minute="numeric"
                    day="2-digit"
                  />
                </p>
              )}

              <h1 className="t-gamma t-negative t-light s-top--med">
                <strong>{publicOffer.name}</strong>
              </h1>
            </div>
            <ClientInfo clientInfo={publicOffer.clientInfo} />
          </div>
        </header>

        <section className="proposal__details">
          <div className="wrapper wrapper--tight">
            {publicOffer.services &&
              publicOffer.services.map((item: any) =>
                serviceIsOfferService(item)
                  ? this.renderService(item)
                  : serviceIsOfferField(item)
                  ? this.renderField(item)
                  : this.renderHeading(item)
              )}
          </div>

          <div className="wrapper wrapper--tight">
            <div className="proposal__total">
              <ul className="proposal__total__list">
                <li className="proposal__total__list__item">
                  <span className="proposal__total__list__label o-60">
                    Subtotal:
                  </span>
                  <span className="proposal__total__list__value">
                    <FormattedNumber
                      value={publicOffer.total!.priceWithoutVat! || 0}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                    {` ${publicOffer.currency}`}
                  </span>
                </li>
                <li className="proposal__total__list__item">
                  <span className="proposal__total__list__label o-60">
                    VAT / Tax:
                  </span>
                  <span className="proposal__total__list__value">
                    {publicOffer.total && publicOffer.total.vat}%
                  </span>
                </li>
                <li className="proposal__total__list__item">
                  <span className="proposal__total__list__label o-60">
                    VAT Amount:
                  </span>
                  <span className="proposal__total__list__value">
                    <FormattedNumber
                      value={publicOffer.total!.vatAmount! || 0}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                    {` ${publicOffer.currency}`}
                  </span>
                </li>
                <li className="proposal__total__list__item proposal__total__list__item--total">
                  <span className="proposal__total__list__label">Total:</span>
                  <span className="proposal__total__list__value t-epsilon">
                    <strong>
                      <FormattedNumber
                        value={publicOffer.total!.price! || 0}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                      {` ${publicOffer.currency}`}
                    </strong>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="wrapper wrapper--tight">
            <footer className="proposal__footer">
              <div className="proposal__footer__companyinfo">
                <div className="proposal__footer__companyinfo__branding">
                  {publicOffer.companyLogo && (
                    <img alt="company logo" src={publicOffer.companyLogo} />
                  )}
                </div>
                <div className="proposal__footer__companyinfo__info">
                  <p className="t-small o-60">
                    <SlateEditor
                      className={'is-readonly t-eta o-80'}
                      content={Value.fromJSON(
                        JSON.parse(serialize(publicOffer.companyInfo || ''))
                      )}
                      readOnly={true}
                    />
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </section>
      </section>
    );
  }
}

export default connect(
  (state: ApplicationState): ReduxProps => ({
    loggedIn: state.auth.isLoggedIn,
    publicOffer: state.publicOffer.publicOffer,
    publicOfferIsUpdating: state.publicOffer.publicOfferIsUpdating,
    error: state.publicOffer.error,
  }),
  (dispatch: Dispatch): DispatchProps => ({
    getSingleOffer: (id: string) =>
      dispatch(PublicOfferThunkAction.getSingleOffer(id)),
  })
)(ViewOffer);
