import { Service } from '../models';

import { ServiceActions } from './actions';
import { ServiceActionTypes } from './types';

export interface ServiceState {
  readonly servicesAreChanging: boolean;
  readonly services?: Service[];
  readonly error?: string;
  readonly query: string;
}

const INITIAL_STATE: ServiceState = {
  servicesAreChanging: false,
  services: undefined,
  error: undefined,
  query: '',
};

export const servicesReducer = (
  state: ServiceState = INITIAL_STATE,
  action: ServiceActions
): ServiceState => {
  switch (action.type) {
    case ServiceActionTypes.ServiceRequest:
      return {
        ...state,
        servicesAreChanging: true,
        error: undefined,
      };
    case ServiceActionTypes.ServiceSuccess:
      return {
        ...state,
        servicesAreChanging: false,
        services: action.payload.services,
      };
    case ServiceActionTypes.ServiceError:
      return {
        ...state,
        servicesAreChanging: false,
        error: action.payload.error,
      };
    case ServiceActionTypes.ServiceQuery:
      return {
        ...state,
        query: action.payload.query,
      };
    default:
      return state || INITIAL_STATE;
  }
};
