import { Client } from 'modules/company-clients';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { ClientOfferStatus, Offer } from '../models';
import { FormSelect, RichTextEditor } from 'components';

interface Props {
  currentOffer: Partial<Offer>;
  clients?: Client[];
  selectClientFromDatabaseCallback: (selectedClientId: string) => void;
}

export const OfferClientInformation: React.FC<Props> = ({
  currentOffer,
  clients,
  selectClientFromDatabaseCallback,
}) => {
  const { register, setValue } = useFormContext();
  const { clientInfo, companyInfo } = currentOffer;

  useEffect(() => {
    register('clientInfo');
    register('clientInfoString');
    register('clientStatus');

    setValue('clientInfo', currentOffer?.clientInfo);
    setValue('clientInfoString', currentOffer?.clientInfoString);
    setValue(
      'clientStatus',
      currentOffer?.clientStatus || ClientOfferStatus.Pending
    );
  }, [register, setValue, currentOffer]);

  return (
    <div className="wrapper">
      <div className="general-info__fromto">
        <div className="general-info__company-info">
          {companyInfo && (
            <RichTextEditor
              wrapperClassName="field"
              editorClassName="slate__editor--height"
              label="Company info"
              body={companyInfo}
              name="companyInfo"
              placeholder="Enter your company info here or edit it permanently in the settings menu."
            />
          )}
          <NavLink to="/settings" className="btn btn--core btn--basic">
            Edit info
          </NavLink>
        </div>
        <div className="general-info__clientinfo">
          <p className="t-micro t-upperext o-60 s-bottom--sml">
            Select a client
          </p>
          <div className="field">
            {clients && (
              <FormSelect
                name="clientInfoSelect"
                defaultValue={
                  !clientInfo
                    ? undefined
                    : { value: clientInfo.id, label: clientInfo.name }
                }
                options={clients.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={selectClientFromDatabaseCallback}
                required
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
