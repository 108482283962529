import { Dispatch } from 'modules/redux-store';
import { FirebaseAuthService } from 'modules/firebase/services';

import { AuthenticationActions } from './actions';

const firebaseAuth = new FirebaseAuthService();

const loginAsync = (
  email: string,
  password: string,
  rememberMe: boolean
) => async (dispatch: Dispatch) => {
  dispatch(AuthenticationActions.authStateChange());

  const error = await firebaseAuth.loginWithEmailAndPasswordAsync(
    email,
    password,
    rememberMe
  );

  if (error) {
    dispatch(AuthenticationActions.loginError(error));
  }
};

const loginWithPersistedUser = () => (dispatch: Dispatch) => {
  dispatch(AuthenticationActions.authStateChange());
  firebaseAuth.initAuthStateListener(dispatch);
};

const logout = () => async (dispatch: Dispatch) => {
  await firebaseAuth.logoutAsync();
  dispatch(AuthenticationActions.logout());
};

export const AuthenticationThunkActions = {
  loginAsync,
  loginWithPersistedUser,
  logout
};
