import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal } from './Modal';

interface Props {
  confirmCallback: VoidFunction;
  cancelCallback: VoidFunction;
  isVisible: boolean;
  title: string;
  description?: string;
  itemNameEnglish?: string;
}

export const ConfirmationModal: React.SFC<Props> = ({
  confirmCallback,
  cancelCallback,
  isVisible,
  title,
  description,
  itemNameEnglish,
}) => (
  <Modal
    className={`${isVisible ? 'modal modal is-visible' : 'modal'}`}
    closeModalCallback={cancelCallback}
    saveCallback={confirmCallback}
    isVisible={isVisible}
  >
    <div className="modal__wrapper">
      <header className="modal__header">
        <h1 className="title modal__title t-gamma">{title}</h1>

        <p className="t-small item__description ">
          {description || <FormattedMessage id="" />}
        </p>
      </header>
      <div className="modal__content">
        <section className="item item__title">
          <p className="t-zeta ">{itemNameEnglish}</p>
        </section>
      </div>
      <div className="modal__footer">
        <div className="btn-group btn-group--simple">
          <button
            type="button"
            onClick={cancelCallback}
            className="btn btn--ghost t-primary btn--sml"
          >
            No, keep it
          </button>
          <button
            type="button"
            onClick={confirmCallback}
            className="btn btn--primary btn--sml"
          >
            Yes, delete it
          </button>
        </div>
      </div>
      <button
        type="button"
        onClick={cancelCallback}
        className="btn btn--core modal__close"
      >
        <svg width="12" height="12">
          <path d="M10.707 1.293a1 1 0 0 0-1.414 0L6 4.586 2.707 1.293a1 1 0 0 0-1.414 1.414L4.586 6 1.293 9.293a1 1 0 1 0 1.414 1.414L6 7.414l3.293 3.293a1 1 0 0 0 1.414-1.414L7.414 6l3.293-3.293a1 1 0 0 0 0-1.414z" />
        </svg>
      </button>
    </div>
  </Modal>
);
