import * as React from 'react';

export const Loading: React.SFC = () => (
  <div className="loading">
    <div />
    <div />
    <div />
    <div />
  </div>
);
