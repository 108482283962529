import * as React from 'react';
import { NavLink } from 'react-router-dom';
import {
  OfferListItem,
  YearFilters,
  StatusFilters,
} from 'modules/offers/components';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ApplicationState, Dispatch } from 'modules/redux-store';
import * as uuid from 'uuid';
import {
  OffersThunkAction,
  Offer,
  ItemSelectors,
  OfferStatus,
  OfferOrdering,
  ClientOfferStatus,
} from 'modules/offers';
import { Loading, ConfirmationModal } from 'components';
import { OrderFilters } from 'modules/offers/components/OrderFilters';
import { toast } from 'react-toastify';

interface ReduxProps {
  allOffers?: Offer[];
  draftOffers?: Offer[];
  publishedOffers?: Offer[];
  archivedOffers?: Offer[];
  offersAreChanging: boolean;
}

interface State {
  activeType: OfferStatus;
  currentOfferType?: Offer[];
  currentOfferStatus: OfferStatus;
  currentOrder: OfferOrdering;
  filterByYear: number | undefined;
  clientStatusFilter?: ClientOfferStatus;
  offerId?: string;
  offerName?: string;
  confirmationDialogIsOpen: boolean;
  publishConfirmationModalIsOpen: boolean;
}

interface DispatchProps {
  getAllOffers: () => Promise<void>;
  removeOffer: (id: string) => Promise<void>;
  updateDraft: (offer: Partial<Offer>, id: string) => Promise<void>;
  saveNewVersion: (offer: Partial<Offer>, id: string) => Promise<void>;
}

type Props = ReduxProps & DispatchProps;

class Offers extends React.Component<Props, State> {
  state = {
    activeType: OfferStatus.Draft,
    currentOfferStatus: OfferStatus.Published,
    currentOrder: OfferOrdering.Date,
    filterByYear: new Date().getFullYear(),
    clientStatusFilter: undefined,
    offerId: undefined,
    offerName: undefined,
    confirmationDialogIsOpen: false,
    publishConfirmationModalIsOpen: false,
  } as State;

  componentWillMount() {
    this.props.getAllOffers();

    if (this.props.allOffers) {
      this.setNewOfferType(this.state.currentOfferStatus, this.props);
    }
  }

  componentWillUpdate(nextProps: Props, nextState: State) {
    // Initial setting of offer filtering to default value.
    if (!this.props.allOffers && nextProps.allOffers && nextProps.draftOffers) {
      this.setState({
        currentOfferType: nextProps.draftOffers,
      });
    }

    // This populates currentOfferType when returning from other screens and offers have changed (i.e. new offer, then back to offers), or when deleting offers.
    if (this.props.allOffers !== nextProps.allOffers) {
      this.setNewOfferType(nextState.currentOfferStatus, nextProps);
    }

    // Handles refiltering of offers when changing filter type.
    if (this.state.currentOfferStatus !== nextState.currentOfferStatus) {
      this.setNewOfferType(nextState.currentOfferStatus, nextProps);
    }
  }

  openConfirmationModal = (id: string) => {
    this.setState({
      offerId: id,
      offerName: this.props.allOffers?.find((o) => o.id === id)?.name,
      confirmationDialogIsOpen: true,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      offerId: undefined,
      offerName: undefined,
      confirmationDialogIsOpen: false,
    });
  };

  openPublishConfirmationModal = (id: string) =>
    this.setState({
      offerId: id,
      offerName: this.props.allOffers?.find((o) => o.id === id)?.name,
      publishConfirmationModalIsOpen: true,
    });

  closePublishConfirmationModal = () =>
    this.setState({
      offerId: undefined,
      offerName: undefined,
      publishConfirmationModalIsOpen: false,
    });

  /** Sets currentOfferType (currently shown, filtered offers) based on selected filter. */
  setNewOfferType = (currentOfferStatus: OfferStatus, nextProps: Props) => {
    let newActiveOfferType;
    switch (currentOfferStatus) {
      case OfferStatus.Published:
        newActiveOfferType = nextProps.publishedOffers;
        break;
      case OfferStatus.Archived:
        newActiveOfferType = nextProps.archivedOffers;
        break;
      default:
        newActiveOfferType = nextProps.draftOffers;
        break;
    }

    this.setState({
      currentOfferType: newActiveOfferType,
    });
  };

  /** Deletes offer from API and reloads everything to fetch new state of the offers. */
  handleDeleteOffer = () => {
    this.props.removeOffer(this.state.offerId!);
    this.closeConfirmationModal();
    toast.success('Offer has been deleted!');
  };

  handleDuplicateOffer = (id: string) => {
    const { allOffers, updateDraft } = this.props;

    // Find this offer from all available offers.
    let offerToDuplicate =
      allOffers && allOffers.find((item) => item.id === id);

    //  If found, update offerSstatus with current timestamp, save it to redux and firebase, and reload all offers.
    if (offerToDuplicate) {
      offerToDuplicate = {
        ...offerToDuplicate,
        id: uuid.v4(),
        status: OfferStatus.Draft,
        creationDateTime: Date.now(),
      };

      updateDraft(offerToDuplicate, offerToDuplicate.id);
    }
  };

  /** Publish offer directly from all offers list. */
  handlePublishOffer = () => {
    const { allOffers, saveNewVersion } = this.props;
    const { offerId } = this.state;

    // Find this offer from all available offers.
    let offerToPublish =
      allOffers && allOffers.find((item) => item.id === offerId);

    //  If found, update offerSstatus with current timestamp, save it to redux and firebase, and reload all offers.
    if (offerToPublish) {
      offerToPublish = {
        ...offerToPublish,
        clientStatus: ClientOfferStatus.Pending,
        status: OfferStatus.Published,
        creationDateTime: Date.now(),
      };

      saveNewVersion(offerToPublish, offerId!);
      this.closePublishConfirmationModal();
      toast.success('Offer has been published!');
    }
  };

  handleArchiveOffer = (id: string) => {
    const { allOffers, saveNewVersion } = this.props;

    // Find this offer from all available offers.
    let offerToPublish = allOffers && allOffers.find((item) => item.id === id);

    //  If found, update offerSstatus with current timestamp, save it to redux and firebase, and reload all offers.
    if (offerToPublish) {
      offerToPublish = {
        ...offerToPublish,
        status: OfferStatus.Archived,
        creationDateTime: Date.now(),
      };

      saveNewVersion(offerToPublish, id);
    }
  };

  /** Sets current offer type based on clicked filter. */
  handleSelectType: React.ReactEventHandler<HTMLLIElement> = (event) => {
    const id: string = event.currentTarget.id;

    this.setState({
      currentOfferStatus: OfferStatus[id],
    });
  };

  handleChangeOrder: React.ReactEventHandler<HTMLButtonElement> = (event) => {
    const orderType: OfferOrdering = event.currentTarget.dataset
      .orderType as OfferOrdering;

    this.setState({
      currentOrder: orderType,
    });
  };

  applyFiltersAndOrders = () => {
    const {
      currentOfferType,
      currentOrder,
      filterByYear,
      clientStatusFilter,
      currentOfferStatus,
    } = this.state;

    let result = currentOfferType;

    /** If current filter is set to name, order by name ascending */
    if (currentOrder === OfferOrdering.Name && result) {
      result = result.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
    }

    /** If current filter is set to date, order by date descending */
    if (currentOrder === OfferOrdering.Date && result) {
      result = result.sort((a, b) =>
        (a.displayDateTime ? a.displayDateTime : a.creationDateTime) <
        (b.displayDateTime ? b.displayDateTime : b.creationDateTime)
          ? 1
          : -1
      );
    }

    /** If filter year is set, filter out all offers by displayDateTime if it's set, and by creationDateTime if not. */
    if (filterByYear && result) {
      result = result.filter(
        (item: Offer) =>
          (item.displayDateTime
            ? new Date(item.displayDateTime).getFullYear()
            : new Date(item.creationDateTime).getFullYear()) === filterByYear
      );
    }

    /** If status filter is set, filter out all offers by this status */
    if (
      clientStatusFilter &&
      result &&
      currentOfferStatus === OfferStatus.Published
    ) {
      result = result.filter(
        (item: Offer) => item.clientStatus === clientStatusFilter
      );
    }

    return result;
  };

  changeClientStatus = (id: string, selectedStatus: ClientOfferStatus) => {
    const { allOffers, saveNewVersion } = this.props;

    // Find this offer from all available offers.
    let thisOffer = allOffers && allOffers.find((item) => item.id === id);

    //  If found, update offerSstatus with current timestamp, save it to redux and firebase, and reload all offers.
    if (thisOffer) {
      thisOffer = {
        ...thisOffer,
        clientStatus: selectedStatus,
      };

      saveNewVersion(thisOffer, id);
    }
  };

  showAllYears = () => {
    this.setState({
      filterByYear: undefined,
    });
  };

  selectYear: React.ReactEventHandler<HTMLButtonElement> = (event) => {
    const selectedYear = event.currentTarget.dataset.year;

    if (selectedYear) {
      this.setState({
        filterByYear: parseInt(selectedYear),
      });
    }
  };

  setClientStatusFilter: React.ReactEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const selectedStatus = event.currentTarget.dataset
      .status as ClientOfferStatus;

    if (selectedStatus) {
      this.setState({
        clientStatusFilter: selectedStatus,
      });
    }
  };

  clearStatusFilters = () => {
    this.setState({
      clientStatusFilter: undefined,
    });
  };

  render() {
    const { offersAreChanging } = this.props;
    const {
      currentOfferStatus,
      currentOrder,
      filterByYear,
      clientStatusFilter,
      confirmationDialogIsOpen,
      publishConfirmationModalIsOpen,
    } = this.state;

    const filteredAndOrderedContent = this.applyFiltersAndOrders();

    return (
      <section className="offers">
        <header className="subheader">
          <div className="subheader__wrapper wrapper wrapper--narrow">
            <div className="subheader__split">
              <ul className="subheader__links">
                <li
                  onClick={this.handleSelectType}
                  role="button"
                  id={OfferStatus.Published}
                  className={`subheader__links__item ${
                    currentOfferStatus === OfferStatus.Published
                      ? 'subheader__links__item--active'
                      : ''
                  }`}
                >
                  <FormattedMessage id="filter.button.published" />
                </li>
                <li
                  onClick={this.handleSelectType}
                  role="button"
                  id={OfferStatus.Draft}
                  className={`subheader__links__item ${
                    currentOfferStatus === OfferStatus.Draft
                      ? 'subheader__links__item--active'
                      : ''
                  }`}
                >
                  <FormattedMessage id="filter.button.drafts" />
                </li>
                <li
                  onClick={this.handleSelectType}
                  role="button"
                  id={OfferStatus.Archived}
                  className={`subheader__links__item ${
                    currentOfferStatus === OfferStatus.Archived
                      ? 'subheader__links__item--active'
                      : ''
                  }`}
                >
                  <FormattedMessage id="filter.button.archived" />
                </li>
              </ul>
              <div className="subheader__cta">
                <NavLink className="btn btn--primary btn--sml" to="/offers/add">
                  <img
                    src={require('assets/icons/icon-proposal-16-negative.svg')}
                    alt=""
                    className="btn__icon btn__icon--left"
                  />
                  <FormattedMessage id="button.addnew.offer" />
                </NavLink>
              </div>
            </div>
          </div>
        </header>
        <div className="wrapper wrapper--narrow">
          <section className="offers__filters">
            {currentOfferStatus === OfferStatus.Published ? (
              <StatusFilters
                clientStatusFilter={clientStatusFilter}
                clearStatusFiltersCallback={this.clearStatusFilters}
                setClientStatusFilterCallback={this.setClientStatusFilter}
              />
            ) : null}

            <OrderFilters
              currentOrder={currentOrder}
              handleChangeOrderCallback={this.handleChangeOrder}
            />

            <YearFilters
              selectYearCallback={this.selectYear}
              showAllYearsCallback={this.showAllYears}
              filterByYear={filterByYear}
            />
          </section>
          <section>
            {offersAreChanging ? (
              <Loading />
            ) : filteredAndOrderedContent &&
              filteredAndOrderedContent.length ? (
              filteredAndOrderedContent.map((item: Offer) => (
                <OfferListItem
                  key={item.id}
                  id={item.id}
                  title={item.name}
                  creationDateTime={item.creationDateTime}
                  displayDateTime={item.displayDateTime}
                  amount={item.total && item.total.priceWithoutVat}
                  currency={item.currency}
                  status={item.status}
                  clientStatus={item.clientStatus}
                  clientName={item.clientInfo ? item.clientInfo.name : ''}
                  year={2018}
                  handleDeleteOfferCallback={this.openConfirmationModal}
                  handleDuplicateOfferCallback={this.handleDuplicateOffer}
                  handlePublishOfferCallback={this.openPublishConfirmationModal}
                  handleArchiveOfferCallback={this.handleArchiveOffer}
                  changeClientStatusCallback={this.changeClientStatus}
                />
              ))
            ) : (
              <div className="message message--lrg message--info">
                <div className="message__title">
                  <h2 className="t-epsilon">
                    <FormattedMessage id="empty.offer.type" />
                  </h2>
                </div>
                <div className="message__content">
                  <p className="t-eta o-60">
                    <FormattedMessage id="empty.offer.message" />
                  </p>
                </div>
                <div className="message__action">
                  <NavLink
                    className="btn btn--negative btn--sml"
                    to="/offers/add"
                  >
                    <FormattedMessage id="button.addnew.offer" />
                  </NavLink>
                </div>
              </div>
            )}
            <ConfirmationModal
              title="Delete for real?"
              itemNameEnglish={this.state.offerName}
              description="There is no going back after this."
              isVisible={confirmationDialogIsOpen}
              confirmCallback={this.handleDeleteOffer}
              cancelCallback={this.closeConfirmationModal}
            />
            <ConfirmationModal
              title="Are you sure that you want to publish this proposal?"
              description="You can edit this proposal after you publish it."
              isVisible={publishConfirmationModalIsOpen}
              confirmCallback={this.handlePublishOffer}
              cancelCallback={this.closePublishConfirmationModal}
            />
          </section>
        </div>
      </section>
    );
  }
}

export default connect(
  (state: ApplicationState): ReduxProps => ({
    allOffers: state.offers.offers,
    offersAreChanging: state.offers.offersAreChanging,
    draftOffers: ItemSelectors.getSpecificOffers(state, OfferStatus.Draft),
    publishedOffers: ItemSelectors.getSpecificOffers(
      state,
      OfferStatus.Published
    ),
    archivedOffers: ItemSelectors.getSpecificOffers(
      state,
      OfferStatus.Archived
    ),
  }),
  (dispatch: Dispatch): DispatchProps => ({
    getAllOffers: () => dispatch(OffersThunkAction.getAllAsync()),
    removeOffer: (id: string) => dispatch(OffersThunkAction.removeOffer(id)),
    updateDraft: (offer: Partial<Offer>, id: string) =>
      dispatch(OffersThunkAction.updateDraft(offer, id)),
    saveNewVersion: (offer: Partial<Offer>, id: string) =>
      dispatch(OffersThunkAction.saveNewVersion(offer, id)),
  })
)(Offers);
