import { createAction, ActionUnion } from 'modules/redux-store';

import { Service } from '../models';

import { ServiceActionTypes } from './types';

export const ServiceActions = {
  servicesRequest: () => createAction(ServiceActionTypes.ServiceRequest),

  servicesSuccess: (services: Service[]) =>
    createAction(ServiceActionTypes.ServiceSuccess, {
      services,
    }),
  servicesError: (error?: string) =>
    createAction(ServiceActionTypes.ServiceError, { error }),
  servicesQuery: (query: string) =>
    createAction(ServiceActionTypes.ServiceQuery, { query }),
};

export type ServiceActions = ActionUnion<typeof ServiceActions>;
