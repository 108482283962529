import * as React from 'react';
import { Router } from 'react-router-dom';
import { Layout } from 'components';
import { createBrowserHistory } from 'history';
import { ApplicationState, Dispatch } from 'modules/redux-store';
import { connect } from 'react-redux';
import { AuthenticationThunkActions } from 'modules/authentication';
import {
  Language,
  LocalisationThunkActions,
  LocalisationSelectors
} from 'modules/localisation';
import { AccessControlList } from 'modules/authorization';
import { ClientsThunkActions } from 'modules/company-clients';
import { SettingsThunkActions } from 'modules/settings';

import { RoutingProps } from '../models';
import { NavigationSelectors } from '../redux';

interface DispatchProps {
  loginPersistedUser: () => void;
  setLanguage: (language: Language) => void;
  getAllClientInfo: () => Promise<void>;
  getAllSettings: () => Promise<void>;
}

type Props = RoutingProps & DispatchProps;

class Routing extends React.Component<Props> {
  browserHistory = createBrowserHistory();
  pathname = this.browserHistory.location.pathname;

  componentWillMount() {
    const { setLanguage, currentLanguage } = this.props;

    // Starts firebase authStateListener init action
    // loginPersistedUser();

    // Checks if there is a language code in the url, and sets language accordingly
    const pathnameLanguage = this.getLanguageFromPathname(this.pathname);
    setLanguage(pathnameLanguage || currentLanguage);
  }

  getLanguageFromPathname(pathname: string) {
    // Regex for getting language from pathname
    const pathnameLanguage = pathname.match(/\b[a-z]{2}\b/gi);
    if (pathnameLanguage) {
      return pathnameLanguage[0] as Language;
    }

    return;
  }

  componentWillUpdate(nextProps: Props) {
    const {
      isLoggedIn,
      loginRoute,
      getAllClientInfo,
      getAllSettings
    } = this.props;

    // After logging out, redirect user.
    if (isLoggedIn && !nextProps.isLoggedIn) {
      this.browserHistory.push(loginRoute.path!);
    }

    // After logging in, fetch everything that needs to be accessable globally.
    if (!isLoggedIn && nextProps.isLoggedIn) {
      getAllClientInfo();
      getAllSettings();
    }
  }

  render() {
    return (
      <Router history={this.browserHistory}>
        <Layout>
          <AccessControlList {...this.props} />
        </Layout>
      </Router>
    );
  }
}

export default connect(
  (state: ApplicationState): RoutingProps => ({
    currentLanguage: state.localisation.currentLanguage,
    isLoggedIn: state.auth.isLoggedIn,
    authStateChanging: state.auth.authStateChanging,
    routes: LocalisationSelectors.getLocalisedRoutes(state),
    dashboardRoute: NavigationSelectors.getBaseAuthenticatedRoute(state),
    loginRoute: NavigationSelectors.getBasePublicRoute(state),
    errorRoute: NavigationSelectors.getGeneralErrorRoute(state),
    permissionErrrorRoute: NavigationSelectors.getPermissionErrorRoute(state)
  }),
  (dispatch: Dispatch): DispatchProps => ({
    loginPersistedUser: () =>
      dispatch(AuthenticationThunkActions.loginWithPersistedUser()),

    setLanguage: (language: Language) =>
      dispatch(LocalisationThunkActions.setLanguageAsync(language)),
    getAllClientInfo: () => dispatch(ClientsThunkActions.getAllAsync()),
    getAllSettings: () => dispatch(SettingsThunkActions.getAllAsync())
  })
)(Routing);
