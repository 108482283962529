import React, { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { RichTextEditor } from 'components';
import { useFormContext } from 'react-hook-form';

interface Props {
  id: string;
  index: number;
  body?: string;
  type?: string;
  handleRemoveServiceCallback: (id: string) => void;
}

export const OfferBuilderField: React.FC<Props> = ({
  id,
  index,
  body,
  type,
  handleRemoveServiceCallback
}) => {
  const { register, setValue } = useFormContext();

  useEffect(() => {
    register(`services[${index}].id`);
    setValue(`services[${index}].id`, id);
    register(`services[${index}].index`);
    setValue(`services[${index}].index`, index);
    register(`services[${index}].type`);
    setValue(`services[${index}].type`, type);
  }, [register, index, setValue, id, type]);

  function handleDeleteField() {
    handleRemoveServiceCallback(id);
  }

  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <section
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="proposal-service"
        >
          <div
            {...provided.dragHandleProps}
            className="proposal-service__dragger"
          >
            <img
              alt="drag item"
              src={require('assets/icons/icon-drag-24.svg')}
            />
          </div>
          <div className="proposal-service__body">
            <RichTextEditor
              wrapperClassName="field"
              label="Description label"
              body={body}
              name={`services[${index}].body`}
            />

            <footer className="proposal-service__footer">
              <button
                type="button"
                onClick={handleDeleteField}
                className="btn btn--ghost btn--sml btn--sml--iconOnly"
              >
                <img
                  src={require('assets/icons/icon-trash-16.svg')}
                  alt="delete item"
                  className="btn__icon"
                />
              </button>
            </footer>
          </div>
        </section>
      )}
    </Draggable>
  );
};
