import * as views from 'views';
import { ACLType } from 'modules/authorization';

import { AppRoute } from '../models';

const authenticatedRoutes: AppRoute[] = [
  {
    key: 'dashboard',
    accessbilityLevel: ACLType.Authenticated,
    component: views.Offers,
    exact: true,
    showInMenu: false,
    localisation: {
      en: { title: 'Dashboard', path: '/' },
      hr: { title: 'Pregled', path: '/hr/' }
    }
  },
  {
    key: 'offers',
    accessbilityLevel: ACLType.Authenticated,
    component: views.Offers,
    exact: true,
    showInMenu: false,
    localisation: {
      en: { title: 'Offers', path: '/offers' },
      hr: { title: 'Ponude', path: '/hr/ponude' }
    }
  },
  {
    key: 'new-offer',
    accessbilityLevel: ACLType.Authenticated,
    component: views.NewOffer,
    exact: true,
    localisation: {
      en: { title: 'Create new proposal', path: '/offers/add' },
      hr: {
        title: 'Kreiraj novu ponudu',
        path: '/hr/ponude/nova-ponuda'
      }
    }
  },
  {
    key: 'new-offer',
    accessbilityLevel: ACLType.Authenticated,
    component: views.NewOffer,
    localisation: {
      en: { title: 'Create new proposal', path: '/offers/add/:id' },
      hr: {
        title: 'Kreiraj novu ponudu',
        path: '/hr/ponude/nova-ponuda/:id'
      }
    }
  },
  {
    key: 'settings',
    accessbilityLevel: ACLType.Authenticated,
    component: views.GeneralSettings,
    exact: true,
    showInMenu: true,
    localisation: {
      en: { title: 'Settings', path: '/settings' },
      hr: { title: 'Postavke', path: '/hr/postavke' }
    }
  },
  {
    key: 'services',
    accessbilityLevel: ACLType.Authenticated,
    component: views.Services,
    exact: true,
    showInMenu: false,
    localisation: {
      en: { title: 'Services', path: '/services' },
      hr: { title: 'Usluge', path: '/hr/usluge' }
    }
  },
  {
    key: 'units',
    accessbilityLevel: ACLType.Authenticated,
    component: views.Units,
    exact: true,
    showInMenu: false,
    localisation: {
      en: { title: 'Units', path: '/units' },
      hr: { title: 'Jedinice', path: '/hr/jedinice' }
    }
  },
  {
    key: 'clients',
    accessbilityLevel: ACLType.Authenticated,
    component: views.Clients,
    exact: true,
    showInMenu: false,
    localisation: {
      en: { title: 'Clients', path: '/clients' },
      hr: { title: 'Klijenti', path: '/hr/klijenti' }
    }
  }
];

export default authenticatedRoutes;
