import * as React from 'react';
import { ValidationMethod, validation } from 'modules/validation';

interface Props {
  itemRight?: JSX.Element;
  propName: string;
  value: string;
  compareValue?: string;
  label?: string;
  minValue?: number;
  maxValue?: number;
  minLength?: number;
  maxLength?: number;
  optional?: boolean;
  isDisabled?: boolean;
  type?: 'password' | 'numeric' | 'email' | 'text' | 'textarea';
  changeCallback: (prop: any, value: string, isValid?: boolean) => void;
  validationType?: ValidationMethod;
  emptyErrorText?: string;
  clientNameDuplicate?: boolean;
  validId?: string;
  invalidErrorText?: string;
  isValidOverride?: boolean;
  postValidationCallback?: (value: string, isValid: boolean) => void;
  className?: string;
  hideErrors?: boolean;
}

interface State {
  isValid: boolean;
  isEmpty: boolean;
  isDuplicated: boolean;
  focused: boolean;
  showErrors: boolean;
  isIdDuplicate: boolean;
}

export class ValidatedInput extends React.PureComponent<Props, State> {
  state = {
    isValid: true,
    isDuplicated: false,
    isEmpty: false,
    focused: false,
    showErrors: false,
    isIdDuplicate: false,
  };

  componentWillReceiveProps(nextProps: Props) {
    const { isValidOverride, propName, value, changeCallback } = this.props;

    /** If is valid override exists and it's value changes, trigger manual validation */
    if (isValidOverride !== nextProps.isValidOverride) {
      this.setState(
        {
          isValid: nextProps.isValidOverride!,
          showErrors: nextProps.isValidOverride === false,
        },
        () => changeCallback(propName, value, nextProps.isValidOverride!)
      );
    }
  }

  focus = () => {
    this.setState({
      focused: true,
      showErrors: false,
    });
  };

  blur = () => {
    const { value, validationType } = this.props;

    const parsedValue =
      validationType === ValidationMethod.Numeric && value && value.length > 0
        ? Number(value.replace(',', '.')).toFixed(2)
        : value;

    this.validateByType(
      parsedValue.length > 0 ? parsedValue.trim() : parsedValue
    );

    this.setState({
      focused: false,
    });
  };

  getEmptyErrorText = () => {
    const { label, emptyErrorText } = this.props;

    return emptyErrorText
      ? emptyErrorText
      : label
        ? `${label} is required.`
        : `This field is required.`;
  };

  getInvalidErrorText = () => {
    const { label, invalidErrorText } = this.props;

    return invalidErrorText
      ? invalidErrorText
      : label
        ? `${label} is invalid. Please try again.`
        : 'Value is invalid';
  };

  getDuplicateIdError = () => {
    const { clientNameDuplicate, validId } = this.props;

    return clientNameDuplicate === true ? 'Id already exists' : validId;
  };
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { validationType } = this.props;
    const value: string = event.currentTarget.value;

    const parsedValue =
      validationType === ValidationMethod.Numeric && value && value.length > 0
        ? Number(value.replace(',', '.')).toFixed(2)
        : value;
    this.validateByType(parsedValue.length > 0 ? parsedValue : parsedValue);
    // changeCallback(propName, value);
  };

  validateByType = (currentValue: string): void => {
    const {
      maxLength,
      validationType,
      optional,
      compareValue,
      changeCallback,
      propName,
      minValue,
      maxValue,
      isValidOverride,
      postValidationCallback,
    } = this.props;

    const defaultMinLength = optional ? 0 : 1;
    let { minLength } = this.props;
    minLength = minLength || defaultMinLength;

    const trimmedCurrentValue = currentValue.trim();
    const isEmpty = !trimmedCurrentValue;

    let isValid;
    switch (validationType) {
      case ValidationMethod.Email:
        isValid = this.validateEmail(trimmedCurrentValue);
        break;
      case ValidationMethod.Password:
        isValid = this.validatePassword(trimmedCurrentValue);
        break;
      case ValidationMethod.Comparable:
        isValid = this.validateComparable(trimmedCurrentValue, compareValue!);
        break;
      case ValidationMethod.Numeric:
        isValid =
          optional && isEmpty
            ? true
            : this.validateNumber(trimmedCurrentValue, minValue, maxValue);
        break;
      default:
        isValid =
          optional && isEmpty
            ? true
            : this.validateDefault(trimmedCurrentValue, minLength, maxLength);
        break;
    }

    if (!optional && isValid) {
      isValid = !isEmpty;
    }

    if (isValidOverride !== undefined && isValid) {
      isValid = isValidOverride;
    }

    this.setState({
      isEmpty: optional ? false : isEmpty,
      isValid,
      showErrors: true,
    });

    changeCallback(propName, currentValue, isValid);

    if (postValidationCallback) {
      postValidationCallback(currentValue, isValid);
    }
  };

  validateEmail = (value: string): boolean =>
    validation.EMAIL_REGEX.test(value);

  validatePassword = (value: string): boolean => {
    return (
      value.length >= validation.PASSWORD_LENGTH_MIN &&
      value.length <= validation.PASSWORD_LENGTH_MAX
    );
  };

  validateComparable = (value: string, compareValue: string): boolean =>
    value === compareValue;

  validateDefault = (value: string, minLength: number, maxLength?: number) => {
    return maxLength
      ? value.length >= minLength && value.length <= maxLength
      : value.length >= minLength;
  };

  validateNumber = (value: string, minValue?: number, maxValue?: number) => {
    const { optional } = this.props;

    if (!optional && (!value || value === 'NaN')) {
      return false;
    }

    const minimum = minValue ? minValue : 0.0;
    const maximum = maxValue ? maxValue : undefined;
    const parsedValue = Number(value.replace(',', '.'));

    return maximum
      ? parsedValue >= minimum && parsedValue <= maximum
      : parsedValue >= minimum;
  };

  render() {
    const { isValid, isEmpty, isIdDuplicate, focused, showErrors } = this.state;
    const {
      label,
      value,
      itemRight,
      type,
      className,
      propName,
      hideErrors,
      isDisabled,
    } = this.props;

    return (
      <div className="field">
        {label !== undefined && (
          <label
            htmlFor={propName}
            className={`${focused ? 't-text' : 't-tertiary'} field__lbl `}
          >
            {label}
          </label>
        )}

        <input
          className={`input input--med ${
            type === 'textarea' ? 'input--textarea' : 'input--text'
            } ${className}`}
          value={value}
          onFocus={this.focus}
          onBlur={this.blur}
          onChange={this.onChange}
          onSubmit={this.blur}
          disabled={isDisabled}
          id={propName}
          name={propName}
          type={type || 'text'}
        />
        {itemRight && <div>{itemRight}</div>}

        {showErrors && !hideErrors && (
          <React.Fragment>
            {isEmpty && (
              <p className="input--error">{this.getEmptyErrorText()}</p>
            )}
            {!isValid && !isEmpty && (
              <p className="input--error">{this.getInvalidErrorText()}</p>
            )}
            {!isIdDuplicate && (
              <p className="input-error">{this.getDuplicateIdError()}</p>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
