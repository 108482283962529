import { Dispatch } from 'modules/redux-store';
import { Language } from 'modules/localisation';
import { Globals } from 'consts';

import { applicationStrings } from '../intl';

import { LocalisationActions } from './actions';

const setLanguageAsync = (language: Language) => async (dispatch: Dispatch) => {
  let currentLanguage = Globals.DEFAULT_LANGUAGE;
  let currentApplicationStrings = Globals.DEFAULT_STRINGS;

  if (applicationStrings[language]) {
    currentLanguage = language;
    currentApplicationStrings = applicationStrings[language];
  }

  dispatch(
    LocalisationActions.setLanguage(currentLanguage, currentApplicationStrings)
  );
};

export const LocalisationThunkActions = {
  setLanguageAsync
};
