import { Offer } from 'modules/offers';

import { GetSingleOfferActions } from './actions';
import { PublicOfferTypes } from './types';

export interface PublicOfferState {
  readonly publicOffer?: Partial<Offer>;
  readonly publicOfferIsUpdating: boolean;
  readonly error?: string;
}

const INITIAL_STATE: PublicOfferState = {
  publicOffer: undefined,
  publicOfferIsUpdating: false,
  error: undefined
};

export const publicOfferReducer = (
  state: PublicOfferState = INITIAL_STATE,
  action: GetSingleOfferActions
): PublicOfferState => {
  switch (action.type) {
    case PublicOfferTypes.GetSingleOfferRequest:
      return {
        ...state,
        publicOfferIsUpdating: true,
        error: undefined
      };
    case PublicOfferTypes.GetSingleOfferSuccess:
      return {
        ...state,
        publicOfferIsUpdating: false,
        publicOffer: action.payload.publicOffer
      };
    case PublicOfferTypes.GetSingleOfferError:
      return {
        ...state,
        publicOfferIsUpdating: false,
        error: action.payload.error
      };
    default:
      return state || INITIAL_STATE;
  }
};
