import { createAction, ActionUnion } from 'modules/redux-store';

import { Offer } from '../models';

import { OfferActionTypes } from './types';

const getOfferActions = {
  offersRequest: () => createAction(OfferActionTypes.OfferRequest),

  offersSuccess: (offers: Offer[]) =>
    createAction(OfferActionTypes.OfferSuccess, {
      offers
    }),

  offersError: (error?: string) =>
    createAction(OfferActionTypes.OfferError, { error })
};

const newVersionActions = {
  newVersionRequest: () => createAction(OfferActionTypes.SaveNewVersionRequest),

  newVersionSuccess: () => createAction(OfferActionTypes.SaveNewVersionSuccess),

  newVersionError: (error?: string) =>
    createAction(OfferActionTypes.OfferError, { error })
};

const editOfferActions = {
  editOffer: (offerToEdit?: Partial<Offer>) =>
    createAction(OfferActionTypes.EditOffer, { offerToEdit })
};

const getVersionHistoryActions = {
  getVersionHistoryRequest: () =>
    createAction(OfferActionTypes.GetVersionHistoryRequest),
  getVersionHistorySuccess: (offerHistory: Offer[]) =>
    createAction(OfferActionTypes.GetVersionHistorySuccess, {
      offerHistory
    }),
  removeVersionHistory: () =>
    createAction(OfferActionTypes.RemoveVersionHistory)
};

export const OfferActions = {
  ...getOfferActions,
  ...newVersionActions,
  ...editOfferActions,
  ...getVersionHistoryActions
};

export type OfferActions = ActionUnion<typeof OfferActions>;
