import { Offer } from '../models';

import { OfferActions } from './actions';
import { OfferActionTypes } from './types';

export interface OfferState {
  readonly offersAreChanging: boolean;
  readonly offerIsBeingAdded: boolean;
  readonly offerIsBeingSaved: boolean;
  readonly offerHistoryIsChanging: boolean;
  readonly offers?: Offer[];
  readonly offerHistory?: Offer[];
  readonly newOffer?: Offer;
  readonly error?: string;
  readonly offerToEdit?: Partial<Offer>;
  readonly newOfferId?: string;
}

const INITIAL_STATE: OfferState = {
  offersAreChanging: false,
  offerIsBeingAdded: false,
  offerIsBeingSaved: false,
  offerHistoryIsChanging: false,
  offers: undefined,
  offerHistory: undefined,
  newOffer: undefined,
  error: undefined,
  offerToEdit: undefined,
  newOfferId: undefined
};

export const offerReducer = (
  state: OfferState = INITIAL_STATE,
  action: OfferActions
): OfferState => {
  switch (action.type) {
    case OfferActionTypes.OfferRequest:
      return {
        ...state,
        offersAreChanging: true,
        error: undefined
      };
    case OfferActionTypes.OfferSuccess:
      return {
        ...state,
        offersAreChanging: false,
        offers: action.payload.offers
      };
    case OfferActionTypes.OfferError:
      return {
        ...state,
        offersAreChanging: false,
        error: action.payload.error
      };
    case OfferActionTypes.SaveNewVersionRequest:
      return {
        ...state,
        offerIsBeingSaved: true,
        error: undefined
      };
    case OfferActionTypes.SaveNewVersionSuccess:
      return {
        ...state,
        offerIsBeingSaved: false
      };
    case OfferActionTypes.EditOffer:
      return {
        ...state,
        offerToEdit: action.payload.offerToEdit
      };
    case OfferActionTypes.GetVersionHistoryRequest:
      return {
        ...state,
        offerHistoryIsChanging: true,
        error: undefined
      };
    case OfferActionTypes.GetVersionHistorySuccess:
      return {
        ...state,
        offerHistoryIsChanging: false,
        offerHistory: action.payload.offerHistory
      };
    case OfferActionTypes.RemoveVersionHistory:
      return {
        ...state,
        offerHistoryIsChanging: false,
        offerHistory: undefined
      };
    default:
      return state || INITIAL_STATE;
  }
};
