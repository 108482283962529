import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Language } from 'modules/localisation';
import { Currency } from 'models';

import {
  Offer,
  OfferStatus,
  FormattedCurrency,
  FormattedLanguage,
  FormattedVersionHistory,
} from '../models';

import { OfferDatePicker } from '.';
import { FormSelect } from 'components';
import { useFormContext } from 'react-hook-form';
import { darkSelect } from 'consts';
import Select from 'react-select';
import addWeeks from 'date-fns/addWeeks';
import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

interface Props {
  currentOffer: Partial<Offer>;
  versionHistory?: FormattedVersionHistory[];
  handleSelectHistoryVersionCallback: (offerVersion: Offer) => void;
}

interface State {
  validUntiUserChange: boolean;
}

interface FormState {
  name: string;
  displayDateTime: number;
  validUntilDateTime: number;
}

/**
 * General information has been split to 3 parts, if you are looking for the rest, it can be found in:
 * - OfferCoverImage
 * - OfferClientInformation
 */
export const GeneralInformation: React.FC<Props> = ({
  currentOffer,
  versionHistory,
  handleSelectHistoryVersionCallback,
}) => {
  const formattedCurrencies: FormattedCurrency[] = useMemo(
    () =>
      Object.keys(Currency).map((key) => ({
        value: Currency[key],
        label: Currency[key],
      })),
    []
  );

  const formattedLanguages: FormattedLanguage[] = useMemo(
    () =>
      Object.keys(Language).map((key) => ({
        value: Language[key],
        label: key,
      })),
    []
  );

  const getValidUntil = useCallback(() => {
    const {
      validUntilDateTime,
      displayDateTime,
      creationDateTime,
    } = currentOffer;

    if (validUntilDateTime) {
      return validUntilDateTime;
    }

    if (displayDateTime) {
      return addWeeks(displayDateTime, 2).getTime();
    }

    return addWeeks(creationDateTime!, 2).getTime();
  }, [currentOffer]);

  const [state, setState] = useState<State>({
    validUntiUserChange: false,
  });
  const { register, setValue, getValues } = useFormContext<FormState>();

  useEffect(() => {
    register('displayDateTime');
    register('validUntilDateTime');
    setValue(
      'displayDateTime',
      currentOffer.displayDateTime || currentOffer.creationDateTime
    );
    setValue('validUntilDateTime', getValidUntil());
  }, [register, setValue, currentOffer, getValidUntil]);

  const handleDateChange = (selectedDate: Date[]) => {
    const { validUntiUserChange } = state;
    const { validUntilDateTime } = getValues();
    const displayDateTime = selectedDate[0].getTime();

    setValue('displayDateTime', displayDateTime);

    /**
     * If validUntilDateTime isn't changed by user and
     * validUntilDateTime is smaller than displayDateTime update
     * validUntiUserChange by two weeks from displayDateTime
     */

    if (
      !validUntiUserChange &&
      differenceInCalendarDays(validUntilDateTime, displayDateTime) > 0
    ) {
      return;
    }

    const updatedValidUntil = addWeeks(displayDateTime, 2).getTime();

    setValue('validUntilDateTime', updatedValidUntil);
  };

  const handlevalidUntilDateChange = (selectedDate: Date[]) => {
    setState((state) => ({
      ...state,
      validUntiUserChange: true,
    }));
    setValue('validUntilDateTime', selectedDate[0].getTime());
  };

  const revertTimeToOriginal = () => {
    setState((state) => ({ ...state, displayDateTime: 0 }));
  };

  const handleSelectHistoryVersion = (selectedHistoryVersion: any) => {
    handleSelectHistoryVersionCallback(selectedHistoryVersion.value);
  };

  const { creationDateTime, status } = currentOffer;
  const { displayDateTime, validUntilDateTime } = getValues();

  return (
    <section className="general-info">
      <header className="subheader">
        <div className="subheader__wrapper wrapper">
          <p className="t-micro t-negative t-upperext o-60 s-bottom--sml">
            Proposal name
          </p>
          <input
            autoFocus
            ref={register}
            name="name"
            defaultValue={currentOffer.name}
            type="text"
            className="input input--supersized input--supersized--negative"
          />
        </div>
      </header>
      <section className="general-info-settings">
        <div className="general-info-settings__wrapper wrapper">
          <div className="general-info__language">
            <p className="t-micro t-negative t-upperext o-60 s-bottom--med">
              Language
            </p>
            <FormSelect
              name="language"
              options={formattedLanguages}
              styles={darkSelect}
              defaultValue={{
                value: currentOffer.language || '',
                label: Object.keys(Language).find(
                  (lang) => Language[lang] === currentOffer.language
                )!,
              }}
            />
          </div>
          <div className="general-info__currency">
            <p className="t-micro t-negative t-upperext o-60 s-bottom--med">
              Currency
            </p>
            <FormSelect
              name="currency"
              options={formattedCurrencies}
              styles={darkSelect}
              defaultValue={{
                value: currentOffer.currency || '',
                label: currentOffer.currency || '',
              }}
            />
          </div>

          <OfferDatePicker
            label="Date &amp; time"
            displayDateTime={displayDateTime}
            creationDateTime={creationDateTime}
            handleDateChangeCallback={handleDateChange}
            revertTimeCallback={revertTimeToOriginal}
          />

          <OfferDatePicker
            label="Valid until"
            creationDateTime={validUntilDateTime}
            handleDateChangeCallback={handlevalidUntilDateChange}
            options={{
              minDate: addDays(displayDateTime || creationDateTime!, 1),
            }}
          />

          {status && status !== OfferStatus.Draft && (
            <div className="general-info__history">
              <p className="t-micro t-negative t-upperext o-60 s-bottom--med">
                Version history
              </p>

              <Select
                name="versionHistory"
                onChange={handleSelectHistoryVersion}
                styles={darkSelect}
                options={versionHistory}
                defaultValue={
                  {
                    value: currentOffer,
                    label: 'Latest',
                  } || []
                }
                placeholder="Latest"
              />
            </div>
          )}
        </div>
      </section>
    </section>
  );
};
