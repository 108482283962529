import React, { useEffect } from 'react';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import { SlateEditor, InitialValue } from './SlateEditor';
import { Value } from 'slate';
import { serialize } from 'components';

interface Props {
  name: string;
  body?: string;
  label?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  defaultValue?: string;
  editorClassName?: string;
  errMsg?: string;
  placeholder?: string;
  validationObject?: {
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    max?: number;
    min?: number;
    pattern?: RegExp;
    validate?: any;
  };
}

export const RichTextEditor: React.FC<Props> = ({
  wrapperClassName,
  name,
  body,
  label,
  placeholder,
  errMsg,
  labelClassName,
  editorClassName,
}) => {
  const { register, setValue, errors } = useFormContext();
  const slateBody = body
    ? Value.fromJSON(JSON.parse(serialize(body)))
    : InitialValue;

  useEffect(() => {
    register(name);
    setValue(name, body || JSON.stringify(InitialValue.toJSON()));
  }, [register, name, setValue, body]);

  return (
    <div className={wrapperClassName}>
      <label htmlFor={name} className={labelClassName || 'field__lbl'}>
        {label}
      </label>

      <SlateEditor
        content={slateBody}
        className={editorClassName}
        updateFieldCallback={updateSlateEditorField}
        placeholder={placeholder}
      />

      <ErrorMessage
        errors={errors || {}}
        name={name}
        message={errMsg || 'This field is required'}
        as={<p className="alert alert--warning" />}
      />
    </div>
  );

  function updateSlateEditorField(value: string) {
    setValue(name, value);
  }
};
