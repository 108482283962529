import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  filterByYear?: number;
  showAllYearsCallback: VoidFunction;
  selectYearCallback: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export class YearFilters extends React.Component<Props> {
  render() {
    const {
      showAllYearsCallback,
      selectYearCallback,
      filterByYear
    } = this.props;

    return (
      <div className="offers__filters__year">
        <p className="offers__filters__title t-micro t-upperext o-40">
          <FormattedMessage id="filter.label.year" />
        </p>
        <button
          data-year="2020"
          onClick={selectYearCallback}
          className={`btn btn--core btn--filter ${
            filterByYear === 2020 ? 'btn--filter--active' : ''
          }`}
        >
          2020
        </button>
        <button
          data-year="2019"
          onClick={selectYearCallback}
          className={`btn btn--core btn--filter ${
            filterByYear === 2019 ? 'btn--filter--active' : ''
          }`}
        >
          2019
        </button>
        <button
          data-year="2018"
          onClick={selectYearCallback}
          className={`btn btn--core btn--filter ${
            filterByYear === 2018 ? 'btn--filter--active' : ''
          }`}
        >
          2018
        </button>
        <button
          onClick={showAllYearsCallback}
          className={`btn btn--core btn--filter ${
            !filterByYear ? 'btn--filter--active' : ''
          }`}
        >
          <FormattedMessage id="filter.button.showall" />
        </button>
      </div>
    );
  }
}
