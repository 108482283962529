import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { OfferOrdering } from '..';

interface Props {
  currentOrder: OfferOrdering;
  handleChangeOrderCallback: (
    event: React.SyntheticEvent<HTMLButtonElement>
  ) => void;
}

export class OrderFilters extends React.Component<Props> {
  render() {
    const { handleChangeOrderCallback, currentOrder } = this.props;

    return (
      <div className="offers__filters__order">
        <p className="offers__filters__title t-micro t-upperext o-40">
          <FormattedMessage id="filter.label.orderby" />
        </p>
        <button
          onClick={handleChangeOrderCallback}
          data-order-type={OfferOrdering.Date}
          className={`btn btn--core btn--filter ${
            currentOrder === OfferOrdering.Date ? 'btn--filter--active' : ''
          }`}
        >
          <FormattedMessage id="filter.button.date" />
        </button>
        <button
          onClick={handleChangeOrderCallback}
          data-order-type={OfferOrdering.Name}
          className={`btn btn--core btn--filter ${
            currentOrder === OfferOrdering.Name ? 'btn--filter--active' : ''
          }`}
        >
          <FormattedMessage id="filter.button.name" />
        </button>
      </div>
    );
  }
}
