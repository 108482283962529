import * as React from 'react';
import Flatpickr from 'react-flatpickr';
import flatpickr from 'flatpickr';

interface Props {
  label: string;
  displayDateTime?: number;
  creationDateTime?: number;
  revertTimeCallback?: VoidFunction;
  handleDateChangeCallback: (selectedDate: Date[]) => void;
  options?: flatpickr.Options.Options;
}

export const OfferDatePicker: React.SFC<Props> = ({
  label,
  displayDateTime,
  creationDateTime,
  handleDateChangeCallback,
  revertTimeCallback,
  options,
}) => (
  <div className="general-info__date">
    <p className="t-micro t-negative t-upperext o-60 s-bottom--med">{label}</p>
    <div className="d--flex">
      {creationDateTime && (
        <Flatpickr
          className="input input--med input--select input--dark"
          data-enable-time
          value={displayDateTime || creationDateTime}
          onChange={handleDateChangeCallback}
          options={options}
        />
      )}
      {displayDateTime ? (
        <button
          onClick={revertTimeCallback}
          className="btn btn--ghost btn--sml btn--sml--iconOnly"
        >
          <img
            src={require('assets/icons/icon-loop-16-negative.svg')}
            alt=""
            className="btn__icon"
          />
        </button>
      ) : (
        ''
      )}
    </div>
  </div>
);
