import { createAction, ActionUnion } from 'modules/redux-store';

import { Client } from '../models';

import { ClientsActionTypes } from './types';

export const ClientsActions = {
  clientsRequest: () => createAction(ClientsActionTypes.ClientsRequest),

  clientsSuccess: (clients: Client[]) =>
    createAction(ClientsActionTypes.ClientsSuccess, {
      clients,
    }),

  clientsError: (error?: string) =>
    createAction(ClientsActionTypes.ClientsError, { error }),

  clientsQuery: (query: string) =>
    createAction(ClientsActionTypes.ClientsQuery, { query }),
};

export type ClientsActions = ActionUnion<typeof ClientsActions>;
