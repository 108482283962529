import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ApplicationState } from 'modules/redux-store';
import { NavigationSelectors, AppRoute } from 'modules/navigation';
import { Link } from 'react-router-dom';

interface ReduxProps {
  dashboardRoute: AppRoute;
}

class GeneralError extends React.Component<ReduxProps> {
  render() {
    const { dashboardRoute } = this.props;

    return (
      <section className="errorpage">
        <div className="errorpage__wrapper wrapper">
          <header className="errorpage__header">
            <h1>
              <FormattedMessage id="error.label.default" />
            </h1>
          </header>
          <div className="errorpage__content">
            <p>
              <FormattedMessage id="error.message.pagenotfound" />
            </p>
          </div>
          <div className="errorpage__action">
            <Link
              to={dashboardRoute.path!}
              className="btn btn--primary btn--sml"
            >
              {dashboardRoute.title}
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(
  (state: ApplicationState): ReduxProps => ({
    dashboardRoute: NavigationSelectors.getBaseAuthenticatedRoute(state)
  })
)(GeneralError);
