import { Styles } from 'react-select/lib/styles';

/**
 * Custom style object for react-select components
 * more details about what it contains and how to use it can be found here:
 * https://react-select.com/styles#using-classnames
 */
export const darkSelect: Partial<Styles> = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'hsl(260, 6%, 10%)',
    borderColor: state.isFocused ? 'hsl(0, 0%, 50%)' : 'hsl(0, 0%, 28%)',
    boxShadow: state.isFocused ? '0 0 5px black' : 'none',
    minHeight: '40px',
    minWidth: '140px'
    // overide styles for container here ex.
  }),
  singleValue: provided => ({
    ...provided,
    color: 'hsl(0, 0%, 70%)'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'hsl(0, 0%, 30%)'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'hsl(0, 0%, 40%)'
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: 'hsl(0, 0%, 18%)'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'hsl(0, 0%, 100%)' : 'hsl(0, 0%, 70%)',
    backgroundColor: state.isSelected ? 'hsl(0, 0%, 30%)' : 'none'
  })
};
