import * as React from 'react';
import { connect } from 'react-redux';
import { AuthenticationThunkActions } from 'modules/authentication';
import { ApplicationState, Dispatch } from 'modules/redux-store';
import { Loading } from 'components';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';

interface DispatchProps {
  loginUser: (
    username: string,
    password: string,
    rememberMe: boolean
  ) => Promise<void>;
}

interface ReduxProps {
  authStateChanging: boolean;
  error?: string;
  user?: firebase.User;
}

type Props = DispatchProps & ReduxProps & RouteComponentProps<any>;

interface State {
  email: string;
  password: string;
  rememberMe: boolean;
  [param: string]: string | boolean;
}

class Login extends React.Component<Props, State> {
  state: State = {
    email: '',
    password: '',
    rememberMe: false,
  };

  handleSubmit = () => {
    const { loginUser } = this.props;
    const { email, password, rememberMe } = this.state;

    loginUser(email, password, rememberMe);
  };

  handleInputChange: React.ReactEventHandler<HTMLInputElement> = (event) => {
    const { name, type, checked } = event.currentTarget;
    let value: string | boolean = event.currentTarget.value;

    if (type === 'checkbox') {
      value = checked;
    }

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { email, password, rememberMe } = this.state;
    const { authStateChanging, error } = this.props;

    return (
      <main className="container login">
        <div className="wrapper wrapper--narrow login__wrapper">
          <header className="login__header">
            <h1 className="t-zeta">
              <strong>
                <FormattedMessage id="login.welcome" />
              </strong>
            </h1>
          </header>
          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <label htmlFor="username" className="field__lbl visuallyhidden">
                <FormattedMessage id="input.email" />
              </label>
              <input
                id="username"
                name="email"
                value={email}
                className="input input--text"
                type="email"
                placeholder="Enter your e-mail address"
                onChange={this.handleInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="password" className="field__lbl visuallyhidden">
                <FormattedMessage id="input.password" />
              </label>
              <input
                id="password"
                name="password"
                value={password}
                className="input input--text"
                type="password"
                placeholder="Enter your password"
                onChange={this.handleInputChange}
              />
            </div>
            <div className="field">
              <input
                id="rememberMe"
                name="rememberMe"
                checked={rememberMe}
                type="checkbox"
                className="input--check"
                onChange={this.handleInputChange}
              />
              <label htmlFor="rememberMe" className="s-left--sml">
                <FormattedMessage id="input.rememberme" />
              </label>
              {error && <p className="alert alert--warning s-top--med">{error}</p>}
            </div>

            {authStateChanging ? (
              <Loading />
            ) : (
              <button
                type="submit"
                value="Submit"
                className="btn btn--med btn--primary login__btn"
                disabled={authStateChanging}
              >
                <FormattedMessage id="button.login" />
              </button>
            )}
          </form>
          <div className="field s-top--med">
            version: {process.env.REACT_APP_VERSION}
          </div>
        </div>
      </main>
    );
  }
}

export default connect(
  (state: ApplicationState): ReduxProps => ({
    authStateChanging: state.auth.authStateChanging,
    error: state.auth.error,
  }),
  (dispatch: Dispatch): DispatchProps => ({
    loginUser: (username: string, password: string, rememberMe: boolean) =>
      dispatch(
        AuthenticationThunkActions.loginAsync(username, password, rememberMe)
      ),
  })
)(Login);
