enum GetOffersTypes {
  OfferRequest = 'offers/REQUEST',
  OfferSuccess = 'offers/SUCCESS',
  OfferError = 'offers/ERROR'
}

enum EditOfferTypes {
  EditOffer = 'edit-offer/ADD-FIELDS'
}

enum SaveNewVersionTypes {
  SaveNewVersionRequest = 'new-version/REQUEST',
  SaveNewVersionSuccess = 'new-version/SUCCESS'
}

enum GetVersionHistoryTypes {
  GetVersionHistoryRequest = 'version-history/REQUEST',
  GetVersionHistorySuccess = 'version-history/SUCCESS',
  RemoveVersionHistory = 'version-history/REMOVE'
}

export const OfferActionTypes = {
  ...GetOffersTypes,
  ...EditOfferTypes,
  ...SaveNewVersionTypes,
  ...GetVersionHistoryTypes
};
