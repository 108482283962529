import { createSelector } from 'reselect';
import { Unit } from 'modules/company-units';
import { LocalisationSelectors } from 'modules/localisation';
import { Service } from 'modules/company-services';
import { ApplicationState } from 'modules/redux-store';
import { format } from 'date-fns';

import { Offer, OfferStatus } from '../models';

const getFilteredOffers = (state: ApplicationState, offerType: OfferStatus) =>
  state.offers &&
  state.offers.offers &&
  state.offers.offers.filter(item => item.status === offerType);

const getOffer = (offers: Offer[], offerId: string) => ({
  offers,
  offerId
});

const getVersionHistory = (state: ApplicationState) =>
  state.offers.offerHistory;

const getSingleOffer = createSelector(
  [getOffer],
  offersWithId =>
    offersWithId.offers.find(
      offer => offer.id === offersWithId.offerId
    ) as Partial<Offer>
);

const getFormattedUnits = createSelector(
  [
    LocalisationSelectors.getLocalisedUnits,
    LocalisationSelectors.getCurrentOfferLanguage
  ],
  (units, language) => {
    if (units) {
      const formattedUnits = units.map((item: Unit) => ({
        value: item.id,
        label: item.localisation[language].title
      }));
      return formattedUnits;
    }

    return;
  }
);

const getFormattedServices = createSelector(
  [
    LocalisationSelectors.getLocalisedServices,
    LocalisationSelectors.getCurrentOfferLanguage
  ],
  (service, language) => {
    if (service) {
      const formattedServices = service.map((item: Service) => ({
        value: item.id,
        label: item.localisation[language].title
      }));
      return formattedServices;
    }

    return;
  }
);

const getSpecificOffers = createSelector([getFilteredOffers], offers => {
  if (offers) {
    return offers;
  }

  return;
});

const getFormattedVersionHistory = createSelector(
  [getVersionHistory],
  versionHistory => {
    if (versionHistory) {
      const formattedVersionHistory = versionHistory.map((item: Offer) => {
        return {
          value: item,
          label: format(item.creationDateTime, 'MM.dd.yyyy HH:mm')
        };
      });

      return formattedVersionHistory;
    }
    return;
  }
);

export const ItemSelectors = {
  getFormattedUnits,
  getFormattedServices,
  getSingleOffer,
  getSpecificOffers,
  getFormattedVersionHistory
};
