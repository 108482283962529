import { Dispatch, ApplicationState } from 'modules/redux-store';
import { FirebaseDatabaseService } from 'modules/firebase/services';
import { FirestoreListenerActions } from 'modules/firestore-listeners';

import { Offer } from '../models';

import { OfferActions } from './actions';

const offers = new FirebaseDatabaseService<Offer>('offers');

export const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  /** If offers listener is already active, don't trigger again */
  const existingOffersListener = getState().listeners.offersListener;
  if (existingOffersListener) {
    return;
  }

  dispatch(OfferActions.offersRequest());

  const successFunction = (data: Offer[]) =>
    dispatch(OfferActions.offersSuccess(data));

  const errorFunction = (error: string) =>
    dispatch(OfferActions.offersError(error));

  const listenerProps = {
    successFunction,
    errorFunction
  };

  offers
    .filterAsync(undefined, listenerProps)
    .then(listener =>
      dispatch(
        FirestoreListenerActions.setOffersListener(listener as VoidFunction)
      )
    );

  return;
};

export const updateDraft = (offer: Partial<Offer>, id: string) => async (
  dispatch: Dispatch
) => {
  // Update draft is using same actions as New Version (which is when offer is published) since it should be used to generate the same loading animation. No need to create additional request/success/error path for drafts and new versions separately.
  dispatch(OfferActions.newVersionRequest());

  await offers.addDraft(offer, id);

  dispatch(OfferActions.newVersionSuccess());
};

export const saveNewVersion = (offer: Partial<Offer>, id: string) => async (
  dispatch: Dispatch
) => {
  dispatch(OfferActions.newVersionRequest());

  await offers.addNewVersion(offer, id);
  dispatch(OfferActions.editOffer(offer));

  dispatch(OfferActions.newVersionSuccess());

  return;
};

export const editOffer = (offerToEdit?: Partial<Offer>) => async (
  dispatch: Dispatch
) => {
  dispatch(OfferActions.editOffer(offerToEdit));

  return;
};

/**
 *
 * @note Not a thunk!
 */
export const removeOffer = (offerId: string) => async () => {
  offers.removeAsync(offerId);
  return;
};

export const getVersionHistory = (offerId: string) => async (
  dispatch: Dispatch
) => {
  dispatch(OfferActions.getVersionHistoryRequest());

  const offerHistory = await offers.getHistory(offerId);

  if (offerHistory && offerHistory.length) {
    dispatch(OfferActions.getVersionHistorySuccess(offerHistory));
  }
};

export const removeVersionHistory = () => async (dispatch: Dispatch) => {
  dispatch(OfferActions.removeVersionHistory());
};

export const OffersThunkAction = {
  getAllAsync,
  editOffer,
  updateDraft,
  saveNewVersion,
  removeOffer,
  getVersionHistory,
  removeVersionHistory
};
