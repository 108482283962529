import { AuthenticationActions } from './actions';
import { AuthenticationActionTypes } from './types';

export interface AuthenticationState {
  readonly authStateChanging: boolean;
  readonly isLoggedIn: boolean;
  readonly user?: firebase.User;
  readonly error?: string;
}

const INITIAL_STATE: AuthenticationState = {
  authStateChanging: false,
  isLoggedIn: false,
  user: undefined,
  error: undefined
};

export const authenticationReducer = (
  state: AuthenticationState = INITIAL_STATE,
  action: AuthenticationActions
): AuthenticationState => {
  switch (action.type) {
    case AuthenticationActionTypes.AuthStateChange:
      return {
        ...state,
        authStateChanging: true,
        error: undefined
      };
    case AuthenticationActionTypes.LoginSuccess:
      return {
        ...state,
        authStateChanging: false,
        isLoggedIn: true,
        user: action.payload.user
      };
    case AuthenticationActionTypes.LoginError:
      return {
        ...state,
        authStateChanging: false,
        isLoggedIn: false,
        error: action.payload.error
      };
    case AuthenticationActionTypes.Logout:
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
