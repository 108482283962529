enum LoginActionTypes {
  AuthStateChange = 'auth/StateChange',
  LoginSuccess = 'login/SUCCESS',
  LoginError = 'login/ERROR',
  Logout = 'logout/USER'
}

export const AuthenticationActionTypes = {
  ...LoginActionTypes
};
