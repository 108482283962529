import * as React from 'react';

interface Props {
  closeModalCallback: VoidFunction;
  saveCallback: VoidFunction;
  isVisible: boolean;
}

export class Modal extends React.PureComponent<
  Props &
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
> {
  private modalRef = React.createRef<HTMLElement>();

  componentDidMount() {
    window.addEventListener('keydown', this.handleEscapeOrEnter, false);
    window.addEventListener('mousedown', this.closeModalOnOutside, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEscapeOrEnter, false);
    window.removeEventListener('mousedown', this.closeModalOnOutside, false);
  }

  closeModalOnOutside = (event: MouseEvent) => {
    if (event.target === this.modalRef.current) {
      event.preventDefault();
      this.props.closeModalCallback();
    }
  };

  handleEscapeOrEnter = (event: KeyboardEvent) => {
    if (this.props.isVisible) {
      switch (event.keyCode) {
        case 27:
          event.preventDefault();
          this.props.closeModalCallback();
          break;
        case 13:
          event.preventDefault();
          this.props.saveCallback();
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { children } = this.props;
    const {
      saveCallback,
      closeModalCallback,
      isVisible,
      ...otherProps
    } = this.props;
    return (
      <section ref={this.modalRef} {...otherProps}>
        {children}
      </section>
    );
  }
}
