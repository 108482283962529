import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'modules/redux-store';
import { AuthenticationThunkActions } from 'modules/authentication';

export interface SettingsProps {
  logout: VoidFunction;
}

class SettingsMenu extends React.Component<SettingsProps> {
  render() {
    const { logout } = this.props;

    return (
      <aside className="settings__layout__aside">
        <div className="asidenav">
          <ul className="asidenav__list">
            <li className="asidenav__list__item">
              <NavLink to="/settings" className="asidenav__list__link">
                General settings
              </NavLink>
            </li>
            <li className="asidenav__list__item">
              <NavLink to="/clients" className="asidenav__list__link">
                Clients
              </NavLink>
            </li>
            <li className="asidenav__list__item">
              <NavLink to="/services" className="asidenav__list__link">
                Services
              </NavLink>
            </li>
            <li className="asidenav__list__item">
              <NavLink to="/units" className="asidenav__list__link">
                Units
              </NavLink>
            </li>
          </ul>
          <button onClick={logout} className="btn btn--dark btn--tny">
            <FormattedMessage id="button.logout" />
          </button>
        </div>
        <div className="field s-top--med">
          version: {process.env.REACT_APP_VERSION}
        </div>
      </aside>
    );
  }
}

export default connect(null, (dispatch: Dispatch) => ({
  logout: () => dispatch(AuthenticationThunkActions.logout()),
}))(SettingsMenu);
