import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Client } from '..';

interface Props {
  clientInfo: Client;
  clientName: string;
  editClientCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
  deleteClientCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ClientItem: React.SFC<Props> = ({
  clientInfo,
  clientName,
  deleteClientCallback,
  editClientCallback,
}) => (
  <div className="settings-itemlist__item settings-itemlist__item--clients">
    <span>{clientInfo.name}</span>
    <div>
      <button
        data-client-id={clientInfo.id}
        onClick={editClientCallback}
        className="btn btn--dark btn--tny"
      >
        <FormattedMessage id="button.edit" />
      </button>
      <button
        data-client-id={clientInfo.id}
        data-client-name={clientName}
        onClick={deleteClientCallback}
        className="btn btn--ghost btn--tny"
      >
        <img
          src={require('assets/icons/icon-trash-16.svg')}
          alt=""
          className="btn__icon"
        />
      </button>
    </div>
  </div>
);
