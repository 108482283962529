import * as React from 'react';

export const PageLoading: React.SFC = () => (
  <div className="container container--empty">
    <div className="loading loading--page">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
