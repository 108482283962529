import * as React from 'react';
import { MouseEvent, createRef } from 'react';
import { Controller, Value, ValueJSON } from 'slate';
import { Editor } from 'slate-react';
import { isCodeHotkey } from 'is-hotkey';
import Icon from 'react-icons-kit';
import { Mark } from 'components';

import { SlateMarksEnum, SlateMarks } from './Marks';

interface SlateEditorProps {
  content: Value;
  placeholder?: string;
  className?: string;
  edit?: boolean;
  readOnly?: boolean;
  updateFieldCallback?: (value: string) => void;
}

const schema = {
  document: {
    nodes: [
      {
        match: [{ type: 'paragraph' }]
      }
    ]
  },
  blocks: {
    paragraph: {
      nodes: [
        {
          match: { object: 'text' }
        }
      ]
    }
  }
};

export const InitialValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text'
          }
        ]
      }
    ]
  }
} as ValueJSON);

export class SlateEditor extends React.PureComponent<SlateEditorProps> {
  state = {
    value: InitialValue
  };

  private editor = createRef<Editor>();

  componentDidMount() {
    this.setState({ value: this.props.content });
  }

  onChange = (change: { value: Value }) => {
    if (change.value.document !== this.state.value.document) {
      this.props.updateFieldCallback!(JSON.stringify(change.value.toJSON()));
    }
    this.setState({ value: change.value });
  };

  onKeyDown = (e: Event, change: Controller, next: () => void) => {
    const event = e as KeyboardEvent;
    // check if the first key is modificator (ctrl or cmd)
    if (!isCodeHotkey(`mod+${event.key}`, event)) {
      return next();
    }

    const markType = SlateMarks.find(
      slateMark => slateMark.mark.shortcut === event.key
    );

    if (markType && markType.mark) {
      e.preventDefault();
      change.toggleMark(markType.mark.type!.toString());
      return true;
    }

    return next();
  };

  renderMark = (props: any) =>
    Object.keys(SlateMarksEnum).includes(props.mark.type) ? (
      <Mark {...props} />
    ) : (
      undefined
    );

  onMarkClick = (e: MouseEvent<HTMLButtonElement>) => {
    const { type } = e.currentTarget.dataset;
    const currentEditor = this.editor.current;
    if (type && currentEditor) {
      const change = currentEditor.toggleMark(type);
      this.onChange(change);
      currentEditor.focus();
    }
  };

  renderToolbarBtns = () =>
    SlateMarks.map((slateMark, index) => (
      <button
        key={index}
        type="button"
        onPointerDown={this.onMarkClick}
        data-type={slateMark.mark.type}
        className="slate__toolbar__icon"
      >
        <Icon icon={slateMark.mark.icon} />
      </button>
    ));

  render() {
    const { placeholder, className, readOnly } = this.props;
    const { value } = this.state;

    return (
      <section className="slate">
        {!readOnly && (
          <div className="slate__toolbar">{this.renderToolbarBtns()}</div>
        )}

        <Editor
          className={`${className} slate__editor`}
          ref={this.editor}
          value={value}
          schema={schema}
          onChange={this.onChange}
          placeholder={placeholder}
          onKeyDown={this.onKeyDown}
          renderMark={this.renderMark}
          spellCheck={false}
          readOnly={readOnly}
        />
      </section>
    );
  }
}
