import * as views from 'views';
import { ACLType } from 'modules/authorization';

import { AppRoute } from '../models';

const publicRoutes: AppRoute[] = [
  {
    key: 'login',
    accessbilityLevel: ACLType.AnonymousOnly,
    component: views.Login,
    exact: true,
    localisation: {
      en: { title: 'Login', path: '/login' },
      hr: { title: 'Prijava', path: '/hr/prijava' }
    }
  },
  {
    key: 'view',
    accessbilityLevel: ACLType.Public,
    component: views.ViewOffer,
    exact: true,
    localisation: {
      en: { title: 'View Offer', path: '/view' },
      hr: {
        title: 'Pogledaj ponudu',
        path: '/hr/pregled/'
      }
    }
  },
  {
    key: 'view',
    accessbilityLevel: ACLType.Public,
    component: views.ViewOffer,
    localisation: {
      en: { title: 'View Offer', path: '/view/:id' },
      hr: {
        title: 'Pogledaj ponudu',
        path: '/hr/pregled/:id'
      }
    }
  }
];

export default publicRoutes;
