import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const ServiceListHeader: React.SFC = () => (
  <header className="settings-itemlist__header settings-itemlist__header--services">
    <span>ID</span>
    <span>
      <FormattedMessage id="headers.servicename" /> EN
    </span>
    <span>
      <FormattedMessage id="headers.servicename" /> HR
    </span>
    <span>Actions</span>
  </header>
);
