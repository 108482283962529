import * as React from 'react';

import { Pagination } from './Pagination';

interface Props<T> {
  data: T[];
  renderItem: (item: T) => JSX.Element | JSX.Element[];
  pageSize?: number;
}

interface State {
  page: number;
  pageSize: number;
}

export class PagedContent<T> extends React.PureComponent<Props<T>, State> {
  state = {
    page: 1,
    pageSize: this.props.pageSize || 30
  };

  getPageCount = () => Math.ceil(this.props.data.length / this.state.pageSize);

  goToFirst = () => this.setState({ page: 1 });
  goToLast = () => this.setState({ page: this.getPageCount() });
  goToNextPage = () => this.setState({ page: this.state.page + 1 });
  goToPreviousPage = () => this.setState({ page: this.state.page - 1 });
  goToPage = (event: React.MouseEvent<HTMLButtonElement>) =>
    this.setState({ page: Number(event.currentTarget.dataset.pageNumber) });

  renderData = () => {
    const { data, renderItem } = this.props;
    const { page, pageSize } = this.state;

    // Set item count from props or global default
    const size = pageSize;

    const start = (page - 1) * size;
    const end = page * size;

    return data.slice(start, end).map(item => renderItem(item));
  };

  renderPagination = () => {
    const { page } = this.state;

    const pageCount = this.getPageCount();

    if (pageCount <= 1) {
      return;
    }

    return (
      <Pagination
        pageNumber={page}
        totalPages={pageCount}
        onGoToFirstPage={this.goToFirst}
        onGoToPage={this.goToPage}
        onGoToLastPage={this.goToLast}
        onGoToNextPage={this.goToNextPage}
        onGoToPreviousPage={this.goToPreviousPage}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderData()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }
}
