import * as React from 'react';
import { Client } from 'modules/company-clients';

interface Props {
  clientInfo?: Client;
}
export const ClientInfo: React.FC<Props> = ({ clientInfo }) => {
  if (!clientInfo) {
    return (
      <div className="proposal__to">
        <div className="proposal__to__client">
          <p className="t-small">
            <strong>Client is not selected</strong>
          </p>
        </div>
      </div>
    );
  }

  const { name, address, taxId, email, phone } = clientInfo;
  return (
    <div className="proposal__to">
      <div className="proposal__to__client">
        <p className="t-small">
          <strong>{name}</strong>
        </p>
        <p className="t-small">{address}</p>
        <p className="t-small">VAT: {taxId}</p>
        <p className="t-small">{email}</p>
        <p className="t-small">{phone}</p>
      </div>
    </div>
  );
};
