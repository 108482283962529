import { createAction, ActionUnion } from 'modules/redux-store';
import { Offer } from 'modules/offers';

import { PublicOfferTypes } from './types';

export const GetSingleOfferActions = {
  getSingleOfferRequest: () =>
    createAction(PublicOfferTypes.GetSingleOfferRequest),

  getSingleOfferSuccess: (publicOffer: Partial<Offer>) =>
    createAction(PublicOfferTypes.GetSingleOfferSuccess, {
      publicOffer
    }),

  getSingleOfferError: (error?: string) =>
    createAction(PublicOfferTypes.GetSingleOfferError, { error })
};

export type GetSingleOfferActions = ActionUnion<typeof GetSingleOfferActions>;
