import * as React from 'react';
import { Modal, ValidatedInput } from 'components';

interface Props {
  title?: string;
  isVisible: boolean;
  formValid: boolean;

  closeModalCallback: VoidFunction;
  saveCallback: VoidFunction;
  handleInputChangeCallback: (
    propName: string,
    value: string,
    isValid?: boolean
  ) => void;

  unitNameEnglish: string;
  unitNameCroatian: string;
}

export const NewUnitModal: React.SFC<Props> = ({
  title,
  isVisible,
  closeModalCallback,
  saveCallback,
  handleInputChangeCallback,
  unitNameEnglish,
  unitNameCroatian,
  formValid
}) => (
  <Modal
    className={`${isVisible ? 'modal modal is-visible' : 'modal'}`}
    closeModalCallback={closeModalCallback}
    saveCallback={saveCallback}
    isVisible={isVisible}
  >
    <div className="modal__wrapper">
      <header className="modal__header">
        <h1 className="modal__title t-gamma">{title}</h1>
      </header>
      <div className="modal__content">
        <section className="">
          <ValidatedInput
            propName="unitNameEnglish"
            label="Unit name (EN)"
            value={unitNameEnglish}
            changeCallback={handleInputChangeCallback}
          />
          <ValidatedInput
            propName="unitNameCroatian"
            label="Unit name (HR)"
            value={unitNameCroatian}
            changeCallback={handleInputChangeCallback}
          />
        </section>
      </div>
      <div className="modal__footer">
        <div className="btn-group btn-group--simple">
          <button
            onClick={saveCallback}
            className="btn btn--primary btn--sml"
            disabled={!formValid}
          >
            Save
          </button>
          <button
            onClick={closeModalCallback}
            className="btn btn--ghost btn--sml"
          >
            Cancel
          </button>
        </div>
      </div>
      <button
        onClick={closeModalCallback}
        className="btn btn--core modal__close"
      >
        <svg width="12" height="12">
          <path d="M10.707 1.293a1 1 0 0 0-1.414 0L6 4.586 2.707 1.293a1 1 0 0 0-1.414 1.414L4.586 6 1.293 9.293a1 1 0 1 0 1.414 1.414L6 7.414l3.293 3.293a1 1 0 0 0 1.414-1.414L7.414 6l3.293-3.293a1 1 0 0 0 0-1.414z" />
        </svg>
      </button>
    </div>
  </Modal>
);
