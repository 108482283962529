import {
  authenticationReducer,
  AuthenticationState
} from 'modules/authentication';
import { clientsReducer, ClientsState } from 'modules/company-clients';
import { servicesReducer, ServiceState } from 'modules/company-services';
import { unitsReducer, UnitState } from 'modules/company-units/redux';
import {
  firestoreListenerReducer,
  FirestoreListenerState
} from 'modules/firestore-listeners';
import { localisationReducer, LocalisationState } from 'modules/localisation';
import { navigationReducer, NavigationState } from 'modules/navigation';
import { offerReducer } from 'modules/offers';
import { OfferState } from 'modules/offers/redux/reducer';
import { settingsReducer, SettingsState } from 'modules/settings';
import { publicOfferReducer, PublicOfferState } from 'modules/view-offer';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

export interface ApplicationState {
  listeners: FirestoreListenerState;
  auth: AuthenticationState;
  localisation: LocalisationState;
  navigation: NavigationState;
  services: ServiceState;
  units: UnitState;
  offers: OfferState;
  publicOffer: PublicOfferState;
  clients: ClientsState;
  settings: SettingsState;
}

export const configureStore = (): Store<ApplicationState> => {
  /**
   * Create the composing function for our middlewares
   * Include dev tools support
   */
  const composeEnhancers = composeWithDevTools({});

  /**
   * Merge all reducers into a single object
   */
  const rootReducer = {
    auth: authenticationReducer,
    localisation: localisationReducer,
    navigation: navigationReducer,
    services: servicesReducer,
    clients: clientsReducer,
    units: unitsReducer,
    offers: offerReducer,
    publicOffer: publicOfferReducer,
    listeners: firestoreListenerReducer,
    settings: settingsReducer
  };

  /**
   * We'll create our store with the combined reducers and all enchancers
   */
  return createStore(
    combineReducers(rootReducer),
    {},
    composeEnhancers(applyMiddleware(thunk))
  );
};
