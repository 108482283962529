import * as React from 'react';
import { ReactNode } from 'react';
import { bold, italic, list, underline, minus } from 'react-icons-kit/feather';

export enum SlateMarksEnum {
  strong = 'strong',
  em = 'em',
  li = 'li',
  u = 'u',
  s = 's'
}

export interface SlateMark {
  children?: ReactNode;
  mark: { type?: SlateMarksEnum; shortcut?: string; icon?: any };
}

export const SlateMarks: SlateMark[] = [
  { mark: { type: SlateMarksEnum.strong, shortcut: 'b', icon: bold } },
  { mark: { type: SlateMarksEnum.em, shortcut: 'i', icon: italic } },
  { mark: { type: SlateMarksEnum.li, shortcut: 'l', icon: list } },
  { mark: { type: SlateMarksEnum.u, shortcut: 'u', icon: underline } },
  { mark: { type: SlateMarksEnum.s, shortcut: 's', icon: minus } }
];

export const Mark: React.FC<SlateMark> = ({ children, mark, ...props }) =>
  React.createElement(`${mark.type || 'span'}`, props, children);
