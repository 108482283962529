enum SetActions {
  SetOffersListener = 'listener/SET_OFFERS_LISTENER',
  SetClientsListener = 'listener/SET_CLIENTS_LISTENER',
  SetServicesListener = 'listener/SET_SERVICES_LISTENER',
  SetUnitsListener = 'listener/SET_UNITS_LISTENER',
  SetSettingsListener = 'listener/SET_SETTINGS_LISTENER'
}
enum ClearActions {
  ClearReducer = 'listener/CLEAR_REDUCER'
}

export const FirestoreListenerActionTypes = {
  ...SetActions,
  ...ClearActions
};
