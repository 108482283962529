import { Client, ClientsActions } from '..';

import { ClientsActionTypes } from './types';

export interface ClientsState {
  readonly clientsAreChanging: boolean;
  readonly clients?: Client[];
  readonly error?: string;
  readonly query: string;
}

const INITIAL_STATE: ClientsState = {
  clientsAreChanging: false,
  clients: undefined,
  error: undefined,
  query: '',
};

export const clientsReducer = (
  state: ClientsState = INITIAL_STATE,
  action: ClientsActions
): ClientsState => {
  switch (action.type) {
    case ClientsActionTypes.ClientsRequest:
      return {
        ...state,
        clientsAreChanging: true,
        error: undefined,
      };
    case ClientsActionTypes.ClientsSuccess:
      return {
        ...state,
        clientsAreChanging: false,
        clients: action.payload.clients,
      };
    case ClientsActionTypes.ClientsError:
      return {
        ...state,
        clientsAreChanging: false,
        error: action.payload.error,
      };
    case ClientsActionTypes.ClientsQuery:
      return {
        ...state,
        query: action.payload.query,
      };
    default:
      return state || INITIAL_STATE;
  }
};
