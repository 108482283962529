import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SettingsMenu from 'components/SettingsMenu';

export const withSettingsMenu = (Component: any) => {
  return class Settings extends React.Component {
    render() {
      return (
        <section className="settings">
          <header className="subheader">
            <div className="subheader__wrapper wrapper wrapper--narrow">
              <ul className="subheader__links">
                <li className="subheader__links__item--active">
                  <FormattedMessage id="page.settings" />
                </li>
              </ul>
            </div>
          </header>
          <div className="wrapper wrapper--narrow">
            <section className="settings__layout">
              <SettingsMenu />

              <div className="settings__layout__content">
                <Component />
              </div>
            </section>
          </div>
        </section>
      );
    }
  };
};
