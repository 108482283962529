enum ActionTypes {
  ServiceRequest = 'service-codes/REQUEST',
  ServiceSuccess = 'service-codes/SUCCESS',
  ServiceError = 'service-codes/ERROR',
  ServiceQuery = 'service-codes/QUERY',
}

export const ServiceActionTypes = {
  ...ActionTypes,
};
