import * as React from 'react';
import { Modal, ValidatedInput } from 'components';
import { ValidationMethod } from 'modules/validation';

interface Props {
  title?: string;
  isVisible: boolean;
  closeModalCallback: VoidFunction;
  saveCallback: VoidFunction;
  handleInputChangeCallback: (
    propName: string,
    value: string,
    isValid?: boolean
  ) => void;
  clientName: string;
  clientNameValid: boolean;
  clientNameDuplicate: boolean;
  taxId: string;
  email: string;
  phone: string;
  address: string;
  isEdit: boolean;
  checkId: (id: string) => boolean;
  clientNameCheck?: string;
}

export const NewClientModal: React.SFC<Props> = ({
  title,
  isVisible,
  closeModalCallback,
  saveCallback,
  handleInputChangeCallback,
  clientName,
  taxId,
  email,
  phone,
  address,
  clientNameValid,
  clientNameDuplicate,
  isEdit,
  clientNameCheck,
  checkId,
}) => (
    <Modal
      className={`${isVisible ? 'modal modal is-visible' : 'modal'}`}
      closeModalCallback={closeModalCallback}
      saveCallback={saveCallback}
      isVisible={isVisible}
    >
      <div className="modal__wrapper">
        <header className="modal__header">
          <h1 className="modal__title t-gamma">{title}</h1>
        </header>
        {isVisible && (
          <div className="modal__content">
            <ValidatedInput
              propName="clientName"
              label="Name"
              value={clientName}
              changeCallback={handleInputChangeCallback}
              clientNameDuplicate={clientNameDuplicate}
              hideErrors={!checkId(clientNameCheck || '')}
              type={'text'}
            />
            <ValidatedInput
              propName="taxId"
              label="Vat ID"
              value={taxId}
              changeCallback={handleInputChangeCallback}
              optional={true}
              type={'text'}
            />
            <ValidatedInput
              propName="email"
              label="Email"
              value={email}
              validationType={ValidationMethod.Email}
              changeCallback={handleInputChangeCallback}
              optional={true}
              type={'text'}
            />
            <ValidatedInput
              propName="phone"
              label="Phone number"
              value={phone}
              changeCallback={handleInputChangeCallback}
              optional={true}
            />
            <ValidatedInput
              propName="address"
              label="Address"
              value={address}
              changeCallback={handleInputChangeCallback}
              optional={true}
              type={'text'}
            />
          </div>
        )}
        <div className="modal__footer">
          <div className="btn-group btn-group--simple">
            <button
              onClick={saveCallback}
              className="btn btn--primary btn--sml"
              disabled={
                !isEdit
                  ? !clientNameValid || clientNameDuplicate || !clientName.length
                  : isEdit
                    ? !clientName.length ||
                    checkId(clientNameCheck ? clientNameCheck : '')
                    : undefined
              }
            >
              Save
          </button>
            <button
              onClick={closeModalCallback}
              className="btn btn--ghost btn--sml"
            >
              Cancel
          </button>
          </div>
        </div>
        <button
          onClick={closeModalCallback}
          className="btn btn--core modal__close"
        >
          <svg width="12" height="12">
            <path d="M10.707 1.293a1 1 0 0 0-1.414 0L6 4.586 2.707 1.293a1 1 0 0 0-1.414 1.414L4.586 6 1.293 9.293a1 1 0 1 0 1.414 1.414L6 7.414l3.293 3.293a1 1 0 0 0 1.414-1.414L7.414 6l3.293-3.293a1 1 0 0 0 0-1.414z" />
          </svg>
        </button>
      </div>
    </Modal>
  );
