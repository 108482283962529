import { AppRoute } from 'modules/navigation';
import * as React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { ACLType } from '../models';

interface OwnProps {
  isLoggedIn: boolean;
  permissionErrrorRoute: AppRoute;
  dashboardRoute: AppRoute;
}

type Props = OwnProps & AppRoute;

export const ACLRoute: React.SFC<Props> = ({
  key,
  accessbilityLevel,
  isLoggedIn,
  permissionErrrorRoute,
  dashboardRoute,
  ...rest
}) => {
  const { pathname } = useLocation();

  // Redirect user to permission error page if anonymous user tries to access authenticated route
  if (!isLoggedIn && accessbilityLevel === ACLType.Authenticated) {
    return <Redirect key={key} to={permissionErrrorRoute.path!} />;
  }

  // // Redirect user to dashboard if he is on unauthorized error page but logged in
  if (isLoggedIn && pathname === permissionErrrorRoute.path) {
    return <Redirect key={key} to={dashboardRoute.path!} />;
  }

  // // Redirect user to dashboard if authenticated user tries to access AnonymousOnly route
  if (isLoggedIn && accessbilityLevel === ACLType.AnonymousOnly) {
    return <Redirect key={key} to={dashboardRoute.path!} />;
  }

  return <Route key={key} {...rest} />;
};
