import { createSelector } from 'reselect';
import { ApplicationState } from 'modules/redux-store';
import { Service } from 'modules/company-services';

const getServices = (state: ApplicationState) => state.services.services;
const getQuery = (state: ApplicationState) => state.services.query;
const serviceById = (state: ApplicationState, id: string) =>
  state.services.services?.find((i) => i.id === id);

const getSortedServices = createSelector([getServices], (services) => {
  return services?.sort(
    (a: Service, b: Service) => parseInt(a.code) - parseInt(b.code)
  );
});

const getServiceDuplicate = createSelector(
  [getServices, getQuery],
  (services, query) => {
    return !!services?.find((a) => a.code === query);
  }
);

const getServiceEdit = createSelector(
  [getServices, getQuery, serviceById],
  (services, query, service) =>
    !!services?.find((i) => {
      return i.id !== service?.id ? i.code === query : undefined;
    })
);

export const ServiceSelectors = {
  getSortedServices,
  getServiceDuplicate,
  getServiceEdit,
};
