import { createAction, ActionUnion } from 'modules/redux-store';

import { Settings } from '../models';

import { SettingsActionTypes } from './types';

export const SettingsActions = {
  settingsRequest: () => createAction(SettingsActionTypes.SettingsRequest),

  settingsSuccess: (settings: Settings) =>
    createAction(SettingsActionTypes.SettingsSuccess, {
      settings
    }),

  settingsError: (error?: string) =>
    createAction(SettingsActionTypes.SettingsError, { error })
};

export type SettingsActions = ActionUnion<typeof SettingsActions>;
