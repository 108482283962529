import { ApplicationStrings } from '../models';

const en: ApplicationStrings = {
  // Login view
  'login.welcome': 'Login',

  // Page names
  'page.homepage': 'Homepage',
  'page.clients': 'Clients',
  'page.services': 'Services',
  'page.units': 'Units',
  'page.settings': 'Settings',
  'page.newoffer': 'New offer',

  // List headers
  'headers.offername': 'Proposal name',
  'headers.servicename': 'Service name',
  'headers.unitname': 'Unit name',
  'headers.actions': 'Actions',

  // Filters
  'filter.label.orderby': 'Order by:',
  'filter.label.status': 'Status:',
  'filter.label.year': 'Year:',
  'filter.button.name': 'Name',
  'filter.button.date': 'Date',
  'filter.button.showall': 'Show all',
  'filter.button.drafts': 'Drafts',
  'filter.button.published': 'Published',
  'filter.button.archived': 'Archived',

  'offers.listitem.amount': 'Amount',
  'offers.listitem.status': 'Status',
  'offers.listitem.year': 'Year',

  'input.email': 'E-mail',
  'input.password': 'Password',
  'input.rememberme': 'Remember me',

  'button.save': 'Save',
  'button.delete': 'Delete',
  'button.cancel': 'Cancel',
  'button.edit': 'Edit',
  'button.publish': 'Publish',
  'button.archive': 'Archive',
  'button.logout': 'Sign out',
  'button.login': 'Sign in',
  'button.addnew.offer': 'Create new proposal',
  'button.addnew.service': 'Add new service',
  'button.addnew.unit': 'Add new unit',
  'button.addnew.client': 'Add new client',
  'button.copy': 'Copy link',
  'button.preview': 'Preview',
  'button.duplicate': 'Duplicate',

  'error.label.default': 'Something is wrong.',
  'error.label.unauthorized':
    'Sorry, you do not have access permissions to view this content.',
  'error.message.noitems': 'There are currently no items',
  'error.message.unauthorized':
    'Unfortunately you do not have the needed permissions to access this area.',
  'error.message.pagenotfound':
    'The page you requested cannot be found. If you entered a web address please check again for typos. You can always go back home using the button below.',

  // Empty states
  'empty.offer.title': 'Unnamed offer',
  'empty.offer.companyInfo': 'No company info',
  'empty.offer.type': 'No offers found that match the criteria.',
  'empty.offer.message':
    'Get started by creating a new offer using the builder, easy!',

  'footer.copyright': 'Copyright &copy; 2018 All Rights Reserved.'
};

export default en;
