import { Dispatch, ApplicationState } from 'modules/redux-store';
import { FirebaseDatabaseService } from 'modules/firebase/services';
import { FirestoreListenerActions } from 'modules/firestore-listeners';

import { Settings } from '../models';

import { SettingsActions } from './actions';

const firebaseSettings = new FirebaseDatabaseService<Settings>('settings');

export const getAllAsync = () => async (
  dispatch: Dispatch,
  getState: () => ApplicationState
) => {
  /** If settings listener is already active, don't trigger again */
  const settings = getState().listeners.settingsListener;
  if (settings) {
    return;
  }

  dispatch(SettingsActions.settingsRequest());

  const successFunction = (data: Settings) => {
    dispatch(SettingsActions.settingsSuccess(data));
  };

  const errorFunction = (error: string) =>
    dispatch(SettingsActions.settingsError(error));

  const listenerProps = {
    successFunction,
    errorFunction
  };

  firebaseSettings
    .getByIdAsync('generalSettings', listenerProps)
    .then(listener =>
      dispatch(
        FirestoreListenerActions.setSettingsListener(listener as VoidFunction)
      )
    );

  return;
};

export const updateSettings = (updatedSettings: Settings) => async (
  dispatch: Dispatch
) => {
  dispatch(SettingsActions.settingsRequest());
  firebaseSettings.updateAsync(updatedSettings);
  return;
};

export const SettingsThunkActions = {
  getAllAsync,
  updateSettings
};
