import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import firebase from 'firebase/app';
import uuid from 'uuid';
import { useFormContext } from 'react-hook-form';

import { Offer } from '../models';

interface Props {
  currentOffer: Partial<Offer>;
}

export const OfferCoverImage: React.FC<Props> = ({ currentOffer }) => {
  const { setValue, register, watch } = useFormContext();
  const { coverPhoto } = currentOffer;
  const [error, setError] = useState('');

  useEffect(() => {
    register('coverPhoto');
    setValue('coverPhoto', coverPhoto || '');
  }, [register, setValue, coverPhoto]);

  const formCoverImage = watch('coverPhoto');

  return (
    <div className="wrapper">
      <section className="photobox">
        <Dropzone
          onDrop={onDrop}
          accept="image/*"
          onDropRejected={onDropRejected}
          maxSize={2000000}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {formCoverImage && (
                  <img src={formCoverImage} alt="proposal cover" />
                )}

                {!formCoverImage && (
                  <>
                    {isDragActive ? (
                      <p>Drop files here...</p>
                    ) : (
                      <p>Drop image to upload or click to select a file.</p>
                    )}
                  </>
                )}
              </div>
            );
          }}
        </Dropzone>
        {formCoverImage && (
          <button
            type="button"
            onClick={removeImage}
            className="btn btn--dark btn--sml photobox__delete"
          >
            Remove cover image
          </button>
        )}
      </section>
      <p className="s-top--sml u-color--warning">{error}</p>
    </div>
  );

  async function onDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length > 1) {
      setError(
        'You have uploaded more than one file. Please upload only one file.'
      );
      return;
    }

    if (acceptedFiles.length) {
      setError('');
      const snap = await firebase
        .storage()
        .ref()
        .child(`covers/${uuid.v4()}`)
        .put(acceptedFiles[acceptedFiles.length - 1]);

      const imageDownloadUrl = await snap.ref.getDownloadURL();
      setValue('coverPhoto', imageDownloadUrl);
    }
  }

  function onDropRejected() {
    setError('File is over 2mb. Please upload smaller file.');
  }

  function removeImage() {
    setValue('coverPhoto', null);
  }
};
