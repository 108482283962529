import React, { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ValidatedHookInput } from 'components';
import { useFormContext } from 'react-hook-form';

interface Props {
  id: string;
  index: number;
  type?: string;
  heading?: string;
  subheading?: string;
  handleRemoveHeaderCallback: (id: string) => void;
}

export const OfferBuilderHeading: React.FC<Props> = ({
  id,
  index,
  heading,
  type,
  subheading,
  handleRemoveHeaderCallback
}) => {
  const { register, errors, setValue } = useFormContext();

  useEffect(() => {
    register(`services[${index}].id`);
    setValue(`services[${index}].id`, id);
    register(`services[${index}].index`);
    setValue(`services[${index}].index`, index);
    register(`services[${index}].type`);
    setValue(`services[${index}].type`, type);
  }, [register, index, setValue, id, type]);

  function handleDeleteField() {
    handleRemoveHeaderCallback(id);
  }

  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <section
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="proposal-builder-heading"
        >
          <div
            {...provided.dragHandleProps}
            className="proposal-service__dragger"
          >
            <img
              alt="drag item"
              src={require('assets/icons/icon-drag-24.svg')}
            />
          </div>
          <ValidatedHookInput
            autoFocus
            name={`services[${index}].heading`}
            register={register}
            errors={errors}
            defaultValue={heading}
            placeholder="Add a header"
            inputClassName="input input--supersized u-color--bg s-bottom--sml"
          />

          <ValidatedHookInput
            autoFocus
            name={`services[${index}].subheading`}
            register={register}
            errors={errors}
            defaultValue={subheading}
            placeholder="Add a subheader"
            inputClassName="input input--text"
          />
          <footer className="proposal-service__footer">
            <button
              type="button"
              onClick={handleDeleteField}
              className="btn btn--ghost btn--sml btn--sml--iconOnly"
            >
              <img
                src={require('assets/icons/icon-trash-16.svg')}
                alt=""
                className="btn__icon"
              />
            </button>
          </footer>
        </section>
      )}
    </Draggable>
  );
};
