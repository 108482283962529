import firebase from 'firebase/app';
import 'firebase/auth';
import { Dispatch } from 'modules/redux-store';
import { AuthenticationActions } from 'modules/authentication';

import { FirebaseService } from './FirebaseService';

enum AuthPersistence {
  Local = 'local',
  Session = 'session'
}

export class FirebaseAuthService {
  private firebase = FirebaseService.Instance;
  private auth = this.firebase.auth();

  initAuthStateListener(dispatch: Dispatch) {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        dispatch(AuthenticationActions.loginSuccess(user));
        return;
      }

      dispatch(AuthenticationActions.authStateChange());
      dispatch(AuthenticationActions.logout());
    });
  }

  async loginWithEmailAndPasswordAsync(
    email: string,
    password: string,
    rememberMe: boolean
  ) {
    return this.auth
      .setPersistence(
        rememberMe ? AuthPersistence.Local : AuthPersistence.Session
      )
      .then(() =>
        this.auth
          .signInWithEmailAndPassword(email, password)
          .then(() => undefined)
          .catch((error: firebase.FirebaseError) => error.message)
      );
  }

  async logoutAsync() {
    return this.auth
      .signOut()
      .then()
      .catch((error: firebase.FirebaseError) => error.message);
  }
}
