import React, { useEffect } from 'react';
import {
  ConfirmationModal,
  Loading,
  PageLoading,
  FormSubmit,
} from 'components';
import { Client, ClientSelectors } from 'modules/company-clients';
import {
  FormattedService,
  ServicesThunkActions,
} from 'modules/company-services';
import { FormattedUnit, UnitsThunkActions } from 'modules/company-units';
import {
  FormattedVersionHistory,
  Offer,
  OfferActions,
  OfferBuilderField,
  OfferBuilderHeading,
  OfferBuilderService,
  OfferClientInformation,
  OfferCoverImage,
  OfferStatus,
  OffersThunkAction,
  serviceIsOfferField,
  serviceIsOfferService,
  useNewOffer,
} from 'modules/offers';
import { GeneralInformation } from 'modules/offers/components/GeneralInformation';
import { TotalPrices } from 'modules/offers/components/TotalPrices';
import { ItemSelectors } from 'modules/offers/redux/selectors';
import { ApplicationState, Dispatch } from 'modules/redux-store';
import { Settings } from 'modules/settings';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { useForm, FormContext, useFieldArray } from 'react-hook-form';

interface ReduxProps {
  services?: FormattedService[];
  units?: FormattedUnit[];
  offerIsBeingAdded: boolean;
  offerIsBeingSaved: boolean;
  error?: string;
  offers?: Offer[];
  currentOffer?: Partial<Offer>;
  newOfferId?: string;
  offerHistory?: any;
  versionHistory?: FormattedVersionHistory[];
  settings?: Settings;
  clients?: Client[];
}

interface DispatchProps {
  editOffer: (currentOffer?: Partial<Offer>) => Promise<void>;
  saveNewVersion: (offer: Partial<Offer>, id: string) => Promise<void>;
  updateDraft: (offer: Partial<Offer>, id: string) => Promise<void>;
  getAllVersions: (id: string) => Promise<void>;
  removeVersionHistory: () => Promise<void>;
  getAllOffers: () => Promise<void>;
  getAllUnits: () => Promise<void>;
  getAllServices: () => Promise<void>;
  dispatchError: (error?: string) => void;
}

type Props = ReduxProps & DispatchProps;

const NewOffer: React.FC<Props> = ({
  getAllServices,
  getAllUnits,
  getAllOffers,
  offers,
  clients,
  currentOffer,
  offerIsBeingAdded,
  editOffer,
  dispatchError,
  removeVersionHistory,
  versionHistory,
  getAllVersions,
  offerIsBeingSaved,
  settings,
  saveNewVersion,
  updateDraft,
  units,
  services,
  error,
}) => {
  const { id: matchId } = useParams();
  const methods = useForm<Offer>({
    mode: 'onBlur',
    defaultValues: {
      id: matchId,
    },
  });
  const { register, getValues, setValue, handleSubmit, control } = methods;
  const { remove, move } = useFieldArray({
    control,
    name: 'services',
  });

  useEffect(() => {
    register('id');
    register('status');
    register('creationDateTime');

    setValue('status', currentOffer?.status);
    setValue('creationDateTime', currentOffer?.creationDateTime);
  }, [register, setValue, currentOffer]);

  const {
    state: { offerIsSaved, publishConfirmationModalIsOpen },
    togglePublishConfirmationModal,
    selectClientFromDatabase,
    handleRemoveServiceOrFieldOrHeader,
    handleAddServiceOrFieldOrHeader,
    handleInitialOfferPublishing,
    handleSaveOffer,
    handleSelectHistoryVersion,
    onDragEnd,
  } = useNewOffer(
    editOffer,
    getAllOffers,
    getAllUnits,
    getAllServices,
    removeVersionHistory,
    dispatchError,
    getAllVersions,
    saveNewVersion,
    updateDraft,
    move,
    offerIsBeingSaved,
    offers,
    currentOffer,
    versionHistory,
    settings,
    clients,
    getValues,
    remove
  );

  if (error) {
    return <Redirect to="/error" />;
  }

  if (offerIsBeingAdded || !currentOffer) {
    return <PageLoading />;
  }

  return (
    <section className="proposal-create">
      <FormContext {...methods}>
        <form onSubmit={handleSubmit(handleSaveOffer)}>
          <GeneralInformation
            currentOffer={currentOffer}
            versionHistory={versionHistory}
            handleSelectHistoryVersionCallback={handleSelectHistoryVersion}
          />

          <OfferCoverImage currentOffer={currentOffer} />

          <OfferClientInformation
            currentOffer={currentOffer}
            clients={clients}
            selectClientFromDatabaseCallback={selectClientFromDatabase}
          />

          <div className="wrapper">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="1">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="proposal-create__service-list s-top--lrg"
                  >
                    {currentOffer.services?.map((item: any, index) =>
                      serviceIsOfferService(item) ? (
                        <OfferBuilderService
                          index={index}
                          key={item.id}
                          id={item.id}
                          services={services}
                          units={units}
                          name={item.name}
                          unit={item.unit}
                          type={item.type}
                          selectedUnit={item.unitId}
                          selectedService={item.serviceId}
                          additionalField={item.additionalField}
                          pricePerUnit={item.pricePerUnit}
                          discount={item.discount}
                          quantity={item.quantity}
                          language={currentOffer.language}
                          handleRemoveServiceCallback={
                            handleRemoveServiceOrFieldOrHeader
                          }
                          currency={currentOffer.currency}
                          priceWithoutDiscount={
                            item.total && item.total.priceWithoutDiscount
                          }
                          discountAmount={
                            item.total && item.total.discountAmount
                          }
                          price={item.total && item.total.price}
                        />
                      ) : serviceIsOfferField(item) ? (
                        <OfferBuilderField
                          index={index}
                          key={item.id}
                          id={item.id}
                          body={item.body}
                          type={item.type}
                          handleRemoveServiceCallback={
                            handleRemoveServiceOrFieldOrHeader
                          }
                        />
                      ) : (
                        <OfferBuilderHeading
                          index={index}
                          key={item.id}
                          id={item.id}
                          type={item.type}
                          heading={item.heading}
                          subheading={item.subheading}
                          handleRemoveHeaderCallback={
                            handleRemoveServiceOrFieldOrHeader
                          }
                        />
                      )
                    )}

                    {provided.placeholder}

                    <div className="proposal-create__add-block">
                      <button
                        type="button"
                        onClick={handleAddServiceOrFieldOrHeader}
                        name="service"
                        className="btn btn--primary btn--sml"
                      >
                        <img
                          src={require('assets/icons/icon-add-16-negative.svg')}
                          alt=""
                          className="btn__icon btn__icon--left"
                        />
                        Add a service
                      </button>
                      <button
                        type="button"
                        onClick={handleAddServiceOrFieldOrHeader}
                        name="header"
                        className="btn btn--negative btn--sml s-left--sml"
                      >
                        <img
                          src={require('assets/icons/icon-paragraph-16.svg')}
                          alt=""
                          className="btn__icon btn__icon--left"
                        />
                        Add a Header
                      </button>

                      <button
                        type="button"
                        onClick={handleAddServiceOrFieldOrHeader}
                        name="field"
                        className="btn btn--negative btn--sml s-left--sml"
                      >
                        <img
                          src={require('assets/icons/icon-paragraph-16.svg')}
                          alt=""
                          className="btn__icon btn__icon--left"
                        />
                        Add a text block
                      </button>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {currentOffer && <TotalPrices currentOffer={currentOffer} />}

            <div className="proposal-create__save-block">
              <div className="btn-group btn-group--simple">
                {/* Disable publish offer button until id is not in url since it means offer is not saved on firebase. */}
                {currentOffer &&
                  currentOffer.status !== OfferStatus.Published &&
                  matchId && (
                    <button
                      type="button"
                      onClick={togglePublishConfirmationModal}
                      className="btn btn--primary btn--sml"
                    >
                      Publish offer
                    </button>
                  )}

                {offerIsBeingSaved ? (
                  <Loading />
                ) : (
                  <FormSubmit
                    className="btn btn--primary btn--sml"
                    value={
                      currentOffer.status === OfferStatus.Published
                        ? 'Save new version'
                        : 'Update draft'
                    }
                  />
                )}

                {currentOffer.id && (
                  <Link
                    to={`/view/${currentOffer.id}`}
                    target="_blank"
                    className="btn btn--basic btn--sml"
                  >
                    <FormattedMessage id="button.preview" />
                  </Link>
                )}
              </div>

              {offerIsSaved && <p className="proposal-create__saved">Saved!</p>}
            </div>
          </div>
          <ConfirmationModal
            title="Are you sure that you want to publish this proposal?"
            description="You can edit this proposal after you publish it."
            isVisible={publishConfirmationModalIsOpen}
            confirmCallback={handleInitialOfferPublishing}
            cancelCallback={togglePublishConfirmationModal}
          />
        </form>
      </FormContext>
    </section>
  );
};

export default connect(
  (state: ApplicationState): ReduxProps => ({
    units: ItemSelectors.getFormattedUnits(state),
    services: ItemSelectors.getFormattedServices(state),
    offers: state.offers.offers,
    offerIsBeingAdded: state.offers.offerIsBeingAdded,
    offerIsBeingSaved: state.offers.offerIsBeingSaved,
    error: state.offers.error,
    currentOffer: state.offers.offerToEdit,
    newOfferId: state.offers.newOfferId,
    settings: state.settings.settings,
    clients: ClientSelectors.getSortedClients(state),
    versionHistory: ItemSelectors.getFormattedVersionHistory(state),
  }),
  (dispatch: Dispatch): DispatchProps => ({
    editOffer: (offer?: Partial<Offer>) =>
      dispatch(OffersThunkAction.editOffer(offer)),
    saveNewVersion: (offer: Partial<Offer>, id: string) =>
      dispatch(OffersThunkAction.saveNewVersion(offer, id)),
    updateDraft: (offer: Partial<Offer>, id: string) =>
      dispatch(OffersThunkAction.updateDraft(offer, id)),
    getAllVersions: (id: string) =>
      dispatch(OffersThunkAction.getVersionHistory(id)),
    removeVersionHistory: () =>
      dispatch(OffersThunkAction.removeVersionHistory()),
    getAllOffers: () => dispatch(OffersThunkAction.getAllAsync()),
    getAllUnits: () => dispatch(UnitsThunkActions.getAllAsync()),
    getAllServices: () => dispatch(ServicesThunkActions.getAllAsync()),
    dispatchError: (error?: string) =>
      dispatch(OfferActions.offersError(error)),
  })
)(NewOffer);
