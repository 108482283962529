import { Globals } from 'consts';

import { Language, ApplicationStrings } from '../models';

import { LocalisationActions } from './actions';
import { LocalisationActionTypes } from './types';

export interface LocalisationState {
  readonly currentLanguage: Language;
  readonly currentApplicationStrings: ApplicationStrings;
}

const INITIAL_STATE: LocalisationState = {
  currentLanguage: Globals.DEFAULT_LANGUAGE,
  currentApplicationStrings: Globals.DEFAULT_STRINGS
};

export const localisationReducer = (
  state: LocalisationState = INITIAL_STATE,
  action: LocalisationActions
): LocalisationState => {
  switch (action.type) {
    case LocalisationActionTypes.SetLanguage:
      return {
        ...state,
        currentLanguage: action.payload.currentLanguage,
        currentApplicationStrings: action.payload.currentApplicationStrings
      };
    default:
      return state || INITIAL_STATE;
  }
};
