import * as React from 'react';
// import { FormattedMessage } from 'react-intl';

export const Footer: React.SFC = () => (
  <footer className="app__footer footer">
    <div className="wrapper wrapper--tight">
      <div className="footer__content">
        <p className="t-upperext">
          Proposal built with <span>OFFERNATOR</span> &mdash; a{' '}
          <a href="https://prototyp.digital">
            <strong>PROTOTYP</strong>
          </a>{' '}
          product.
        </p>
        {/* <FormattedMessage id="footer.copyright" /> */}
      </div>
    </div>
  </footer>
);
