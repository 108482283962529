import { createSelector } from 'reselect';
import { ApplicationState } from 'modules/redux-store';
import { AppRoute } from 'modules/navigation';
import { Unit } from 'modules/company-units';
import { Service } from 'modules/company-services';

import { Language } from '../models';

const getRoutes = (state: ApplicationState) => state.navigation.routes;
const getUnits = (state: ApplicationState) => state.units.units;
const getServices = (state: ApplicationState) => state.services.services;

const getCurrentLanguage = (state: ApplicationState) =>
  state.localisation.currentLanguage;

const getCurrentOfferLanguage = (state: ApplicationState) =>
  (state.offers &&
    state.offers.offerToEdit &&
    state.offers!.offerToEdit!.language) ||
  Language.English;

const getLocalisedRoutes = createSelector(
  [getRoutes, getCurrentLanguage],
  (routes, language) =>
    routes.map((route: AppRoute) => {
      route = {
        ...route,
        ...route.localisation[language]
      };

      return route;
    })
);

const getLocalisedUnits = createSelector(
  [getUnits, getCurrentOfferLanguage],
  (units, language) => {
    if (units) {
      return units.map((unit: Unit) => {
        return {
          ...unit,
          ...unit.localisation[language]
        };
      });
    }

    return;
  }
);

const getLocalisedServices = createSelector(
  [getServices, getCurrentOfferLanguage],
  (services, language) => {
    if (services) {
      return services.map((service: Service) => {
        return {
          ...service,
          ...service.localisation[language]
        };
      });
    }

    return;
  }
);

export const LocalisationSelectors = {
  getCurrentOfferLanguage,
  getLocalisedRoutes,
  getLocalisedUnits,
  getLocalisedServices
};
