import { createAction, ActionUnion } from 'modules/redux-store';

import { Unit } from '../models';

import { UnitActionTypes } from './types';

export const UnitActions = {
  unitsRequest: () => createAction(UnitActionTypes.UnitRequest),

  unitsSuccess: (units: Unit[]) =>
    createAction(UnitActionTypes.UnitSuccess, {
      units
    }),

  unitsError: (error?: string) =>
    createAction(UnitActionTypes.UnitError, { error })
};

export type UnitActions = ActionUnion<typeof UnitActions>;
