import { FirestoreListenerActions } from '.';
import { FirestoreListenerActionTypes } from './types';

export interface FirestoreListenerState {
  offersListener?: VoidFunction;
  clientsListener?: VoidFunction;
  servicesListener?: VoidFunction;
  unitsListener?: VoidFunction;
  settingsListener?: VoidFunction;
}

const INITIAL_STATE: FirestoreListenerState = {
  offersListener: undefined,
  clientsListener: undefined,
  servicesListener: undefined,
  unitsListener: undefined,
  settingsListener: undefined
};

export const firestoreListenerReducer = (
  state: FirestoreListenerState = INITIAL_STATE,
  action: FirestoreListenerActions
) => {
  switch (action.type) {
    case FirestoreListenerActionTypes.ClearReducer:
      return INITIAL_STATE;
    case FirestoreListenerActionTypes.SetOffersListener:
      return {
        ...state,
        offersListener: action.payload.listener
      };
    case FirestoreListenerActionTypes.SetClientsListener:
      return {
        ...state,
        clientsListener: action.payload.listener
      };
    case FirestoreListenerActionTypes.SetServicesListener:
      return {
        ...state,
        servicesListener: action.payload.listener
      };
    case FirestoreListenerActionTypes.SetUnitsListener:
      return {
        ...state,
        unitsListener: action.payload.listener
      };
    case FirestoreListenerActionTypes.SetSettingsListener:
      return {
        ...state,
        settingsListener: action.payload.listener
      };
    default:
      return state || INITIAL_STATE;
  }
};
