import * as React from 'react';
import { Modal, ValidatedInput } from 'components';

interface Props {
  title?: string;
  isVisible: boolean;
  formValid: boolean;
  getDuplicateId: boolean;
  closeModalCallback: VoidFunction;
  saveCallback: VoidFunction;
  handleInputChangeCallback: (
    propName: string,
    value: string,
    isValid?: boolean
  ) => void;
  isEdit: boolean;
  serviceCode?: string;
  serviceNameEnglish: string;
  serviceNameCroatian: string;
  checkId: (id: string) => boolean;
  serviceCodeCheck: string;
}

export const NewServiceModal: React.SFC<Props> = ({
  title,
  isVisible,
  closeModalCallback,
  saveCallback,
  handleInputChangeCallback,
  serviceNameEnglish,
  serviceNameCroatian,
  serviceCode,
  formValid,
  getDuplicateId,
  isEdit,
  checkId,
  serviceCodeCheck,
}) => (
    <Modal
      className={`${isVisible ? 'modal modal is-visible' : 'modal'}`}
      closeModalCallback={closeModalCallback}
      saveCallback={saveCallback}
      isVisible={isVisible}
    >
      <div className="modal__wrapper">
        <header className="modal__header">
          <h1 className="modal__title t-gamma">{title}</h1>
        </header>
        {isVisible && (
          <div className="modal__content">
            <section className="">
              <ValidatedInput
                propName="serviceCode"
                label="Service ID"
                value={serviceCode!}
                changeCallback={handleInputChangeCallback}
                clientNameDuplicate={getDuplicateId}
                hideErrors={!checkId(serviceCodeCheck)}
              />
              <ValidatedInput
                propName="serviceNameEnglish"
                label="Service name (EN)"
                value={serviceNameEnglish}
                changeCallback={handleInputChangeCallback}
              />
              <ValidatedInput
                propName="serviceNameCroatian"
                label="Service name (HR)"
                value={serviceNameCroatian}
                changeCallback={handleInputChangeCallback}
              />
            </section>
          </div>
        )}
        <div className="modal__footer">
          <div className="btn-group btn-group--simple">
            <button
              onClick={saveCallback}
              className="btn btn--primary btn--sml"
              disabled={
                !isEdit
                  ? !formValid || getDuplicateId || !serviceCode?.length
                  : isEdit
                    ? !serviceCode?.length || checkId(serviceCodeCheck)
                    : undefined
              }
            >
              Save
          </button>
            <button
              onClick={closeModalCallback}
              className="btn btn--ghost btn--sml"
            >
              Cancel
          </button>
          </div>
        </div>
        <button
          onClick={closeModalCallback}
          className="btn btn--core modal__close"
        >
          <svg width="12" height="12">
            <path d="M10.707 1.293a1 1 0 0 0-1.414 0L6 4.586 2.707 1.293a1 1 0 0 0-1.414 1.414L4.586 6 1.293 9.293a1 1 0 1 0 1.414 1.414L6 7.414l3.293 3.293a1 1 0 0 0 1.414-1.414L7.414 6l3.293-3.293a1 1 0 0 0 0-1.414z" />
          </svg>
        </button>
      </div>
    </Modal>
  );
