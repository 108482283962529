import React from 'react';

interface Props {
  value: string;
  stateIsChanging?: boolean;
  disabled?: boolean;
  className?: string;
}

export const FormSubmit: React.FC<Props> = ({
  value,
  stateIsChanging,
  disabled,
  className
}) => (
  <input
    type="submit"
    value={stateIsChanging ? 'Loading...' : value}
    className={className || 'btn btn--primary btn--med'}
    disabled={disabled || stateIsChanging}
  />
);
